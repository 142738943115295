import { call, put, select } from "redux-saga/effects";
import { selectAccessKeyCurrent } from "../Selectors/selectAccessKeyCurrent";
import { fetchApi, urlAuthNode } from "../../../helpers/common";
import { ApiClient } from "helpers/ApiClient";
import { AccessKey } from "models/AccessKey";
import { handleSagaError } from "Redux/helpers";
import { setAccessKeys, setCurrentAccessKey } from "../reducer";

export function* deleteAccessKeyProcess() {
  try {
    const currentKey = yield select(selectAccessKeyCurrent);
    yield call(fetchApi, "delete", "204", {
      url: `/access-keys/${currentKey?.id}`,
      mainUrl: urlAuthNode,
    });
    const accessKeys: AccessKey[] = yield call(ApiClient.getAccessKeys);
    yield put(setAccessKeys(accessKeys));
    yield put(setCurrentAccessKey({} as any));
  } catch (err) {
    yield handleSagaError(err, "Access keys");
    yield put(setCurrentAccessKey({} as any));
  }
}
