import { call, put } from "redux-saga/effects";
import { fetchApi, urlAuthNode } from "../../../helpers/common";
import { handleSagaError } from "Redux/helpers";
import { setSettingsInfo } from "../reducer";

export function* getSettingsInfoProcess() {
  try {
    const result = yield call(fetchApi, "get", "200", {
      url: "/me",
      mainUrl: urlAuthNode,
    });
    yield put(setSettingsInfo({ ...result }));
  } catch (err) {
    yield handleSagaError(err, "Settings");
  }
}
