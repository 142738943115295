import { takeLatest } from "redux-saga/effects";
import { startUploadZipAction } from "./Actions/downloadActions";
import { prepareFilesForDownload } from "./downloadSlice";
import { watchDownloadFiles } from "./Sagas/watchDownloadFiles";
import { watchUploadZip } from "./Sagas/watchUploadZip";

export function* downloadFilesWathcer() {
  yield takeLatest(prepareFilesForDownload.type, watchDownloadFiles);
  yield takeLatest(startUploadZipAction.type, watchUploadZip);
}
