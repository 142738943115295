import { CoreModal } from "components/UI/Modal/Modal";
import { HighPriority } from "iconoir-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserProfile } from "Redux/user/Selectors/selectUserState";
import { Heading } from "shared/src/components/Heading/Heading";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import styles from "./RequiredTariffRoute.module.scss";

export const RequiredTariffRoute: React.FC = ({ children }) => {
  const { tariffId } = useSelector(selectUserProfile);
  const navigate = useNavigate();

  const onClick = () => navigate("/dashboard/billing");

  if (tariffId !== undefined) return <>{children}</>;
  return (
    <CoreModal
      titleMargin="0"
      showClose={false}
      closeOutClick={false}
      className={styles.wrapper}
      visible
      onClose={() => null}
    >
      <div className={styles.body}>
        <Heading className={styles.title} variant="h2">
          Attention
        </Heading>
        <HighPriority className={styles.icon} />
        <p className={styles.text}>
          To continue using Dashboard,
          <br /> please select Subscription Plan
        </p>
        <PbButton onClick={onClick}>Select Subscription Plan</PbButton>
      </div>
    </CoreModal>
  );
};
