import clsx from "clsx";
import React from "react";
import { PbButton } from "../PbButton/PbButton";
import style from "./emptyList.module.scss";

interface IProps {
  onClick: () => void;
  textButton: string;
  icon: React.ReactNode;
  noBorder?: boolean;
  children: React.ReactNode | string;
  noButton?: boolean;
  loading?: boolean;
  className?: string;
}
export const EmptyList: React.FC<IProps> = (props) => {
  const { onClick, icon, textButton, noBorder } = props;
  const { noButton, loading, children, className } = props;

  return (
    <div className={clsx(style.wrapper, className)} style={noBorder ? { paddingBottom: "0" } : {}}>
      {loading && <div style={{ position: "absolute", height: "100%", width: "100%", background: "white" }} />}
      <div className={clsx(style.wrapperGradient, noBorder && style.noGradient)}>
        <div className={style.innerBlock}>
          <div>
            <div className={style.icon}>{icon}</div>
            <div className={style.text}>{children}</div>
            {!noButton && (
              <div className={style.wrapButton} style={noBorder ? { width: "168px" } : {}}>
                <PbButton onClick={onClick} color="primary" size={noBorder ? "md" : "sm"}>
                  {textButton}
                </PbButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
