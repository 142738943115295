import * as React from "react";

function SvgGear() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.86297 13.7606C2.43922 12.8961 2.22735 12.4638 2.22735 12C2.22735 11.5362 2.43922 11.1039 2.86297 10.2394L4.20577 7.5L5.9068 4.96738C6.4436 4.16815 6.712 3.76854 7.11367 3.53663C7.51534 3.30473 7.99562 3.27209 8.95618 3.20682L12 3L15.0438 3.20682C16.0044 3.27209 16.4847 3.30473 16.8863 3.53663C17.288 3.76854 17.5564 4.16815 18.0932 4.96738L19.7942 7.5L21.137 10.2394C21.5608 11.1039 21.7727 11.5362 21.7727 12C21.7727 12.4638 21.5608 12.8961 21.137 13.7606L19.7942 16.5L18.0932 19.0326C17.5564 19.8318 17.288 20.2315 16.8863 20.4634C16.4847 20.6953 16.0044 20.7279 15.0438 20.7932L12 21L8.95617 20.7932C7.99562 20.7279 7.51534 20.6953 7.11367 20.4634C6.712 20.2315 6.4436 19.8318 5.9068 19.0326L4.20577 16.5L2.86297 13.7606Z"
        stroke="#D4D7E0" strokeWidth="1.5"/>
      <circle cx="12" cy="12" r="3" stroke="#D4D7E0" strokeWidth="1.5"/>
    </svg>

  );
}

export default SvgGear;
