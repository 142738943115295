import { TStore } from "Redux/rootReducer";
import { createSelector } from "reselect";

export const selectReduxState = (state: TStore) => state;
const selectAsyncButtonId = (_state: TStore, id: string) => id;

export const selectIsLoadingAsyncButton = createSelector([selectReduxState, selectAsyncButtonId], (state, itemId) => {
  return Object.values(state.ui.asyncButtonState).includes(itemId);
});

export const selectAsyncButtonStore = createSelector([selectReduxState], (state) => state.ui.asyncButtonState);
