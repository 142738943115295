import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CommonTariffModal = {
  isOpened: boolean;
  tariffId?: number;
};

type CancelModalState = {
  isCancelSwitching: boolean;
} & CommonTariffModal;

type SuccessSelectModal = {
  isOpened: boolean;
  title?: string;
  body?: string[];
  maxWidth?: number;
};

type CancelSubscriptionModalOpen = {
  tariffId: number;
  isCancelSwitching?: boolean;
};

export type BillingInfoUiState = {
  successSelectModal: SuccessSelectModal;
  cancelSubModal: CancelModalState;
};

const commonTariffModal: CommonTariffModal = {
  isOpened: false,
};
const cancelModalInitState: CancelModalState = {
  isOpened: false,
  isCancelSwitching: false,
};

const successModalInitState: SuccessSelectModal = {
  isOpened: false,
};

const initialState: BillingInfoUiState = {
  successSelectModal: successModalInitState,
  cancelSubModal: cancelModalInitState,
};

const billingInfoSlice = createSlice({
  name: "billingInfo",
  initialState,
  reducers: {
    successSelectModalOpen(state, action: PayloadAction<SuccessSelectModal>) {
      const { isOpened, title, body, maxWidth = 348 } = action.payload;
      return {
        ...state,
        successSelectModal: { isOpened, title, body, maxWidth },
      };
    },

    successSelectModalClose(state) {
      return {
        ...state,
        successSelectModal: commonTariffModal,
      };
    },

    cancelSubscriptionModalOpen(state, action: PayloadAction<CancelSubscriptionModalOpen>) {
      return {
        ...state,
        cancelSubModal: {
          isOpened: true,
          tariffId: action.payload.tariffId,
          isCancelSwitching: !!action.payload.isCancelSwitching,
        },
      };
    },

    cancelSubscriptionModalClose(state) {
      return {
        ...state,
        cancelSubModal: cancelModalInitState,
      };
    },
  },
});

export const {
  successSelectModalOpen,
  successSelectModalClose,
  cancelSubscriptionModalOpen,
  cancelSubscriptionModalClose,
} = billingInfoSlice.actions;

export const billingInfoUiReducer = billingInfoSlice.reducer;
