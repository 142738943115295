import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { setBucketPolicy } from "Redux/buckets/reducer";
import { handleSagaError } from "../../helpers";

export function* deleteBucketPolicyProcess({ payload }: PayloadAction<string>) {
  try {
    yield call([s3Client, s3Client.deleteBucketPolicy], payload);
    yield put(setBucketPolicy(undefined));
  } catch (err) {
    yield handleSagaError(err);
  }
}
