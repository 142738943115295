import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { S3 } from "aws-sdk";
import { UploadFileType } from "models/UploadFileType";
import { call, put, select } from "redux-saga/effects";
import { saveUploadManager, Upload } from "../reducer";
import { selectUpload } from "../Selectors/selectUpload";

type ProgressValues = { loaded: number; total: number };

export type UploadManager = {
  filemeta: {
    filepath: string;
    size: number;
    originFile: UploadFileType;
  };
  instance: S3.ManagedUpload;
  progress: number;
  progressValues: ProgressValues;
};

const createS3Instance = (props: { file: UploadFileType; pathFolder: string; nameBucket: string }): UploadManager => {
  const { file, pathFolder, nameBucket } = props;

  let path = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}${file.filepath}`;
  path = path.replace("//", "/");

  const instance = new S3.ManagedUpload({
    params: {
      Bucket: nameBucket,
      Key: path,
      Body: file,
      ContentType: file.type,
      StorageClass: "STANDARD",
    },
    service: s3Client.s3,
  });

  return {
    instance,
    filemeta: { filepath: file.filepath, size: file.size, originFile: file },
    progress: 0,
    progressValues: { loaded: 0, total: 0 } as ProgressValues,
  };
};

export function* prepareDropedFilesSaga({ payload: dropedFiles }: PayloadAction<UploadFileType[]>) {
  const { nameBucket, pathFolder }: Upload = yield select(selectUpload);
  // update s3 instance configuration
  yield call([s3Client, s3Client.checkBucketRegionAndUpdateClient], nameBucket);
  yield put(saveUploadManager(dropedFiles.map((x) => createS3Instance({ file: x, pathFolder, nameBucket }))));
}
