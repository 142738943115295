import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TStore } from "Redux/rootReducer";

export type CommonError = {
  message: string;
  isBottomText: boolean;
};

export type ErrorState = {
  commonErrors: CommonError[];
};

const initialState: ErrorState = {
  commonErrors: [],
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setCommonError(state, action: PayloadAction<CommonError>) {
      return {
        ...state,
        commonErrors: [...state.commonErrors, action.payload],
      };
    },
    clearCommonErrors() {
      return initialState;
    },
  },
});

export const { setCommonError, clearCommonErrors } = errorSlice.actions;

export const errorReducer = errorSlice.reducer;

export const selectErrors = createSelector([(state: TStore) => state], (state) => state.error.commonErrors);
