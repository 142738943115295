import { NotificationRep } from "models/Notification";
import { BasePagination } from "models/Pagination";
import { Notification } from "models/Notification";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

// marker
// notify slice
export const initPagination = { page: 1, perPage: 10, pagesCount: 1 };

export type NotifcationReducer = {
  notifications: NotificationRep;
  notificationsPagination: BasePagination;
  notificationsPanel: NotificationRep;
};

const initialState: NotifcationReducer = {
  notificationsPagination: initPagination,
  notificationsPanel: {
    items: [] as Notification[],
  } as NotificationRep,
  notifications: {} as NotificationRep,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNewNotice(state, action: PayloadAction<Notification>) {
      return {
        ...state,
        notificationsPanel: {
          items: [action?.payload, ...state.notificationsPanel.items],
          last: "3",
          readCount: state.notificationsPanel.readCount,
          totalCount: state.notificationsPanel.totalCount + 1,
          unreadCount: state.notificationsPanel.unreadCount + 1,
        },
      };
    },
    setNotifications(state, action: PayloadAction<NotificationRep>) {
      let page = action?.payload?.page ? +action?.payload?.page : 1;
      const pageCount = action?.payload?.pagesCount;

      if (pageCount && page > pageCount) {
        page = pageCount;
      }

      return {
        ...state,
        notifications: {
          items: action?.payload?.items,
          readCount: action?.payload?.readCount,
          totalCount: action?.payload?.totalCount,
          unreadCount: action?.payload?.unreadCount,
        },
        notificationsPagination: {
          page: page,
          pagesCount: pageCount || 1,
          perPage: action?.payload?.perPage || 10,
        },
      };
    },
    setNotificationsPagination(state, action: PayloadAction<any>) {
      let page = action?.payload?.page ? +action?.payload?.page : 1;
      if (page > action?.payload?.pagesCount) {
        page = action?.payload?.pagesCount;
      }
      return {
        ...state,
        notificationsPagination: { ...action.payload, page },
      };
    },
    setNotificationsPanel(state, action: PayloadAction<NotificationRep>) {
      return {
        ...state,
        notificationsPanel: action.payload,
      };
    },
  },
});

export const notificationReducer = notificationSlice.reducer;

export const { setNotifications, setNotificationsPagination, setNotificationsPanel, addNewNotice } =
  notificationSlice.actions;

export type GetNotificationPayload = any;
export const getNotifications = createAction<GetNotificationPayload>("getNotifications");

export const getNotificationsPanel = createAction("getNotificationsPanel");

export type SetAllReadNotificationPayload = any;
export const setMakeAllReadNotifications = createAction<SetAllReadNotificationPayload>("setMakeAllReadNotifications");
