export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
export function isFull(elem: any) {
  if (typeof elem === "undefined") {
    return false;
  }
  if (typeof elem === "object") {
    const stringifiedElem = JSON.stringify(elem, getCircularReplacer());
    if (elem === null) return false;
    try {
      if (stringifiedElem === "{}") return false;
      if (stringifiedElem === "[]") return false;
      if (stringifiedElem === "undefined") return false;
    } catch (err) {}
    for (const key in elem) {
      if (isFull(elem[key])) {
        return true;
      }
    }
    return Object.keys(elem)?.length === 0 && stringifiedElem?.length > 0;
  }
  if (typeof elem === "string") {
    if (elem === "{}" || elem === "[]") return false;
    if (elem === "''" || elem === '""') return false;
    return elem.length > 0;
  }
  if (Array.isArray(elem)) {
    if (elem.length > 0) {
      for (let i = 0; i < elem.length; i++) {
        if (isFull(elem[i])) return true;
      }
      return false;
    }
    return false;
  }
  if (typeof elem === "boolean") {
    return elem;
  }
  if (typeof elem === "number") {
    return true;
  }
  return true;
}
