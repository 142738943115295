import { useState } from "react";

export const useDialogState = (initialState = false) => {
  const [active, setActive] = useState(initialState);

  const close = () => setActive(false);
  const open = () => setActive(true);

  return { active, open, close };
};
