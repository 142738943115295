import { StripeElementsOptions } from "@stripe/stripe-js";

export const stripeOptions: StripeElementsOptions = {
  appearance: {
    variables: {
      borderRadius: "10px",
      fontFamily: "Source Sans Pro, serif",
      fontSizeBase: "14px",
      fontSizeSm: "12px",
      fontWeightLight: "600",
      fontWeightNormal: "600",
      fontWeightMedium: "600",
      fontWeightBold: "600",
      spacingGridRow: "16px",
      spacingGridColumn: "16px",
    },
    rules: {
      ".Input": {
        fontWeight: "600",
        padding: "12px 16px",
        lineHeight: "24px",
      },
    },
  },
  // todo change to .io fonts
  fonts: [
    {
      family: "Source Sans Pro",
      src: "url(https://petabox.dev/static/media/source-sans-pro-v21-latin-600.ba0db8c652c563d236e1.woff2)",
      weight: "600",
    },
    {
      family: "Source Sans Pro",
      src: "url(https://petabox.dev/static/media/source-sans-pro-v21-latin-400.c0d191aa7fb798623030.woff2)",
      weight: "400",
    },
  ],
  locale: "en",
};

export const STRIPE_SUBMIT_EVENT = "STIRIPE_SUBMIT_EVENT";
