/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { PayButton } from "components/BillingHistory/PayButton";
import { StyledLink } from "components/UI/StyledLink/StyledLink";
import { format } from "date-fns";
import { GoogleDocs, LogIn, User, WarningTriangleOutline } from "iconoir-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUserProfile } from "Redux/user/Selectors/selectUserState";
import { centsToUsd, prettySize } from "shared/src/helpers/utils";
import { UserStatusEnum } from "shared/src/models/UserModel";
import { truncateString } from "../../helpers/truncateString";
import SvgGear from "../../icons/Gear";
import { selectTariffUserPlan } from "../../Redux/account/Selectors/selectTariffUserPlan";
import { selectUserUsage } from "../../Redux/account/Selectors/selectUserUsage";
import { selectUserData } from "../../Redux/user/Selectors/selectUserData";
import { ProgressBar } from "../UI/ProgressBar/ProgressBar";
import style from "./header.module.scss";

type DropDownMenuProps = {
  onLogout: () => void;
  onClose: () => void;
};

export const DropDownMenu = React.forwardRef<HTMLDivElement, DropDownMenuProps>(({ onLogout, onClose }, dropRef) => {
  const userPlan = useSelector(selectTariffUserPlan);
  const userData = useSelector(selectUserData);
  const userUsage = useSelector(selectUserUsage);
  const [limitsExceeded, setLimitsExtended] = useState(false);
  const { status } = useSelector(selectUserProfile);
  const trial = status === UserStatusEnum.trial;
  const paymentId = userPlan?.failedTransactionPaymentIntentId;

  const { t } = useTranslation();

  useEffect(() => {
    if (!userPlan || !userUsage) return;
    if (
      +userUsage.storageUsage.size >= +userPlan?.tariff.storage_size ||
      +userUsage.bandwidthUsage.size >= +userPlan?.tariff.bandwidth
    ) {
      setLimitsExtended(true);
    }
  }, []);

  return (
    <div className={style.dropdownPanel} ref={dropRef}>
      <div className={style.dropdownBlock}>
        <div className={style.dropdownItem}>
          <div className={style.smallIcon}>
            <User width={24} height={24} color="#D4D7E0" />
          </div>
          <div className={clsx(style.dropdownItemTextBlock, style.wallet_address)}>
            <span className={style.userName}>{truncateString(userData.name, 22)}</span>
            {userData && <span className={style.userEmail}>{userData.email}</span>}
          </div>
        </div>
      </div>
      <div className={clsx(style.dropdownBlock, style.flexRowBox)}>
        <div className={style.dropdownItem}>
          <div className={style.smallIcon}>
            <GoogleDocs width={24} height={24} color="#D4D7E0" />
          </div>
          <div className={clsx(style.dropdownItemTextBlock)}>
            <div className={style.textTariff}>{t("tariff")}</div>
            <div className={style.tariffNameWrapper}>
              <div className={style.tariffName}>{userPlan?.tariff.name}</div>
              {trial && <div className={style.tariffNameTrial}>&nbsp;&nbsp;|&nbsp;&nbsp;Trial</div>}
            </div>
            <div className={style.tariffMoney}>
              {centsToUsd(userPlan?.tariff.price ?? 0)}
              <span className={style.tariffPeriod}>/ {t("month")}</span>
            </div>
          </div>
        </div>
        <div className={style.dropdownItem}>
          <div className={style.dropdownItemTextBlock}>
            <div className={style.progressWrapper}>
              {userPlan && userUsage && (
                <>
                  <div className={style.progressText}>
                    <div className={style.progressType}>{t("storage")}</div>
                    <div className={style.progressVolume}>
                      {prettySize(String(userUsage.storageUsage.size))} /{" "}
                      {prettySize(String(userPlan.tariff.storage_size))}
                    </div>
                  </div>
                  <ProgressBar
                    limit={trial ? 1099511627776 : +userPlan?.tariff.storage_size}
                    value={+userUsage.storageUsage.size}
                  />
                </>
              )}
            </div>
            <div className={style.progressWrapper}>
              {userPlan && userUsage && (
                <>
                  <div className={style.progressText}>
                    <div className={style.progressType}>{t("transfer")}</div>
                    <div className={style.progressVolume}>
                      {prettySize(String(userUsage.bandwidthUsage.size))} /{" "}
                      {prettySize(String(userPlan.tariff.bandwidth))}
                    </div>
                  </div>

                  <ProgressBar
                    limit={trial ? 1099511627776 : +userPlan?.tariff.bandwidth}
                    value={+userUsage.bandwidthUsage.size}
                  />
                </>
              )}
            </div>
            {limitsExceeded && (
              <div className={style.alert}>
                <WarningTriangleOutline width={12} height={12} color="#DC6B41" />
                <span style={{ textTransform: "capitalize" }}>{t("limits exceed")}</span>
              </div>
            )}
          </div>
        </div>
        <div className={style.dropdownItem}>
          <div className={style.dropdownItemTextBlock}>
            {userPlan && (
              <div className={style.periodBlock}>
                <div className={style.periodRow}>
                  <div className={style.periodText}>{trial ? t("Trial period start") : t("period start")}</div>{" "}
                  <div className={style.periodDate}>{format(+userPlan?.created_at, "P")}</div>
                </div>
                <div className={style.periodRow}>
                  <div className={style.periodText}>{trial ? t("Trial period end") : t("period end")}</div>{" "}
                  <div className={style.periodDate}>{format(+userPlan?.end_at, "P")}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        {[UserStatusEnum.suspended, UserStatusEnum.payment_required].includes(status) && (
          <div className={style.dropdownItem}>
            <div className={style.dropdownItemTextBlock}>
              <div className={style.suspendedBlock}>
                <div className={style.alert}>
                  <WarningTriangleOutline width={12} height={12} color="#DC6B41" />
                  <span>{t("Your account is suspended")}.</span>
                </div>
                {paymentId && (
                  <PayButton
                    renderButton={(open) => <StyledLink onClick={open}>{t("Please pay your invoice")}</StyledLink>}
                    paymentId={paymentId}
                    tariffId={userPlan.tariff_id}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={clsx(style.dropdownBlock, style.flexRowBox)}>
        <div className={clsx(style.dropdownItem, style.button)}>
          <div className={style.smallIcon}>
            <SvgGear />
          </div>
          <div className={style.dropdownItemTextBlock}>
            <Link className={style.text} to="/dashboard/settings" onClick={onClose}>
              {t("Account Settings")}
            </Link>
          </div>
        </div>
        <div className={clsx(style.dropdownItem, style.button)}>
          <div className={style.smallIcon}>
            <LogIn width={24} height={24} color="#D4D7E0" />
          </div>
          <div className={style.dropdownItemTextBlock}>
            <div style={{ textTransform: "capitalize" }} className={style.text} onClick={onLogout}>
              {t("logout")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
