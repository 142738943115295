import { IStorageClassInfo } from "actions/interfaces";
import { call, put } from "redux-saga/effects";
import { apiUrl, fetchApi } from "helpers/common";
import { setStorageClassesInfo } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getStorageClassesInfoProcess() {
  try {
    const classes: IStorageClassInfo[] = yield call(fetchApi, "get", "200", {
      url: `/storage-classes`,
      mainUrl: apiUrl,
    });
    yield put(setStorageClassesInfo(classes));
  } catch (err) {
    console.error(err);
    yield handleSagaError(err);
  }
}
