import { catalogApi } from "api/CatalogApi";
import { all, call, put } from "redux-saga/effects";
import { setTariffCatalog } from "../catalogSlice";

export function* getCatalogSaga() {
  try {
    const [tariffCatalog] = yield all([call([catalogApi, catalogApi.getTarriffsData])]);
    yield put(setTariffCatalog(tariffCatalog));
  } catch (e) {
    console.error(e);
  }
}
