import { TonConnectButton, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkProofTonWallet, getTonConnectPayload } from "../../Redux/user/Actions/userActions";
import { selectTonConnectPayload } from "../../Redux/user/Selectors/selectTonConnectPayload";
import style from "./loginPage.module.scss";

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const tonConnectWalletPayload = useSelector(selectTonConnectPayload);
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const l1 = useRef(false);

  useEffect(() => {
    if (tonConnectWalletPayload?.payload) {
      tonConnectUI.setConnectRequestParameters({
        state: "ready",
        value: {
          tonProof: tonConnectWalletPayload?.payload,
        },
      });
    } else {
      dispatch(getTonConnectPayload());
    }
  }, [tonConnectWalletPayload?.payload]);

  useEffect(() => {
    const setWallet = async () => {
      if (wallet && wallet.connectItems?.tonProof && "proof" in wallet.connectItems.tonProof) {
        l1.current = true;
        dispatch(
          checkProofTonWallet({
            proof: {
              ...wallet?.connectItems?.tonProof?.proof,
              state_init: wallet.account.walletStateInit,
            },
            address: wallet.account.address,
            network: wallet.account.chain,
            public_key: wallet.account.publicKey as string,
          })
        );
        l1.current = false;
      }
      l1.current = false;
    };
    if (!l1.current) {
      setWallet();
    }
  }, [wallet]);

  return (
    <div className={style.loginPage}>
      <TonConnectButton />
    </div>
  );
};
