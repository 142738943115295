import { PayloadAction } from "@reduxjs/toolkit";
import { IGetTicketsPayload } from "actions/accountActionTypes";
import { all, call, put } from "redux-saga/effects";
import { setSupportData } from "Redux/account/reducer";
import { apiUrl, fetchApi } from "../../../helpers/common";
import { handleSagaError } from "Redux/helpers";

export function* getSupportDataProcess({ payload }: PayloadAction<IGetTicketsPayload>) {
  const { status, afterLoad } = payload;
  try {
    const data = yield all([
      call(fetchApi, "get", "200", {
        url: "/tickets/by-user",
        mainUrl: apiUrl,
        query: { status: status, page: 1, perPage: 10000000 },
      }),
      call(fetchApi, "get", "200", { url: "/handbook/tickets", mainUrl: apiUrl }),
    ]);
    yield put(setSupportData(data));
    afterLoad();
  } catch (err) {
    yield handleSagaError(err, "Get support data");
  }
}
