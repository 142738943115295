import { TStore } from "Redux/rootReducer";
import { createSelector } from "reselect";

const getNotificationState = (rootState: TStore) => rootState.notifications;

export const selectNotifications = createSelector(getNotificationState, (state) => state.notifications);

export const selectNotificationsPagination = createSelector(
  getNotificationState,
  (state) => state.notificationsPagination
);

export const selectNotificationsUnreadCount = createSelector(selectNotifications, (state) => state.unreadCount);
