import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISetStatisticsHomePayload } from "actions/actionTypes";
import { IGuideRegion, IStorageClassInfo } from "actions/interfaces";
import {
  IContentsFile,
  IFoldersList,
  IObjectExtendedInfo,
  ISearchedFile,
  ISearchFilesResult,
} from "api/s3-client.types";
import S3, { BucketVersioningStatus, Grants, Owner, Policy } from "aws-sdk/clients/s3";
import { mergeObjectInArray } from "helpers/utils";
import { cloneDeep } from "lodash";
import { BandwidthAnalyticsRecord, StorageUsageAnalyticsRecord } from "models/Analytics";
import { ListVersionsOfSpecificObjectResponse } from "models/FileVersion";
import { BasePagination, BucketPagination } from "models/Pagination";
import { Region } from "models/S3Regions";
import { Statistics } from "models/Statistics";
import { getBucketsList } from "Redux/buckets/Actions/bucketsActions";
import { ResourcesSharedWithMe, SharingListItem } from "../../models/ShareList";
import { UploadManager } from "./Sagas/prepareDropedFilesSaga";

const initPagination: BucketPagination = {
  Page: 1,
  PerPage: 10,
  PagesCount: 1,
  Name: "",
  Prefix: "",
};

export interface IBucket {
  Name: string;
  ACL: "public-read" | "private";
  CreationDate: string;
  Region: Region;
  ObjectsCount: string;
  ObjectsWithoutFoldersCount: string;
  VersioningStatus: "Disabled" | "Enabled" | "Suspended";
}

export interface ICurrentBucket {
  filesList?: IContentsFile[];
  foldersList?: IFoldersList[];
  nameBucket?: string;
  pathFolder?: string;
  pagination?: BasePagination;
}

export type StoreFile = {
  nameBucket?: string;
  pathFolder?: string;
  data: IObjectExtendedInfo;
  fileVersions?: ListVersionsOfSpecificObjectResponse;
};

export type UploadManagerStore = {
  [key: string]: UploadManager;
};

export type Upload = {
  isModalUpload: boolean;
  nameBucket: string;
  pathFolder: string;
  isLoadingFiles: boolean;
  sharing?: boolean;
  callBack?: () => void;
};

type StatisticsHome = {
  statistics: Statistics;
  bandwidthAnalytics: {
    Records: BandwidthAnalyticsRecord[];
  };
  storageUsageAnalytics: {
    Records: StorageUsageAnalyticsRecord[];
  };
};

type UploadInfoTotal = {
  filesNumber: number;
  loadedFilesNumber: number;
  filesTotalSize: number;
};

export type FilePath = string;

const uploadInfoTotalInitial: UploadInfoTotal = {
  filesNumber: 0,
  loadedFilesNumber: 0,
  filesTotalSize: 0,
};

export interface IBucketsState {
  bucketsList: IBucket[];
  currentBucket: ICurrentBucket;
  paginationBuckets: BucketPagination;
  paginationFiles: BucketPagination;
  paginationNotifications: BucketPagination;
  loadingPage: string;
  storageClasses: IGuideRegion[];
  storageClassesInfo: IStorageClassInfo[];
  regions: IGuideRegion[];
  file: StoreFile;
  statisticsHome: StatisticsHome;
  searchedFiles: ISearchedFile[];
  s3regions?: Region[];
  bucketLogging: {
    loggingStatus?: S3.LoggingEnabled;
  };
  currentBucketInfo: {
    currentBucketVersioningStatus?: BucketVersioningStatus;
    showFileVersionsInList: boolean;
    grants: Grants;
    sharingList: SharingListItem[];
    owner?: Owner;
    objectLockConfig?: S3.ObjectLockConfiguration & { backetName: string };
    objectRetention?: S3.ObjectLockRetention;
    objectLegalHold?: S3.ObjectLockLegalHold;
  };
  upload: Upload;
  uploadState: UploadManagerStore;
  uploadStateInfo: UploadInfoTotal;
  bucketPolicy?: Policy;
  resourcesSharedWithMe?: ResourcesSharedWithMe;
}

const initialState: IBucketsState = {
  bucketsList: [],
  paginationBuckets: initPagination,
  paginationFiles: initPagination,
  paginationNotifications: initPagination,
  currentBucket: {
    filesList: [],
    nameBucket: "",
  } as ICurrentBucket,
  file: {} as StoreFile,
  loadingPage: "",
  storageClasses: [],
  storageClassesInfo: [],
  regions: [],
  upload: {} as Upload,
  statisticsHome: {} as StatisticsHome,
  searchedFiles: [],
  s3regions: [],
  bucketLogging: {
    loggingStatus: undefined,
  },
  currentBucketInfo: {
    currentBucketVersioningStatus: undefined,
    showFileVersionsInList: false,
    grants: [],
    sharingList: [],
    owner: undefined,
    objectLockConfig: undefined,
    objectRetention: undefined,
    objectLegalHold: undefined,
  },
  uploadState: {} as UploadManagerStore,
  uploadStateInfo: uploadInfoTotalInitial,
  bucketPolicy: undefined,
  resourcesSharedWithMe: undefined,
};

const bucketsSlice = createSlice({
  name: "buckets",
  initialState,
  reducers: {
    setBucketPolicy(state, action: PayloadAction<Policy | undefined>) {
      return {
        ...state,
        bucketPolicy: action.payload,
      };
    },
    setAclOwner(state, action: PayloadAction<Owner>) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          owner: action.payload,
        },
      };
    },
    setObjectLockConfiguration(
      state,
      action: PayloadAction<(S3.ObjectLockConfiguration & { backetName: string }) | undefined>
    ) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          objectLockConfig: action.payload,
        },
      };
    },
    setObjectRetention(state, action: PayloadAction<S3.ObjectLockRetention | undefined>) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          objectRetention: action.payload,
        },
      };
    },
    setObjectLegalHold(state, action: PayloadAction<S3.ObjectLockLegalHold | undefined>) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          objectLegalHold: action.payload,
        },
      };
    },
    setAclGrants(state, action: PayloadAction<Grants>) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          grants: action.payload,
        },
      };
    },
    setShareListGrants(state, action: PayloadAction<SharingListItem[]>) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          sharingList: action.payload,
        },
      };
    },
    setResourcesSharedWithMe(state, action: PayloadAction<ResourcesSharedWithMe>) {
      return {
        ...state,
        resourcesSharedWithMe: action.payload,
      };
    },
    updateBucket(state, action: PayloadAction<Partial<IBucket> & { Name: string }>) {
      const { bucketsList } = state;
      const newBucketList = mergeObjectInArray(action.payload, bucketsList, "Name");
      return { ...state, bucketsList: newBucketList };
    },
    disableShowFileVersionsInList(state) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          showFileVersionsInList: false,
        },
      };
    },
    toggleShowFileVersionsInList(state) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          showFileVersionsInList: !state.currentBucketInfo.showFileVersionsInList,
        },
      };
    },
    setBucketLoggingStatus(state, action: PayloadAction<S3.LoggingEnabled | undefined>) {
      return {
        ...state,
        bucketLogging: {
          loggingStatus: action.payload,
        },
      };
    },
    setCurrentBucketVersioningStatus(state, action: PayloadAction<BucketVersioningStatus | undefined>) {
      return {
        ...state,
        currentBucketInfo: {
          ...state.currentBucketInfo,
          currentBucketVersioningStatus: action.payload,
        },
      };
    },
    setAllS3Regions(state, action: PayloadAction<Region[]>) {
      return { ...state, s3regions: action.payload };
    },
    setBucketsList(state, action: PayloadAction<IBucket[]>) {
      return { ...state, bucketsList: action.payload };
    },
    setFilesList(state, action: PayloadAction<ICurrentBucket>) {
      return { ...state, currentBucket: action.payload };
    },
    setFileInfo(state, action: PayloadAction<StoreFile>) {
      return { ...state, file: action.payload };
    },
    setFilePagination(state, action: PayloadAction<{ page?: number; perPage?: number }>) {
      return {
        ...state,
        currentBucket: {
          ...state.currentBucket,
          pagination: { ...state.currentBucket?.pagination, ...action.payload },
        },
      };
    },
    setStorageClasses(state, action: PayloadAction<IGuideRegion[]>) {
      return { ...state, storageClasses: action.payload };
    },
    setRegions(state, action: PayloadAction<IGuideRegion[]>) {
      return { ...state, regions: action.payload };
    },
    setStorageClassesInfo(state, action: PayloadAction<IStorageClassInfo[]>) {
      return { ...state, storageClassesInfo: action.payload };
    },
    setStatisticsHome(state, action: PayloadAction<ISetStatisticsHomePayload>) {
      return { ...state, statisticsHome: action.payload };
    },
    setSearchedFilesList(state, action: PayloadAction<ISearchFilesResult>) {
      let searchedFiles = action.payload.Files;

      if (action.payload.Query.page > 1) {
        searchedFiles = [...state.searchedFiles, ...searchedFiles];
      }
      return {
        ...state,
        searchedFiles,
      };
    },
    setUpload(state, action: PayloadAction<Partial<Upload>>) {
      return { ...state, upload: { ...state.upload, ...action.payload } };
    },
    saveUploadManager(state, action: PayloadAction<UploadManager[]>) {
      const managers = action.payload;
      const prevUploads = state.uploadState;
      const { filesNumber, loadedFilesNumber, filesTotalSize } = state.uploadStateInfo;

      let [newNewFilesNumber, newLoadedFilesNumber, newFilesTotalSize] = [
        filesNumber,
        loadedFilesNumber,
        filesTotalSize,
      ];

      const newUploads = {} as { [key: string]: UploadManager };

      for (const manager of managers) {
        const { filepath, size } = manager.filemeta;
        newUploads[filepath] = manager;
        newNewFilesNumber += 1;
        newFilesTotalSize += size;
      }

      return {
        ...state,
        uploadState: { ...prevUploads, ...newUploads },
        uploadStateInfo: {
          filesNumber: newNewFilesNumber,
          loadedFilesNumber: newLoadedFilesNumber,
          filesTotalSize: newFilesTotalSize,
        },
      };
    },
    updateUploadProgress(
      state,
      action: PayloadAction<{ total: number; loadedPercent: number; filepath: string; loaded: number }>
    ) {
      const { total, loadedPercent, loaded, filepath } = action.payload;
      const { loadedFilesNumber } = state.uploadStateInfo;
      let newLoadedFilesNumber = loadedPercent === 100 ? loadedFilesNumber + 1 : loadedFilesNumber;

      const upload = state.uploadState[filepath];
      const newUpload: UploadManager = {
        ...upload,
        progress: Math.trunc(loadedPercent),
        progressValues: {
          total,
          loaded,
        },
      };

      return {
        ...state,
        uploadState: { ...state.uploadState, [filepath]: newUpload },
        uploadStateInfo: {
          ...state.uploadStateInfo,
          loadedFilesNumber: newLoadedFilesNumber,
        },
      };
    },
    clearUploadFile(state, action: PayloadAction<FilePath>) {
      const { uploadState, uploadStateInfo } = state;
      const filepath = action.payload;
      const upload = uploadState[filepath];
      const newUploadState = cloneDeep(uploadState);

      const {
        filemeta: { size },
      } = upload;
      delete newUploadState[filepath];

      return {
        ...state,
        uploadState: newUploadState,
        uploadStateInfo: {
          ...uploadStateInfo,
          filesNumber: uploadStateInfo.filesNumber - 1,
          filesTotalSize: uploadStateInfo.filesTotalSize - size,
        },
        upload: {
          ...state.upload,
          isLoadingFiles: !!Object.keys(uploadState).length,
        },
      };
    },
    clearAllUploads(state) {
      return {
        ...state,
        uploadState: {},
        upload: { ...state.upload, isModalUpload: false, isLoadingFiles: false },
        uploadStateInfo: uploadInfoTotalInitial,
      };
    },
  },
  extraReducers: {
    [getBucketsList.type]: (state) => {
      return { ...state, loadingPage: "buckets" };
    },
  },
});

export const buckets = bucketsSlice.reducer;

export const {
  setAclOwner,
  setObjectLockConfiguration,
  setObjectRetention,
  setObjectLegalHold,
  setAclGrants,
  updateBucket,
  disableShowFileVersionsInList,
  toggleShowFileVersionsInList,
  setBucketLoggingStatus,
  setCurrentBucketVersioningStatus,
  setAllS3Regions,
  setBucketsList,
  setFilesList,
  setFileInfo,
  setFilePagination,
  setStorageClasses,
  setRegions,
  setStorageClassesInfo,
  setStatisticsHome,
  setSearchedFilesList,
  setUpload,
  saveUploadManager,
  updateUploadProgress,
  clearUploadFile,
  clearAllUploads,
  setShareListGrants,
  setBucketPolicy,
  setResourcesSharedWithMe,
} = bucketsSlice.actions;
