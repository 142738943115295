import { DeleteCircledOutline } from "iconoir-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSuspendedModal } from "../../../../Redux/ui/uiState";
import Button from "../../Button/Button";
import LinkComponent from "../../Link/Link";
import { CoreModal } from "../Modal";
import style from "./suspendedModal.module.scss";

type SuspendedModalProps = {
  visible: boolean;
};

export const SuspendedModal: React.FC<SuspendedModalProps> = ({ visible = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setSuspendedModal(false));
  };

  return (
    <CoreModal className={style.wrap} closeOutClick={true} onClose={onClose} visible={visible} titleMargin="10px">
      <div>
        <div className={style.icon}>
          <DeleteCircledOutline strokeWidth={1} width={40} height={40} color="#FE5F55" />
        </div>
        <div className={style.bottom}>
          {t("Your account is suspended, and you have only read-only access to your data.")}
          <br />
          {t("You can upgrade your account  at")}{" "}
          <LinkComponent
            onClickHandler={() => {
              navigate("/dashboard/billing");
              onClose();
            }}
          >
            {t("billing")}
          </LinkComponent>{" "}
          {t("page")}
        </div>
        <Button color="secondary" size="buttonlarge" onClick={onClose}>
          {t("Ok")}
        </Button>
      </div>
    </CoreModal>
  );
};
