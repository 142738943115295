import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import {
  BucketSharingList,
  KeySharingListRoot,
  PrefixSharingListRoot,
  SharingListItem,
  SharingListType,
} from "../../../models/ShareList";
import { setShareListGrants } from "../reducer";

export function* getShareListProcess({ payload }: PayloadAction<{ path: string; type: SharingListType }>) {
  try {
    let list: SharingListItem[] | undefined = undefined;
    switch (payload.type) {
      case SharingListType.Bucket:
        const shareBucketListOutput: BucketSharingList = yield call([s3Client, s3Client.getBucketShare], payload.path);
        list = shareBucketListOutput.BucketSharingList.SharingList;
        break;
      case SharingListType.Folder:
        const shareFolderListOutput: PrefixSharingListRoot = yield call(
          [s3Client, s3Client.getPrefixSharingList],
          payload.path
        );
        list = shareFolderListOutput.PrefixSharingList.SharingList;
        break;
      case SharingListType.File:
        const shareFileListOutput: KeySharingListRoot = yield call([s3Client, s3Client.geKeySharingList], payload.path);
        list = shareFileListOutput.KeySharingList.SharingList;
        break;
    }
    if (list) {
      yield put(setShareListGrants(list));
    }
  } catch (err) {
    yield handleSagaError(err);
  }
}
