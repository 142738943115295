import clsx from "clsx";
import { Avatar, AvatarProps } from "components/Avatar/Avatar";
import { first, size } from "lodash";
import React, { useState } from "react";
import classes from "./Stack.module.scss";

type StackProps = {
  offset?: number;
  presisions?: "px" | "%";
  direction?: "horizontal" | "vertical";
  className?: string;
};

export const isAvatarChild = (
  x?: React.ReactChild | React.ReactFragment | React.ReactPortal
): x is React.ReactElement<AvatarProps> => {
  if (React.isValidElement(x)) {
    // @ts-expect-error nice
    return x.type.name === Avatar.name;
  }
  return false;
};

export const Stack: React.FC<StackProps> = ({
  children,
  direction = "horizontal",
  className,
  offset = 50,
  presisions = "%",
}) => {
  const horizontalDir = direction === "horizontal";
  const [styles] = useState(() => {
    const container: React.CSSProperties = {
      display: "flex",
    };
    container.flexDirection = horizontalDir ? "row" : "column";

    return {
      container: container,
    };
  });
  const childrens = React.Children.toArray(children);
  const childrensToRender = childrens.slice(0, 3);
  const rest = size(childrens) - size(childrensToRender);
  const cnt = rest > 100 ? "99+" : `${rest}+`;

  const component = first(childrens);

  const isAvatarComponent = isAvatarChild(component);

  const makeItemsStyle = (idx: number): React.CSSProperties => ({
    transformOrigin: "center",
    transform: horizontalDir
      ? `translateX(-${offset * idx}${presisions})`
      : `translateY(-${offset * idx}${presisions})`,
  });

  return (
    <div className={clsx(className)} style={styles.container}>
      {React.Children.map(childrensToRender, (child, idx) => {
        return (
          <div style={makeItemsStyle(idx)} key={idx}>
            {child}
          </div>
        );
      })}
      {rest > 0 && isAvatarComponent && (
        <div style={makeItemsStyle(3)}>
          {React.cloneElement(component, { className: classes.lastChildAvatar, name: cnt, cutName: false })}
        </div>
      )}
    </div>
  );
};
