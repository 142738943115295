/* eslint-disable react/jsx-no-target-blank */
import clsx from "clsx";
import { ArrowRight } from "iconoir-react";
import { Link } from "react-router-dom";
import styles from "./StyledLink.module.scss";

type LinkType = "native" | "react-router";
export type StyledLinkSize = "lg" | "md";
export type StyledLinkProps = {
  to?: string;
  type?: LinkType;
  onClick?: () => void;
  className?: string;
  size?: "lg" | "md";
  icon?: JSX.Element | true;
  blankLink?: boolean;
  iconPosition?: "start" | "end";
};

const SizeMap = {
  lg: styles.lg,
  md: styles.md,
};

export const StyledLink: React.FC<StyledLinkProps> = ({
  type = "react-router",
  size = "md",
  children,
  to,
  onClick,
  className,
  icon,
  blankLink,
  iconPosition = "end",
}) => {
  const sizeClass = SizeMap[size];
  const classes = clsx(styles.link, className, sizeClass);
  const iconSize = size === "md" ? "20px" : "24px";
  const Icon = typeof icon === "boolean" ? <ArrowRight height={iconSize} width={iconSize} /> : icon;

  if (type === "native") {
    return (
      <a target={blankLink ? "_blank" : undefined} className={classes} onClick={onClick} href={to}>
        {Icon && iconPosition === "start" && <span className={clsx(styles.icon, styles.iconLeft)}>{Icon}</span>}
        {children}
        {Icon && iconPosition === "end" && <span className={clsx(styles.icon, styles.iconRight)}>{Icon} </span>}
      </a>
    );
  }
  return (
    <Link
      className={classes}
      onClick={(e) => {
        if (to === undefined) e.preventDefault();
        onClick?.();
      }}
      to={to || ""}
    >
      {Icon && iconPosition === "start" && <span className={clsx(styles.icon, styles.iconLeft)}>{Icon}</span>}
      {children}
      {Icon && iconPosition === "end" && <span className={clsx(styles.icon, styles.iconRight)}>{Icon} </span>}
    </Link>
  );
};
