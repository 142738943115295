import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { PrivacyFilePayload } from "models/PrivacyFilePayload";
import { call, put, select } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { selectIsCanShare } from "Redux/user/Selectors/selectIsCanShare";
import { getFileInfo, getFilesList } from "../Actions/bucketsActions";

export function* privacyFileProcess({ payload }: PayloadAction<PrivacyFilePayload>) {
  const { nameBucket, nameFile, pathFolder, typePrivacy, typePage, versionId } = payload;
  try {
    const isCanShare = yield select(selectIsCanShare);
    if (!isCanShare) {
      throw Error(
        "We disabled this function for security reasons. However, if it is essential to you, please get in touch with support."
      );
    }

    yield call([s3Client, s3Client.privacyFileFunc], {
      nameBucket,
      pathFolder,
      name: nameFile,
      typePrivacy,
      versionId,
    });
    const pagination = yield select((store) => store.buckets?.currentBucket?.pagination);

    if (typePage === "bucketPage") {
      yield put(
        getFilesList({
          nameBucket,
          pathFolder,
          afterLoad: () => {},
          pagination: { Page: pagination?.page || 1, PerPage: pagination?.perPage || 10 },
        })
      );
    }

    if (typePage === "filePage") {
      yield put(
        getFileInfo({
          nameFile,
          nameBucket,
          pathFolder,
          versionId,
          afterLoad: () => {},
        })
      );
    }
  } catch (err) {
    yield handleSagaError(err);
  }
}
