import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { BucketLogging } from "models/BucketLogging";
import { call } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";

export function* setBucketLoggingProcess({ payload }: PayloadAction<BucketLogging>) {
  try {
    yield call([s3Client, s3Client.toggleBucketLogging], payload);
  } catch (err) {
    yield handleSagaError(err);
  }
}
