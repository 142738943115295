import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import S3 from "aws-sdk/clients/s3";
import { call, put } from "redux-saga/effects";
import { setBucketLoggingStatus } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getBucketLoggingStatusProcess({ payload }: PayloadAction<string>) {
  try {
    const loggingStatus: S3.LoggingEnabled | undefined = yield call([s3Client, s3Client.getBucketLogging], payload);
    yield put(setBucketLoggingStatus(loggingStatus));
  } catch (err) {
    yield handleSagaError(err);
  }
}
