const env = process.env;

type EnvValue<T extends string | boolean> = T;

const setupEnv = <T extends string | boolean>(defaultValue: T, envKey?: string): EnvValue<T> => {
  return (envKey as EnvValue<T>) || defaultValue;
};

type AppConfigStruct = {
  environment: string;
  baseUrl: string;
  urlAuthNode: string;
  apiUrl: string;
  stripePublic: string;
  type?: string;
  isProd: boolean;
  technicalProcess: boolean;
  loginUrl: string;
};

const origin = window.location.origin;

const LocalConfig: AppConfigStruct = {
  environment: "development",
  baseUrl: setupEnv("https://s3.petabox.dev"),
  urlAuthNode: setupEnv("http://localhost:3000"),
  apiUrl: setupEnv("http://localhost:3333"),
  stripePublic: setupEnv(
    "pk_test_51L4TDjKvGPabe1bJoOg9Hg7CG7RuzeeUeEkSF8QgRSQ0wRSXVLrdTXQ9U3wcUKAoqlavOMPXpvYtkyec6aItuxPu00wWdGrBtO"
  ),
  isProd: setupEnv(false),
  technicalProcess: setupEnv(false),
  loginUrl: setupEnv(`/dashboard/login`),
};
const DevConfig: AppConfigStruct = {
  environment: "development",
  baseUrl: setupEnv("https://s3.petabox-dev.dccore.tech", env.REACT_APP_COLDSTACK_S3_ENDPOINT),
  urlAuthNode: setupEnv("https://auth.petabox-dev.dccore.tech", env.REACT_APP_AUTHNODE_URL),
  apiUrl: setupEnv("https://dhb-api.petabox-dev.dccore.tech", env.REACT_APP_API_URL),
  stripePublic: setupEnv(
    "pk_test_51L4TDjKvGPabe1bJoOg9Hg7CG7RuzeeUeEkSF8QgRSQ0wRSXVLrdTXQ9U3wcUKAoqlavOMPXpvYtkyec6aItuxPu00wWdGrBtO",
    env.REACT_APP_STRIPE_PUBLIC
  ),
  isProd: setupEnv(false),
  technicalProcess: setupEnv(false),
  loginUrl: setupEnv(`https://petabox-dev.dccore.tech/signin?redirectTo=${origin}`, env.REACT_APP_AUTH_LINK),
};

const StageConfig: AppConfigStruct = {
  environment: "staging",
  baseUrl: setupEnv("https://s3.petabox.dev", env.REACT_APP_COLDSTACK_S3_ENDPOINT),
  urlAuthNode: setupEnv("https://auth.petabox.dev", env.REACT_APP_AUTHNODE_URL),
  apiUrl: setupEnv("https://dhb-api.petabox.dev", env.REACT_APP_API_URL),
  stripePublic: setupEnv(
    "pk_test_51L4TDjKvGPabe1bJoOg9Hg7CG7RuzeeUeEkSF8QgRSQ0wRSXVLrdTXQ9U3wcUKAoqlavOMPXpvYtkyec6aItuxPu00wWdGrBtO",
    env.REACT_APP_STRIPE_PUBLIC
  ),
  isProd: setupEnv(true),
  technicalProcess: setupEnv(false),
  loginUrl: setupEnv(`https://petabox.dev/signin?redirectTo=${origin}`, env.REACT_APP_AUTH_LINK),
};

const ProductionConfig: AppConfigStruct = {
  environment: "production",
  baseUrl: String(env.REACT_APP_COLDSTACK_S3_ENDPOINT || ""),
  urlAuthNode: String(env.REACT_APP_AUTHNODE_URL || ""),
  apiUrl: String(env.REACT_APP_API_URL || ""),
  stripePublic: String(env.REACT_APP_STRIPE_PUBLIC || ""),
  type: String(env.REACT_APP_TYPE),
  isProd: String(env.REACT_APP_ENV) === "production",
  technicalProcess: env.REACT_APP_TECHNICAL_PROCESS === "true" ? true : false,
  loginUrl: String(env.REACT_APP_AUTH_LINK || ""),
};

const setupConfig = (): AppConfigStruct => {
  const appEnv = String(env.REACT_APP_ENV || "production");
  switch (true) {
    case appEnv === "production": {
      return ProductionConfig;
    }
    case appEnv === "development": {
      console.log({ DevConfig });
      return DevConfig;
    }
    case appEnv === "staging": {
      console.log({ StageConfig });
      return StageConfig;
    }
    case appEnv === "local": {
      console.log({ LocalConfig });
      return LocalConfig;
    }
    default: {
      return ProductionConfig;
    }
  }
};

export const AppConfig = setupConfig();
