import { PayloadAction } from "@reduxjs/toolkit";
import { IBucketDelete } from "actions/interfaces";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { getBucketsList } from "../Actions/bucketsActions";

export function* deleteBucketProcess({ payload }: PayloadAction<IBucketDelete>) {
  const { nameBucket, afterLoad, notFetchListAfter } = payload;
  try {
    const pagination = { Page: 1, PerPage: 100 };
    yield call([s3Client, s3Client.deleteBucketFunc], { nameBucket });
    if (!notFetchListAfter) {
      yield put(getBucketsList({ pagination, afterLoad: afterLoad }));
    } else {
      afterLoad();
    }
  } catch (err) {
    afterLoad();
    yield handleSagaError(err);
  }
}
