import { TStore } from "Redux/rootReducer";
import { createSelector } from "reselect";

export const selectUIState = (state: TStore) => state.ui;
export const selectIsMob = createSelector([selectUIState], (uiState) => uiState.isMob);
export const selectSuccessModalState = createSelector([selectUIState], (uiState) => uiState.successModal);
export const selectRoutesWithMainLayout = createSelector([selectUIState], (state) => state.routesWithMainLayout);

export const selectAsyncButtonStateDisabled = createSelector(
  [selectUIState],
  (state) => state.asyncButtonStateDisabled
);

export const selectConfirmModal = createSelector([selectUIState], (state) => state.confirmModalState);
export const selectSettingsModal = createSelector([selectUIState], (state) => state.settingsModalState);
export const selectAsyncProcess = createSelector([selectUIState], (state) => state.asyncProcess);
export const selectSuspendedModal = createSelector([selectUIState], (state) => state.suspendedModalState);
export const selectDisabledBucketVersioningModal = createSelector(
  [selectUIState],
  (state) => state.disabledBucketVersioningModalState
);
