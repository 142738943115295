import { PayloadAction } from "@reduxjs/toolkit";
import { ICheckCanDownload } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { downloadFiles } from "helpers/common";
import { FileFolderMultiple } from "models/Bucket";
import { all, call, put } from "redux-saga/effects";
import { setCommonError } from "Redux/ErrorReducer/ErrorReducer";
import { handleSagaError } from "Redux/helpers";

export function* downloadFileMultipleProcess({ payload }: PayloadAction<FileFolderMultiple>) {
  try {
    const { nameBucket, pathFolder, items, onSuccess } = payload;
    const filtered = items.filter((item) => item.type !== "folder" && item.type !== undefined);

    const canDownload: ICheckCanDownload = yield call([s3Client, s3Client.checkCanUpload]);
    if (canDownload?.CanDownload === false) {
      yield put(
        setCommonError({
          message: canDownload?.Message ? canDownload?.Message : "",
          isBottomText: false,
        })
      );
    } else {
      const links = yield all(
        filtered.map((item) => {
          return call([s3Client, s3Client.getDownloadLinkS3], {
            nameBucket,
            nameFile: item?.name,
            pathFolder,
            versionId: item.version,
          });
        })
      );
      yield call(downloadFiles, links);
      onSuccess();
    }
  } catch (err) {
    yield handleSagaError(err);
  }
}
