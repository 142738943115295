import { Bin, Db, GoogleDocs, Keyframe } from "iconoir-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { ChartStorageUsage } from "shared/src/components/Charts/ChartStorageUsage";
import DashboardCard, { IDashboardCard } from "shared/src/components/DashboardCard/DashboardCard";
import Link from "../../components/UI/Link/Link";
import Support from "../../components/UI/Support/Support";
import { isFull } from "../../helpers/common";
import SvgHeadPhones from "../../icons/Headphones";
import { getStatisticsHome } from "../../Redux/buckets/Actions/bucketsActions";
import { selectStatisticsHome } from "../../Redux/buckets/Selectors/selectStatisticsHome";
import style from "./homePage.module.scss";

type HomePageProps = {
  from: Date;
  to: Date;
};

const HomePage: React.FC<HomePageProps> = ({ from, to }) => {
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(-1);
  const statisticsData = useSelector(selectStatisticsHome);
  const setCardClick = (item) => {
    if (item === 0) {
      setTabs(0);
    }
    if (item === 1) {
      setTabs(1);
    }
  };
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const data = useMemo(() => {
    const items = [
      {
        title: t("Buckets"),
        number: statisticsData?.statistics?.Buckets?.Count || 0,
        link: "/dashboard/buckets",
      },
      {
        title: t("Files"),
        number: statisticsData?.statistics?.Objects?.Count || 0,
        link: "/dashboard/buckets",
      },
      {
        title: t("Storage"),
        number: statisticsData?.statistics?.UsedStorage?.UsedStorageReadableQuantity || 0,
        numberPostfix: statisticsData?.statistics?.UsedStorage?.UsedStorageReadableUnit || "",
        link: "#analytics",
        setCardClick,
        tabs: 0,
      },
      {
        title: t("Bandwidth"),
        number: statisticsData?.statistics?.Bandwidth?.BandwidthReadableQuantity || 0,
        numberPostfix: statisticsData?.statistics?.Bandwidth?.BandwidthReadableUnit || "",
        link: "#analytics",
        setCardClick,
        tabs: 1,
      },
    ] as IDashboardCard[];

    return items;
  }, [language, statisticsData]);

  const getDataGraphic = useCallback(
    ({ from, to, callback }: { from?: Date; to?: Date; callback?: (...args: any[]) => void }) =>
      dispatch(getStatisticsHome({ from, to, callback })),
    []
  );

  const icon = (title: string) => {
    switch (title) {
      case "Files":
        return <GoogleDocs color="#41A8ED" height={24} width={24} />;
      case "Buckets":
        return <Bin color="#41A8ED" height={24} width={24} />;
      case "Storage":
        return <Db color="#41A8ED" height={24} width={24} />;
      case "Bandwidth":
        return <Keyframe color="#41A8ED" height={24} width={24} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    getDataGraphic({ to, from });
  }, []);

  return (
    <div className={style.homePageContainer}>
      <Helmet>
        <title>Petabox: Dashboard</title>
        <meta name="description" content="Petabox: Dashboard" />
      </Helmet>
      <>
        <div className={style.cardsRow}>
          {data.map((item) => (
            <div key={item.title} className={style.wrapCard}>
              {isFull(statisticsData) ? (
                <DashboardCard {...item} icon={icon(item.title)} />
              ) : (
                <Skeleton className={style.dashboardCardSkeleton} />
              )}
            </div>
          ))}
        </div>
        {isFull(statisticsData) ? (
          <Support noClose={false} icon={<SvgHeadPhones />}>
            {" "}
            <React.Fragment>
              {" "}
              {t("If you have any questions")},{" "}
              <Link className={style.link} href={"/dashboard/support"}>
                {t("write&nbsp;to&nbsp;support")}
              </Link>
            </React.Fragment>
          </Support>
        ) : (
          <Skeleton height={66} className={style.supportSkeleton} />
        )}
      </>
      {/*)}*/}
      <div className={style.chartWrapper}>
        <div id="analytics" className={style.analyticsAnchor} />
        {isFull(statisticsData?.storageUsageAnalytics?.Records) ||
        isFull(statisticsData?.bandwidthAnalytics?.Records) ? (
          <ChartStorageUsage
            get={getDataGraphic}
            initialFromTo={{
              fromInput: from,
              toInput: to,
            }}
            tabsParent={tabs}
            dataBandwidth={statisticsData?.bandwidthAnalytics?.Records}
            dataStorage={statisticsData?.storageUsageAnalytics?.Records}
          />
        ) : (
          <Skeleton className={style.chartStorageUsageSkeleton} />
        )}
      </div>
    </div>
  );
};
export default HomePage;
