import { PayloadAction } from "@reduxjs/toolkit";
import { IBucketsListData } from "actions/interfaces";
import { IListAllMyBucketsExtendedResult } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { setBucketsList } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getBuckets({ payload }: PayloadAction<IBucketsListData>) {
  const { afterLoad } = payload;
  try {
    const list: IListAllMyBucketsExtendedResult = yield call([s3Client, s3Client.getListBuckets]);
    yield put(setBucketsList(list?.Buckets));
    afterLoad();
  } catch (err) {
    yield handleSagaError(err);

    afterLoad();
  }
}
