import { PayloadAction } from "@reduxjs/toolkit";
import { ICheckCanDownload, S3File } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { downloadFileByLink } from "helpers/common";
import { call, put, select } from "redux-saga/effects";
import { setCommonError } from "Redux/ErrorReducer/ErrorReducer";
import { handleSagaError } from "Redux/helpers";
import { selectUserStatus } from "Redux/user/Selectors/selectUserStatus";
import { UserStatusEnum } from "shared/src/models/UserModel";

export function* downloadFileProcess({ payload }: PayloadAction<S3File>) {
  try {
    const { nameBucket, pathFolder, nameFile, versionId } = payload;
    const userStatus = yield select(selectUserStatus);
    if (userStatus === UserStatusEnum.suspended) {
      yield put(
        setCommonError({
          message: "You do not have sufficient rights to perform this operation. Contact to support service.",
          isBottomText: true,
        })
      );
      return;
    }
    const canDownload: ICheckCanDownload = yield call([s3Client, s3Client.checkCanDownload]);
    if (canDownload?.CanDownload === false) {
      yield put(
        setCommonError({
          message: canDownload?.Message ? canDownload?.Message : "",
          isBottomText: false,
        })
      );
    } else {
      const link = yield call([s3Client, s3Client.getDownloadLinkS3], { nameBucket, nameFile, pathFolder, versionId });
      yield call(downloadFileByLink, link?.link, nameFile);
    }
  } catch (err) {
    yield handleSagaError(err);
  }
}
