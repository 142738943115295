import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AppConfig } from "config";
import { useEventBus } from "helpers/hooks/useEventBus";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./StripeForm.module.scss";
import { stripeOptions } from "./stripeOptions";

const handleMetric = async () => {
  try {
    // @ts-ignore
    await window?.dataLayer?.push({
      event: "Click - verify_card",
    });
  } catch (e) {
    console.error(e);
  }
};

type StripeFormProps = {
  submitEnventName: string;
  getPaymentSecret: () => Promise<{ client_secret: string }>;
  onError?: (message: string) => void;
  onFormReady: () => void;
  formReady: boolean;
  onSuccess?: () => void;
  setSuspendForm?: (state: boolean) => void;
  returnTo?: string;
};

export const StripeForm: React.FC<StripeFormProps> = (props) => {
  const { onError } = props;
  const { getPaymentSecret, formReady, onSuccess, ...rest } = props;
  const [stripeSecret, setStripeSecret] = useState<string | undefined>();
  const pending = useRef(false);

  useEffect(() => {
    const getSecret = async () => {
      if (pending.current) return;
      pending.current = true;
      try {
        const { client_secret } = await getPaymentSecret();
        if (!client_secret) {
          return onError?.("Stripe secret not provided");
        }
        setStripeSecret(client_secret);
      } catch {
        onError?.("Stripe secret not provided");
      } finally {
        pending.current = false;
      }
    };
    getSecret();
  }, []);

  return (
    <div className={styles.wrapper}>
      {stripeSecret && (
        <Elements
          stripe={loadStripe(AppConfig.stripePublic)}
          options={{
            clientSecret: stripeSecret,
            ...stripeOptions,
          }}
        >
          <PaymentForm {...rest} />
        </Elements>
      )}
    </div>
  );
};

type PaymentFormProps = Omit<StripeFormProps, "getPaymentSecret" | "formReady" | "onSuccess">;
const DEFAULT_ERROR = "unexpected error";

const PaymentForm: React.FC<PaymentFormProps> = ({
  onFormReady,
  setSuspendForm,
  onError,
  submitEnventName,
  returnTo,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const eventBus = useEventBus();
  let isMount = true;
  const returnUrl = `${window.location.origin}/dashboard/suspend-payment-result?returnTo=${returnTo}`;

  const submit = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setSuspendForm?.(true);
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });
      if (result.error) {
        isMount && onError?.(result.error.message || DEFAULT_ERROR);
      }
    } catch (e) {
      isMount && onError?.(e);
    } finally {
      handleMetric();
      isMount && setSuspendForm?.(false);
    }
  }, [stripe, elements]);

  useEffect(() => {
    const submitEvent = eventBus.subscribe(submitEnventName, submit);

    return () => {
      submitEvent.unsubscribe();
      isMount = false;
    };
  }, [submit]);

  return <PaymentElement onReady={onFormReady} />;
};
