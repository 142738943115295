import clsx from "clsx";
import { StyledLink } from "components/UI/StyledLink/StyledLink";
import { ApiClient } from "helpers/ApiClient";
import { useQueryParams } from "helpers/useQuery";
import imageSrc from "images/onboarding/email-verify.png";
import imageSrc2x from "images/onboarding/email-verify@2x.png";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { selectUserProfile } from "Redux/user/Selectors/selectUserState";
import { Heading } from "shared/src/components/Heading/Heading";
import { parseError } from "shared/src/helpers/errorHandler";
import { PetaboxLogo } from "shared/src/images/PetaboxLogo";
import styles from "./ConfirmEmail.module.scss";
import "./styles.scss";

let mounted = true;
export const VerifyEmailPage: React.FC = () => {
  const { email, emailVerified } = useSelector(selectUserProfile);
  const [verifyId, setVerifyId] = useState<string | null>(null);
  const [error, setError] = useState("");
  const { error: queryError } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const onResend = async () => {
    setError("");
    if (!verifyId) return;
    ApiClient.resendEmail(verifyId);
    if (queryError) {
      navigate(location.pathname);
    }
  };

  useEffect(() => {
    setError("");
    const run = async () => {
      try {
        const verifyIdRep = await ApiClient.getEmailVerificationId();
        mounted && setVerifyId(verifyIdRep);
      } catch (e) {
        const { message } = parseError(e);
        mounted && setError(message);
      }
    };

    run();

    return () => {
      mounted = false;
    };
  }, []);

  if (emailVerified) return <Navigate to="/dashboard" />;

  return (
    <div className="wrapper">
      <header className="header">
        <Helmet>
          <title>Petabox: Confirm Email</title>
          <meta name="description" content={`Petabox: Confirm Email`} />
        </Helmet>
        <div className="header__left">
          <div className="header__logo">
            <PetaboxLogo fill="#41a8ed" />
          </div>
        </div>
      </header>

      <main className="main">
        <div className="main__left">
          <img
            className={styles.mainImage}
            src={imageSrc}
            srcSet={`${imageSrc} 1x, ${imageSrc2x} 2x`}
            alt={t("Confirm Email")}
          />
        </div>

        <div className="main__right">
          <div className="checkout">
            {/* <OnboardProgress currentStep={currentStep} /> */}
            <img
              className={styles.imageSm}
              src={imageSrc}
              srcSet={`${imageSrc} 1x, ${imageSrc2x} 2x`}
              alt="Confirm Email"
            />
            <div className={styles.textWrapper}>
              <Heading className={styles.heading} variant="h1">
                {t("You are just a few steps away")}
              </Heading>
              <p className={clsx(styles.textBold)}>Please confirm your e-mail address</p>
              <p className={styles.text}>
                {t("We’ve sent an email to")} <b className={styles.black}>{email}</b>{" "}
                {t("with a link to complete your account setup. We promise it won’t take more than a minute")}.
              </p>
              {queryError && <p style={{ color: "tomato" }}>{queryError}</p>}
              <p className={styles.text}>
                <StyledLink size="lg" onClick={onResend}>
                  {t("Click to resend")}
                </StyledLink>{" "}
                {t("the email if you can’t find it in your inbox or spam folder")}.
              </p>
              <p className={styles.text}>
                {t("If you need assistance, please")}{" "}
                <StyledLink size="lg" type="native" to="mailto:support@petabox.io">
                  {t("Contact us")}.
                </StyledLink>
              </p>
              {error && <small style={{ color: "tomato" }}>{error}</small>}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
