import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpinnerCute } from "shared/src/components/Spinner/Spinner";
import { selectAsyncProcess } from "../../../Redux/ui/selectors";
import style from "./button.module.scss";

interface IProps {
  type?: "submit" | "button";
  color?:
    | "primary"
    | "secondary"
    | "additional"
    | "gray"
    | "lightgray"
    | "disabled"
    | "accent"
    | "text"
    | "transparent"
    | "outlined"
    | "none";
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  size?: string;
  className?: string;
  loading?: boolean;
  icon?: JSX.Element;
  dataTest?: string;
}
const Button: React.FC<IProps> = ({
  children,
  type = "button",
  color = "primary",
  disabled,
  onClick,
  size,
  className,
  loading,
  icon,
  dataTest,
}) => {
  const [disableState, setDisableState] = useState(disabled);

  const asyncProcess = useSelector(selectAsyncProcess);

  useEffect(() => {
    setDisableState(asyncProcess);
  }, [asyncProcess]);

  return (
    <button
      className={clsx(
        style.button,
        size && style[`button${size}`],
        color ? style[`button${color}`] : style.buttonPrimary,
        disabled && style[`buttondisabled${color}`],
        className
      )}
      type={type}
      disabled={disableState}
      onClick={onClick}
      data-test={dataTest}
    >
      {children}
      {(loading || icon) && <div className={style.icon}>{loading ? <SpinnerCute /> : icon}</div>}
    </button>
  );
};
export default Button;
