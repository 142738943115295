import { put } from "redux-saga/effects";
import { setActionButtonState, setConfirmModalState } from "Redux/ui/uiState";
import { handleSagaError } from "Redux/helpers";
import { getSettingsInfo } from "../Actions/userActions";

export function* getEmailSettingsProcess() {
  try {
    yield put(getSettingsInfo());
    yield put(setActionButtonState(false));
    yield put(setConfirmModalState(true));
  } catch (err) {
    yield put(setActionButtonState(false));
    yield handleSagaError(err, "Settings");
  }
}
