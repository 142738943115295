import * as React from "react";

function SvgSuccess() {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 25L20.5 31L34.5 17" stroke="#47C281" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24.5 44C35.5456 44 44.5 35.0456 44.5 24C44.5 12.9543 35.5456 4 24.5 4C13.4543 4 4.5 12.9543 4.5 24C4.5 35.0456 13.4543 44 24.5 44Z" stroke="#47C281" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
}
export default SvgSuccess;
