import { RedirectToLocation } from "components/RedirectToLocation";
import { AppConfig } from "config";
import { Cookie } from "helpers/cookie";
import { getSearchParams } from "helpers/searchParams";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import { checkIsAuthAction } from "Redux/user/Actions/userActions";
import { selectIsAuthorized } from "Redux/user/Selectors/selectIsAuthoraized";
import { Spinner } from "shared/src/components/Spinner/Spinner";
import { setToken } from "shared/src/helpers/token";
import { TechnicalPage } from "./TechnicalPage/TechnicalPage";

export const Auth: React.FC = ({ children }) => {
  const IN_TECH = AppConfig.technicalProcess;
  const { pathname } = useLocation();
  const DISABLE_TECH = !!Cookie.getCookie("dont_show_popup");
  const SHOW_TECH = !DISABLE_TECH && IN_TECH;
  const { checked, result } = useSelector(selectIsAuthorized);
  const unAuhrorized = checked && !result;
  const { token } = getSearchParams(window.location.href);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setToken(token);
      navigate("/dashboard", { replace: true });
    }
    dispatch(checkIsAuthAction());
  }, []);

  if (unAuhrorized && pathname !== "/dashboard/login") {
    return <RedirectToLocation to={"/dashboard/login"} />;
  }
  if (checked && !unAuhrorized && pathname === "/dashboard/login") {
    return <RedirectToLocation to={"/dashboard"} />;
  }
  if (!SHOW_TECH && !checked)
    return (
      <div className="page-loading">
        <Spinner />
      </div>
    );
  if (SHOW_TECH) {
    return (
      <BrowserRouter>
        <Routes>
          <TechnicalPage />
        </Routes>
      </BrowserRouter>
    );
  }
  return <>{children}</>;
};
