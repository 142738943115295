import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { call, put, select } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { DeleteResourceFromSharedWithYouListParams, ResourcesSharedWithMe } from "../../../models/ShareList";
import { getListResourcesSharedWithMe } from "../Actions/bucketsActions";
import { selectResourcesSharedWithMe } from "../Selectors/selectResourcesSharedWithMe";

export function* deleteResourceFromSharedWithYouListProcess({
  payload,
}: PayloadAction<DeleteResourceFromSharedWithYouListParams>) {
  try {
    yield call([s3Client, s3Client.deleteResourceFromSharedWithYouList], payload);
    const resourcesSharedWithMe: ResourcesSharedWithMe = yield select(selectResourcesSharedWithMe);
    yield put(
      getListResourcesSharedWithMe({
        page: resourcesSharedWithMe.ResourcesSharedWithMe.Page,
        perPage: resourcesSharedWithMe.ResourcesSharedWithMe.PerPage,
      })
    );
  } catch (err) {
    yield handleSagaError(err);
  }
}
