import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { PrivacyBucket } from "models/PrivacyBucket";
import { call, put, select } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { selectIsCanShare } from "Redux/user/Selectors/selectIsCanShare";
import { getBucketsList } from "../Actions/bucketsActions";

export function* privacyBucketProcess({ payload }: PayloadAction<PrivacyBucket>) {
  const { nameBucket, typePrivacy } = payload;
  try {
    const isCanShare = yield select(selectIsCanShare);
    
    if (!isCanShare) {
      throw Error(
        "We disabled this function for security reasons. However, if it is essential to you, please get in touch with support."
      );
    }
    
    yield call([s3Client, s3Client.privacyBucketFunc], { nameBucket, typePrivacy });

    const pagination = { Page: 1, PerPage: 100 };

    yield put(getBucketsList({ pagination, afterLoad: () => {} }));
  } catch (err) {
    yield handleSagaError(err);
  }
}
