import { StripeForm } from "components/StripeForm/StripeForm";
import { AsyncSagaButton, AsyncSagaButtonMethods } from "components/UI/AsyncSagaButton/AsyncSagaButton";
import { CoreModal, PbModalHeader } from "components/UI/Modal/Modal";
import { StyledLink } from "components/UI/StyledLink/StyledLink";
import { format } from "date-fns";
import { ApiClient } from "helpers/ApiClient";
import { useEventBus } from "helpers/hooks/useEventBus";
import { useQuery } from "helpers/useQuery";
import { isNil } from "helpers/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { setTariffUserPlan } from "Redux/account/reducer";
import { selectStripeCards } from "Redux/account/Selectors/selectStripeCards";
import { successSelectModalOpen } from "Redux/BillingInfoUi/billingInfoUi";
import { selectTariffCatalog } from "Redux/catalogs/selectors/selectTariffCatalog";
import { checkIsAuthAction, getBillingHistory } from "Redux/user/Actions/userActions";
import { updateUserStatusAction } from "Redux/user/reducer";
import { PbButton } from "shared/src/components/PbButton/PbButton";
import { parseError } from "shared/src/helpers/errorHandler";
import { centsToUsd } from "shared/src/helpers/utils";
import { StripeCard } from "shared/src/models/StripeCard";
import { UserStatusEnum } from "shared/src/models/UserModel";
import styles from "./SelectPlan.module.scss";

type NextTariffCardProps = {
  title: string;
  charge: number;
  date: Date;
};
export const NextTariffCard: React.FC<NextTariffCardProps> = ({ title, charge, date }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.cardLest}>
        <h3 className={styles.cardHeading}>{title}</h3>
        <p className={styles.cardText}>
          {t("One-time charge")} {format(date, "LLL dd,yyyy")}
        </p>
      </div>
      <div className={styles.cardCharge}>{centsToUsd(charge)}</div>
    </div>
  );
};

const SavedCardCase: React.FC<{ card: StripeCard }> = ({ card: { brand, last4, expMonth, expYear } }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className={styles.bankCardTitle}>
        {t("Saved")} {t("payment method")}
      </h3>
      <div className={styles.bankCard}>
        <div className={styles.bankCardInfo}>
          <img
            src={require(`shared/src/images/verify-card/${brand}.svg`).default}
            alt={brand}
            className={styles.bankCardImg}
          />
          <span> • {last4}</span>
        </div>
        <div className={styles.bankCardExpire}>
          {t("Expires")} {expMonth}/{expYear.slice(2)}
        </div>
      </div>
    </>
  );
};

export const SelectPlan = () => {
  const { tariffId } = useParams<{ tariffId?: string }>();
  const query = useQuery();
  const payment_intent = query.get("payment_intent");
  const tariffs = useSelector(selectTariffCatalog);
  const nextTariff = tariffs.find((tariff) => tariff.id === Number(tariffId));
  const stripeCards = useSelector(selectStripeCards);
  const defaultCard = stripeCards.find((x) => x.default);
  const [error, setError] = useState("");
  const [readyForm, setReadyForm] = useState(!!defaultCard);
  const [suspend, setSuspendForm] = useState(false);
  const returnUrl = "/dashboard/billing";
  const submitEnventName = "STRIPE_SUB_FIRST_TIME";
  const asyncBtnRef = useRef<AsyncSagaButtonMethods>(null);

  const handleSetError = (err: string) => {
    setError(err);
    setSuspendForm(false);
    asyncBtnRef.current?.clearState();
  };

  const resetError = () => handleSetError("");

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClose = () => navigate("/dashboard/billing");
  const eventBus = useEventBus();
  const onFormReady = () => setReadyForm(true);
  const getPaymentSecret = () => ApiClient.checkoutTariff(Number(tariffId));
  const onSuccessSw = () => {
    if (isNil(nextTariff)) return;
    onClose();
    dispatch(getBillingHistory({ page: 1, perPage: 5 }));
    dispatch(
      successSelectModalOpen({
        isOpened: true,
        title: "Success",
        body: [`Plan "${nextTariff.name} successfully activated"`],
      })
    );
  };
  const onSuccess = async () => {
    if (isNil(nextTariff)) return;
    dispatch(checkIsAuthAction(onSuccessSw));
  };
  const onSubmit = async () => {
    try {
      if (isNil(nextTariff)) return;
      if (isNil(defaultCard)) {
        return eventBus.publish({ topic: submitEnventName, payload: null });
      }
      const { userPlan, transactionId } = await ApiClient.changeUserPlan(nextTariff.id);
      dispatch(setTariffUserPlan(userPlan));
      dispatch(updateUserStatusAction(UserStatusEnum.active));
      window.location.replace(
        `/dashboard/suspend-payment-result?payment_intent=${transactionId}&returnTo=/dashboard/billing`
      );
    } catch (e) {
      const err = parseError(e);
      handleSetError(err.message);
    }
  };

  useEffect(() => {
    return () => {
      resetError();
    };
  }, []);

  if (isNil(nextTariff)) return <Navigate to="/dashboard/billing" />;
  return (
    <CoreModal
      titleMargin="0"
      showClose={false}
      visible={true}
      className={styles.modal}
      closeOutClick={false}
      onClose={onClose}
    >
      <div className={styles.modalBody}>
        <PbModalHeader onClose={onClose}>{t("Choose Plan")}</PbModalHeader>
        <div className={styles.reactHidden} />
        <NextTariffCard title={nextTariff?.name || ""} charge={nextTariff?.price || 1} date={new Date()} />
        <div className={styles.reactHidden} />

        {defaultCard && !payment_intent && <SavedCardCase card={defaultCard} />}
        {(payment_intent || !defaultCard) && (
          <StripeForm
            onFormReady={onFormReady}
            formReady={readyForm}
            onSuccess={onSuccess}
            submitEnventName={submitEnventName}
            getPaymentSecret={getPaymentSecret}
            onError={handleSetError}
            setSuspendForm={setSuspendForm}
            returnTo={returnUrl}
          />
        )}
        {error && <span className={styles.error}>{error}</span>}
        <p className={styles.modalBodyText}>
          <span
            dangerouslySetInnerHTML={{
              __html: t("By clicking &#171; Complete Purchase&#187;, you hereby agree to Petabox"),
            }}
          />{" "}
          <StyledLink to="https://petabox.dev/terms-of-service" type="native" blankLink>
            {t("Terms of use")}
          </StyledLink>{" "}
          {t("and")}{" "}
          <StyledLink to="https://petabox.dev/privacy-policy" type="native" blankLink>
            {t("Privacy Policy")}
          </StyledLink>
          .{" "}
          {t(
            "Your payment method will be saved for future purchases and, if applicate, recurring subscription payments."
          )}
        </p>

        <div className={styles.reactHidden} />
        <div className={styles.modalBodyFooter}>
          <PbButton onClick={onClose} color="secondary" className={styles.modalBodyBtn}>
            {t("Cancel")}
          </PbButton>
          <AsyncSagaButton
            ref={asyncBtnRef}
            endActionType={updateUserStatusAction.type}
            disabled={!readyForm || suspend}
            onClick={onSubmit}
            className={styles.modalBodyBtn}
            dataTest="pay"
          >
            {t("Pay")} {centsToUsd(nextTariff.price)}
          </AsyncSagaButton>
        </div>
      </div>
    </CoreModal>
  );
};

export const SelectPlanModal = () => {
  return (
    <Routes>
      <Route path="/select-plan/:tariffId" element={<SelectPlan />} />
    </Routes>
  );
};
