import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { setConfirmModalState } from "Redux/ui/uiState";
import { fetchApi, urlAuthNode } from "../../../helpers/common";
import { ChangePasswordPayload } from "../Actions/userActions";
import { setUserDataSettings } from "../reducer";

export function* changePasswordProcess({ payload }: PayloadAction<ChangePasswordPayload>) {
  const { oldPassword, newPassword } = payload;

  try {
    yield call(fetchApi, "put", "200", {
      url: "/password",
      body: { oldPassword, newPassword },
      mainUrl: urlAuthNode,
    });
    yield put(setConfirmModalState(true));
    yield put(setUserDataSettings({ hasStoredPassword: true }));
  } catch (err) {
    yield handleSagaError(err);
  }
}
