import { s3Client } from "api/s3.client";
import { downloadFileByLink } from "helpers/common";
import { call, put, select } from "redux-saga/effects";
import { closeConfirmModalAction, DownloadFilesState } from "../downloadSlice";
import { selectDowloadFileState } from "../selectors";

export function* watchUploadZip() {
  const { bucketName, zipId }: DownloadFilesState = yield select(selectDowloadFileState);
  const url = yield call([s3Client, s3Client.getSignedUrlForDownloadZip], bucketName, zipId);
  yield call(downloadFileByLink, url, `zip-${Date.now()}`);
  yield put(closeConfirmModalAction());
}
