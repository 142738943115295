import { PayloadAction } from "@reduxjs/toolkit";
import { ApiClient } from "helpers/ApiClient";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { BillingHistoryTO } from "shared/src/models/BillingHistory";
import { GetBillingHistoryPayload } from "../Actions/userActions";
import { setBillingHistory } from "../reducer";

export function* getBillingHistoryProcess({ payload }: PayloadAction<GetBillingHistoryPayload>) {
  try {
    const billingHistory: BillingHistoryTO = yield call(ApiClient.getBillingHistory, payload);
    yield put(setBillingHistory(billingHistory));
  } catch (err) {
    console.error(err);
    yield handleSagaError(err);
  }
}
