import { call, put } from "redux-saga/effects";
import { setBillingUsage } from "Redux/account/reducer";
import { handleSagaError } from "Redux/helpers";
import { ApiClient } from "../../../helpers/ApiClient";
import { UsageTO } from "../../../models/UserUsege";

export function* getBillingUsageProcess() {
  try {
    const storageUsage: UsageTO = yield call(ApiClient.getStorageUsage);
    const bandwidthUsage: UsageTO = yield call(ApiClient.getBandwidthUsage);

    yield put(
      setBillingUsage({
        storageUsage: storageUsage,
        // bandwidthUsage: {
        //   size: "0",
        //   sizePretty: "0B",
        // },
        bandwidthUsage,
      })
    );
  } catch (err) {
    console.error("catch getBillingUsageProcess", err);
    yield handleSagaError(err, "Get billing usage");
  }
}
