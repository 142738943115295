import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { PutObjectLegalHold } from "models/ObjectLock";
import { call, put } from "redux-saga/effects";
import { getObjectLegalHold } from "Redux/buckets/Actions/bucketsActions";
import { handleSagaError } from "Redux/helpers";

export function* putObjectLegalHoldProcess({ payload }: PayloadAction<PutObjectLegalHold>) {
  try {
    yield call([s3Client, s3Client.putObjectLegalHold], payload);
    yield put(
      getObjectLegalHold({
        nameBucket: payload.nameBucket,
        versionId: payload.versionId,
        nameFile: payload.nameFile,
        pathFolder: payload.pathFolder,
      })
    );
  } catch (err) {
    yield handleSagaError(err);
  }
}
