import clsx from "clsx";
import React from "react";
import { SvgCheckboxChecked, SvgCheckboxCheckedGray, SvgCheckboxUnChecked } from "../icons/CheckboxIcons";
import style from "./checkbox.module.scss";

interface IProps {
  checked: boolean;
  name: string;
  label?: string;
  id: string | number;
  onChange?: (event: React.ChangeEvent) => void;
  isGray?: boolean;
  disabled?: boolean;
  size?: number;
}
const Checkbox: React.FC<IProps> = ({ checked, name, size = 14, id, onChange, label, isGray, disabled }: IProps) => {
  return (
    <label className={clsx(style.container, disabled && style.disabled)}>
      <input
        className={style.input}
        type="checkbox"
        id={id?.toString()}
        name={name}
        checked={checked}
        disabled={disabled}
        readOnly={disabled}
        onChange={onChange}
      />
      {checked ? (
        disabled ? (
          <SvgCheckboxChecked color="#A6B4CE" size={size} />
        ) : isGray ? (
          <SvgCheckboxCheckedGray size={size} />
        ) : (
          <SvgCheckboxChecked size={size} />
        )
      ) : (
        <SvgCheckboxUnChecked size={size} />
      )}
      {label ? <div className={style.label}> {label} </div> : ""}
    </label>
  );
};
export default Checkbox;
