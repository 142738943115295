import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { DeleteFileFolder } from "models/Bucket";
import { call, put, select } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { ResourcesSharedWithMe } from "../../../models/ShareList";
import { deleteFileFolderMultiple, getFilesList } from "../Actions/bucketsActions";

export function* deleteFileFolderProcess({ payload }: PayloadAction<DeleteFileFolder>) {
  const { nameBucket, nameFile, pathFolder, type, onSuccess, getFilesListAfter, versionId, bypassGovernanceRetention } =
    payload;
  try {
    if (type === "file") {
      yield call([s3Client, s3Client.deleteFileFunc], {
        nameBucket,
        pathFolder,
        nameFile,
        versionId,
        bypassGovernanceRetention,
      });
    } else {
      let path = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}${nameFile}`;
      path = path.replaceAll("//", "/");

      let items;
      if (getFilesListAfter !== false) {
        const data = yield call([s3Client, s3Client.getListFiles], {
          nameBucket,
          pathFolder: path,
          Page: 1,
          PerPage: 1000000000000000000,
          allTree: true,
        });
        items =
          data?.ListExtendedObjects?.Contents &&
          data?.ListExtendedObjects?.Contents?.map((el) => {
            return {
              name: el.Key,
              type: el.FileType === "folder" ? "folder" : "file",
            };
          });
      } else {
        //todo shared list fetch
        const list: ResourcesSharedWithMe = yield call([s3Client, s3Client.getSharedWithMe], {
          page: 1,
          perPage: 1000000000000000000,
          bucket: nameBucket,
          prefix: path,
          // delimiter: string,
          excludeRootFolder: true,
        });
        items = list?.ResourcesSharedWithMe.Resources.map((res) => {
          if (res.Type === "Key") {
            return {
              name: res.Key.Key,
              type: "file",
            };
          }
          if (res.Type === "Prefix") {
            return {
              name: res.Prefix.Prefix,
              type: "folder",
            };
          }
        });
      }

      if (items?.length > 0) {
        yield put(
          deleteFileFolderMultiple({
            nameBucket,
            pathFolder: "",
            items: items,
            onSuccess: () => {},
            isSharedList: getFilesListAfter === false,
          })
        );
      }
      yield call([s3Client, s3Client.deleteFileFunc], { nameBucket, pathFolder, nameFile: `${nameFile}/` });
    }
    onSuccess && onSuccess();
    const pagination = yield select((store) => store.buckets?.currentBucket?.pagination);
    if (getFilesListAfter !== false) {
      yield put(
        getFilesList({
          nameBucket,
          pathFolder,
          afterLoad: () => {},
          pagination: { Page: pagination?.page || 1, PerPage: pagination?.perPage || 10 },
        })
      );
    }
  } catch (err) {
    yield handleSagaError(err);
  }
}
