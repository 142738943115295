import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { S3 } from "aws-sdk";
import { call, put } from "redux-saga/effects";
import { setObjectLockConfiguration } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getObjectLockConfigProccess({ payload }: PayloadAction<string>) {
  try {
    const objectLockConfiguration: S3.GetObjectLockConfigurationOutput = yield call(
      [s3Client, s3Client.getObjectLockConfiguration],
      payload
    );
    if (objectLockConfiguration.ObjectLockConfiguration) {
      yield put(
        setObjectLockConfiguration({
          ...objectLockConfiguration.ObjectLockConfiguration,
          backetName: payload,
        })
      );
    }
  } catch (err) {
    // it's mean this bucket never set ObjectLockConfiguration;
    if (err.code === "ObjectLockConfigurationNotFoundError") {
      yield put(setObjectLockConfiguration(undefined));

      return;
    }
    yield handleSagaError(err);
  }
}
