import { AccessKey } from "models/AccessKey";
import { GetBandwidthAnalyticsRep, GetStorageUsageAnalyticsRep } from "models/Analytics";
import { NotificationRep } from "models/Notification";
import { Pagination } from "models/Pagination";
import { PeymentResultRep } from "models/PaymentResult";
import { GetStatisticsRep } from "models/Statistics";
import { httpFormatDate, withParams } from "shared/src/http";
import { BillingHistoryPagination, BillingHistoryTO } from "shared/src/models/BillingHistory";
import { StripeCard } from "shared/src/models/StripeCard";
import { GetTariffUserPlanAndTransactionIdTO, GetTariffUserPlanTO } from "shared/src/models/TariffUserPlan";
import { UserProfile, UserStatusEnum } from "shared/src/models/UserModel";
import { CheckProofPayload } from "../models/CheckProofTonWallet";
import { UsageTO } from "../models/UserUsege";
import { authClient, dashClient } from "./HttpClient";

type CheckoutResponse = { client_secret: string };

export class ApiClient {
  static getAccessKeys = () => authClient.apiGet<AccessKey[]>({ url: "/access-keys" });

  static getDashboardAccessKeys = () => authClient.apiGet<AccessKey[]>({ url: "/access-key-for-dashboard" });

  static getStripeCards = () => dashClient.apiGet<StripeCard[]>({ url: "/billing-api/cards" });

  static setDefaultStripeCard = (id: string) =>
    dashClient.apiPut<StripeCard[]>({ url: `/billing-api/cards/${id}/make-default` });

  static deleteDefaultStripeCard = (id: string) =>
    dashClient.apiDelete<StripeCard[]>({ url: `/billing-api/cards/${id}` });

  static getNotifications = (query: Pagination) => {
    const url = withParams("/notifications", query);
    return dashClient.apiGet<NotificationRep>({ url });
  };

  static getStatisticsHome = async () => dashClient.apiGet<GetStatisticsRep>({ url: "/statistics" });

  static getTariffUserPlan = async () => dashClient.apiGet<GetTariffUserPlanTO>({ url: "/tariff/user-plan" });

  static getStorageUsage = async () => dashClient.apiGet<UsageTO>({ url: "/billing/storage-usage" });

  static getBandwidthUsage = async () => dashClient.apiGet<UsageTO>({ url: "/billing/bandwidth-usage" });

  static getStorageUsageAnalytics = async (from?: Date, to?: Date) => {
    const url = withParams("/storage-analytics", { fromDate: httpFormatDate(from), toDate: httpFormatDate(to) });
    return dashClient.apiGet<GetStorageUsageAnalyticsRep>({ url });
  };

  static getBandwidthAnalytics = async (from?: Date, to?: Date) => {
    const url = withParams("/bandwidth-analytics", { fromDate: httpFormatDate(from), toDate: httpFormatDate(to) });
    return dashClient.apiGet<GetBandwidthAnalyticsRep>({ url });
  };

  static getUserProfile = async () => authClient.apiGet<UserProfile>({ url: "/me" });

  static getStripeSecret = async (cardValidation = false, tariffId?: number): Promise<{ client_secret: string }> => {
    const url = withParams("/stripe-api/secret", {
      cardValidation: cardValidation ? "true" : undefined,
      tariffId,
    });
    return dashClient.apiGet({ url });
  };

  static validateStripeCard = async () => {
    return dashClient.apiGet<CheckoutResponse>({ url: "/stripe-api/validate-card" });
  };

  static checkoutTariff = async (tariffId: number) => {
    return dashClient.apiGet<CheckoutResponse>({ url: `/stripe-api/checkout-tariff/${tariffId}` });
  };

  static changeUserPlan = async (tariffId: number) => {
    return dashClient.apiPut<GetTariffUserPlanAndTransactionIdTO>({ url: `/tariff/switch-to/${tariffId}` });
  };

  static cancelSubsriptions = async () =>
    dashClient.apiDelete<{ userStatus: UserStatusEnum }>({ url: "/billing/subscriptions" });

  static cancelSwitchUserPlan = async () => dashClient.apiPost({ url: "/tariff/cancel-next" });

  static undoCancellation = async () => dashClient.apiPost({ url: "/billing/subscription/renovate" });

  static getBillingHistory = (query: BillingHistoryPagination) => {
    const url = withParams("/billing/history", query);
    return dashClient.apiGet<BillingHistoryTO>({ url });
  };

  static updateUserData = (email?: string, name?: string, companyName?: string, website?: string) => {
    return authClient.apiPut<UserProfile>({ url: "/me", data: { email, name, companyName, website } });
  };

  static updateUserNameCompanyWebsite = (name: string, companyName: string, website: string) => {
    return authClient.apiPut<UserProfile>({ url: "/me", data: { name, companyName, website } });
  };

  static getPaymentResult = (paymentId: string) => {
    return dashClient.apiGet<PeymentResultRep>({ url: `/billing/payment-status/${paymentId}` });
  };

  static getEmailVerificationId = (): Promise<string> => {
    return authClient.apiGet<string>({ url: `/auth/email-verification-id` });
  };

  static resendEmail = (verificationId: string) => {
    return authClient.apiPost({ url: `/auth/send-email-verification-again?verificationId=${verificationId}` });
  };

  static getTonConnectPayload = () => authClient.apiPost<{ payload: string }>({ url: "/ton-connect/generate-payload" });

  static checkProofWallet = (proof: CheckProofPayload) =>
    authClient.apiPost<{ accessToken: string }>({ url: "/ton-connect/check-proof", data: proof });
}
