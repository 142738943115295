import React from "react";
import { AnyObject } from "../common/ts-helpers";

export function SafeRenderTsc<T extends AnyObject>({
  value,
  children,
}: {
  value?: T;
  children: (value: T) => JSX.Element;
}) {
  if (!value) {
    return null;
  }

  return <>{children(value)}</>;
}
