import { call, put } from "redux-saga/effects";
import { fetchApi, urlAuthNode } from "../../../helpers/common";
import { ApiClient } from "helpers/ApiClient";
import { AccessKey } from "models/AccessKey";
import { handleSagaError } from "Redux/helpers";
import { setAccessKeys, setCurrentAccessKey } from "../reducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { ChangeStatusPayload } from "actions/actionTypes";

export function* changeStatusAccessKeyProcess({ payload }: PayloadAction<ChangeStatusPayload>) {
  try {
    yield call(fetchApi, "put", "200", {
      url: `/access-keys/${payload?.key?.id}/status`,
      body: { active: payload.status },
      mainUrl: urlAuthNode,
    });
    const accessKeys: AccessKey[] = yield call(ApiClient.getAccessKeys);
    yield put(setAccessKeys(accessKeys));
  } catch (err) {
    yield handleSagaError(err, "Access keys");
    yield put(setCurrentAccessKey({} as any));
  }
}
