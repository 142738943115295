import { PayloadAction } from "@reduxjs/toolkit";
import { IObjectExtendedInfo, S3File } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { ListVersionsOfSpecificObjectResponse } from "models/FileVersion";
import { call, put, select } from "redux-saga/effects";
import { setFileInfo } from "Redux/buckets/reducer";
import { selectBucketVersioningStatus } from "Redux/buckets/Selectors/selectBucketVersioningStatus";
import { handleSagaError } from "Redux/helpers";
import history from "../../../helpers/history";

const redirectFunc = (props) => {
  const { to, back } = props;
  if (back) {
    history.back();
  } else {
    history.push(to);
  }
};

export function* getFileInfoProcess({ payload }: PayloadAction<S3File & { afterLoad: () => void }>) {
  const { nameFile, nameBucket, pathFolder, afterLoad, versionId } = payload;
  try {
    const data: IObjectExtendedInfo = yield call([s3Client, s3Client.getFileInfoFunc], {
      nameFile,
      nameBucket,
      pathFolder,
      versionId,
    });
    const versioningEnabled = yield select(selectBucketVersioningStatus);
    let fileVersions: ListVersionsOfSpecificObjectResponse | undefined;
    if (versioningEnabled) {
      fileVersions = yield call([s3Client, s3Client.getFileVersions], {
        nameFile,
        nameBucket,
        pathFolder,
      });
    }
    yield put(setFileInfo({ nameBucket, pathFolder, data, fileVersions }));
    afterLoad();
  } catch (err) {
    redirectFunc({ back: true });
    yield put(setFileInfo({ nameBucket, pathFolder, data: {} as IObjectExtendedInfo, fileVersions: undefined }));
    yield handleSagaError(err, "Get file info");
    afterLoad();
  }
}
