import clsx from "clsx";
import { ArrowDown, Cancel } from "iconoir-react";
import React, { ReactElement, useEffect } from "react";
import { Heading } from "shared/src/components/Heading/Heading";
import { ModalPortal } from "shared/src/components/ModalPortal";
import style from "./modal.module.scss";

interface ModalProps {
  visible: boolean;
  title?: string;
  footer?: ReactElement | string;
  footerCenter?: boolean;
  onClose: () => void;
  titleMargin?: string;
  closeOutClick: boolean;
  overflow?: string;
  uploadFiles?: number;
  disabled?: boolean;
  showClose?: boolean;
  titleClassName?: string;
  size?: "lg" | "md" | "sm" | "xs";
  className?: string;
  maxWidth?: number;
}

export const CoreModal: React.FC<ModalProps> = ({
  visible = false,
  title = "",
  children,
  footer = "",
  footerCenter = false,
  onClose,
  titleMargin,
  closeOutClick,
  overflow = "auto",
  uploadFiles = 0,
  disabled = false,
  showClose = true,
  titleClassName,
  size = "sm",
  className,
  maxWidth,
}) => {
  useEffect(() => {
    const onKeydown = ({ key }: KeyboardEvent) => {
      onClose && key === "Escape" && onClose();
    };

    if (visible) {
      document.addEventListener("keydown", onKeydown);
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
    return () => {
      document.removeEventListener("keydown", onKeydown);
      document.body.classList.remove("hidden");
    };
    //eslint-disable-next-line
  }, [visible]);

  if (!visible) return null;
  return (
    <ModalPortal>
      <div
        data-test={`${title.replaceAll(" ", "")}Modal`}
        className={clsx(style.modal, size)}
        onClick={closeOutClick ? onClose : () => {}}
      >
        <div
          data-test={`Modal`}
          style={{ maxWidth: maxWidth ? `${maxWidth}px` : "auto" }}
          className={clsx(style.modalDialog, className)}
          onClick={(e) => e.stopPropagation()}
        >
          {title && (
            <div className={style.modalHeader} style={titleMargin ? { marginBottom: titleMargin } : {}}>
              <h3 className={titleClassName ? titleClassName : style.modalTitle}>{title}</h3>
              {showClose && (
                <span className={style.modalClose} onClick={onClose}>
                  {uploadFiles > 0 && disabled ? (
                    <ArrowDown width={32} height={32} color="#6E7E9D" />
                  ) : (
                    <Cancel width={32} height={32} color="#6E7E9D" />
                  )}
                </span>
              )}
            </div>
          )}

          <div
            className={style.modalWrapper}
            style={overflow === "visible" ? { overflow: "visible" } : { overflow: "auto" }}
          >
            <div className={style.modalBody}>
              <div className={style.modalContent}>{children}</div>
            </div>
            {footer && <div className={clsx(style.modalFooter, footerCenter && style.modalFooterCenter)}>{footer}</div>}
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export const PbModalHeader: React.FC<{ onClose?: () => void }> = ({ children, onClose }) => {
  return (
    <div className={style.modalPbHeader}>
      <Heading variant="h2">{children}</Heading>

      <button onClick={onClose} className={style.modalPbClose}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.0014 7.99995L14.9915 14.9901M1.01123 14.9901L8.0014 7.99995L1.01123 14.9901ZM14.9915 1.00977L8.0014 7.99995L14.9915 1.00977ZM8.0014 7.99995L1.01123 1.00977L8.0014 7.99995Z"
            stroke="#6E7E9D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};
