import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import {
  BucketSharingList,
  KeySharingListRoot,
  PrefixSharingListRoot,
  SharingListType,
} from "../../../models/ShareList";
import { getShareList } from "../Actions/bucketsActions";

export function* putShareListProcess({
  payload,
}: PayloadAction<{
  path: string;
  sharingList: BucketSharingList | PrefixSharingListRoot | KeySharingListRoot;
  type: SharingListType;
}>) {
  try {
    yield call([s3Client, s3Client.putSharingList], payload.path, payload.sharingList, payload.type);
    yield put(getShareList({ path: payload.path, type: payload.type }));
  } catch (err) {
    console.error(err);
    yield handleSagaError(err);
  }
}
