import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

export const ModalPortal: React.FC = ({ children }) => {
  const [container] = useState(document.createElement("div"));

  useEffect(() => {
    document.body.append(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};
