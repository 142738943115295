import clsx from "clsx";
import { ArrowLeft } from "iconoir-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { PetaboxLogo } from "shared/src/images/PetaboxLogo";
import SvgCloseIcon from "../../icons/Close";
import { LogoShort } from "../../icons/LogoShort";
import style from "./sidebar.module.scss";
import SidebarItem from "./SidebarItem";
import { ISidebarBlock } from "./types";

interface IProps {
  data: ISidebarBlock[];
  isCollapsedDesktop: boolean;
  isCollapsedMobile: boolean;
  closeMobile: () => void;
  collapseMobileClickItem: () => void;
}

const Sidebar: React.FC<IProps> = ({
  data,
  isCollapsedDesktop,
  isCollapsedMobile,
  closeMobile,
  collapseMobileClickItem,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {/*desktop menu*/}
      <div
        className={`${style.sidebar}  ${isCollapsedDesktop ? style.sidebarCollapsedDesktop : ""} ${
          style.sidebarCollapsedTablet
        } `}
      >
        <a
          href="/"
          className={`${style.logotype} ${isCollapsedDesktop ? style.logotypeCollapsed : style.mobileCommon}`}
        >
          <PetaboxLogo fill="white" short height={48} />
        </a>
        <a href="/" className={`${style.logotype} ${isCollapsedDesktop ? "" : style.logotypeCommon}`}>
          <PetaboxLogo fill="white" />
        </a>
        <div className={style.items}>
          <div className={style.mainItems}>
            {data.map((block) => {
              return (
                <div key={block.title} className={style.itemsBlock}>
                  <div
                    className={`${style.titleBlock} ${isCollapsedDesktop ? style.titleBlockCollapsedDesktop : ""} ${
                      style.titleBlockCollapsedTablet
                    }`}
                  >
                    {block.title}
                  </div>
                  {block.items.map((item) => {
                    return (
                      <div key={item.text} onClick={() => collapseMobileClickItem()}>
                        <SidebarItem {...item} isCollapsed={isCollapsedDesktop} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>

          <div className={style.backMobile}>
            <SidebarItem
              onClick={(e) => {
                e.preventDefault();
                window.location.replace(window.location.origin);
              }}
              icon={<ArrowLeft />}
              link="redirect-to-origin"
              text={t("Back to Site")}
              isCollapsed={isCollapsedDesktop}
            />
          </div>
        </div>
      </div>
      {/*tablet menu*/}
      <div className={`${style.sidebar} ${style.sidebarTablet} ${style.sidebarCollapsedTablet} `}>
        <a href="/" className={clsx(style.logotype, style.logotypeFlex)}>
          <LogoShort />
        </a>
        <div className={style.mobileMenuClose} onClick={closeMobile}>
          <SvgCloseIcon color={"#CCD2E3"} />
        </div>
        <div className={style.items}>
          <div className={style.mainItems}>
            {data.map((block) => {
              return (
                <div key={block.title} className={style.itemsBlockTablet}>
                  <div
                    className={`${style.titleBlock} ${isCollapsedDesktop ? style.titleBlockCollapsedDesktop : ""} ${
                      style.titleBlockCollapsedTablet
                    }`}
                  >
                    {block.title}
                  </div>
                  {block.items.map((item) => {
                    return <SidebarItem key={item.text} {...item} isCollapsed={true} />;
                  })}
                </div>
              );
            })}
          </div>
          <SidebarItem
            onClick={(e) => {
              e.preventDefault();
              window.location.replace(window.location.origin);
            }}
            icon={<ArrowLeft />}
            link="redirect-to-origin"
            text={t("Back to Site")}
            isCollapsed={isCollapsedDesktop}
          />
        </div>
      </div>
      {/*mobile menu*/}
      <div
        className={`${style.sidebar} ${style.sidebarMobile}  ${isCollapsedMobile ? style.sidebarCollapsedMobile : ""} `}
      >
        <div className={`${style.logotype}`} style={{ display: "block" }}>
          <PetaboxLogo fill="white" height={24} />
        </div>
        <div className={style.mobileMenuClose} onClick={collapseMobileClickItem}>
          <SvgCloseIcon color="#ffffff" />
        </div>
        <div className={style.items}>
          {data.map((block) => {
            return (
              <div key={block.title}>
                <div className={`${style.titleBlock} ${isCollapsedMobile ? style.titleBlockCollapsedMobile : ""}`}>
                  {block.title}
                </div>
                {block.items.map((item) => (
                  <SidebarItem key={item.text} {...item} isCollapsed={isCollapsedMobile} />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
