import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { RequestVersioningBucket } from "models/Bucket";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { setDisabledBucketVersioningModal } from "Redux/ui/uiState";
import { IBucket, updateBucket } from "../reducer";

export function* versioningBucketSaga({ payload }: PayloadAction<RequestVersioningBucket>) {
  try {
    const rep: IBucket = yield call([s3Client, s3Client.putVersioningBucket], payload.VersioningStatus, payload.Name);
    yield put(updateBucket(rep));
    if (payload.VersioningStatus === "Suspended") {
      yield put(setDisabledBucketVersioningModal(true));
    }
  } catch (e) {
    console.error(e);
    yield handleSagaError(e);
  }
}
