import { call, put } from "redux-saga/effects";
import { setTariffUserPlan } from "Redux/account/reducer";
import { handleSagaError } from "Redux/helpers";
import { GetTariffUserPlanTO } from "shared/src/models/TariffUserPlan";
import { ApiClient } from "../../../helpers/ApiClient";

export function* getTariffUserPlanProcess() {
  try {
    const tariff: GetTariffUserPlanTO = yield call(ApiClient.getTariffUserPlan);
    yield put(setTariffUserPlan(tariff));
  } catch (err) {
    yield handleSagaError(err, "Get tariff user plan");
  }
}
