import prettyBytes from "pretty-bytes";

export function removeArrayElement<T>(arr: T[], idx: number) {
  return arr.filter((_x, i) => i !== idx);
}

export function prettySize(size: string = "0") {
  return prettyBytes(Number(size), { binary: true }).replace("i", "");
}

type Dict = Record<string, any>;

export function updateByKey<T extends Dict, Key extends keyof T>(array: T[], key: Key, value: Partial<T>) {
  const idx = array.findIndex((x) => x[key] === value[key]);
  if (idx === -1) return array;
  const newElement = { ...array[idx], ...value };
  return [...array.slice(0, idx), newElement, ...array.slice(idx + 1)];
}

// https://www.slingacademy.com/article/ways-to-generate-random-strings-in-javascript/
export const generateRandomString = (length: number) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";

  // Create an array of 32-bit unsigned integers
  const randomValues = new Uint32Array(length);

  // Generate random values
  window.crypto.getRandomValues(randomValues);
  randomValues.forEach((value) => {
    result += characters.charAt(value % charactersLength);
  });
  return result;
};

export const centsToUsd = (value: number) => {
  const dollars = (value / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
  return dollars;
};

export function formatBytes(a, b = 2) {
  try {
    if (0 === a) return "0 B";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return (
      parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
      " " +
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    );
  } catch (err) {
    console.error("catch formatBytes", err);
    return "0 B";
  }
}
export const predicateOrUndefined = <T>(predicate: boolean, value: T) => (predicate ? value : undefined);

export const fullName = <T extends { name: string; lastName?: string }>({ name, lastName }: T) =>
  lastName ? `${name} ${lastName}` : name;
