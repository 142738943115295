import { PayloadAction } from "@reduxjs/toolkit";
import { apiUrl, fetchApi } from "helpers/common";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { getNotifications, SetAllReadNotificationPayload } from "../notification";

export function* setAllReadNotificationsProcess({ payload }: PayloadAction<SetAllReadNotificationPayload>) {
  const { pagination } = payload;
  try {
    yield call(fetchApi, "post", "201", {
      url: "/notifications/read-all",
      mainUrl: apiUrl,
    });
    yield put(getNotifications({ pagination }));
  } catch (err) {
    yield handleSagaError(err, "Notifications");
  }
}
