import { PayloadAction } from "@reduxjs/toolkit";
import { GetTicket } from "models/Ticket";
import { call, put } from "redux-saga/effects";
import { apiUrl, fetchApi } from "helpers/common";
import { IGetTicketsPayload } from "actions/accountActionTypes";
import { setTickets } from "Redux/account/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getTicketsProcess({ payload }: PayloadAction<IGetTicketsPayload>) {
  const { status, afterLoad } = payload;
  try {
    const tickets: GetTicket = yield call(fetchApi, "get", "200", {
      url: "/tickets/by-user",
      mainUrl: apiUrl,
      query: { status: status, page: 1, perPage: 10000000 },
    });
    yield put(setTickets(tickets?.data));
    afterLoad();
  } catch (err) {
    yield handleSagaError(err, "Get tickets");
  }
}
