import { PayloadAction } from "@reduxjs/toolkit";
import { catalogApi } from "api/CatalogApi";
import { call, put, select } from "redux-saga/effects";
import { selectTariffCatalog } from "Redux/catalogs/selectors/selectTariffCatalog";
import { TariffDto } from "shared/src/models/TariffDto";
import { setTariffCatalog } from "../catalogSlice";

export function* getTariffByIdSaga(action: PayloadAction<{ id: number }>) {
  try {
    const tariff: TariffDto = yield call([catalogApi, catalogApi.getTariffById], action.payload.id);
    const catalog: TariffDto[] = yield select(selectTariffCatalog);
    yield put(setTariffCatalog([...catalog, tariff]));
  } catch (e) {
    console.error(e);
  }
}
