import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { cloneDeep } from "lodash";
import { call, put, select } from "redux-saga/effects";
import { clearAllUploads, clearUploadFile, FilePath, IBucketsState } from "../reducer";
import { selectBucketState } from "../Selectors/selectUploadInfo";

export function* cancelSingleUploadProcess({ payload }: PayloadAction<FilePath>) {
  const { uploadState }: IBucketsState = yield select(selectBucketState);
  const upload = cloneDeep(uploadState[payload]);
  yield call([s3Client, s3Client.cancelUpload], upload);
  yield put(clearUploadFile(payload));
}

export function* cancelAllUploadsProcess() {
  const { uploadState }: IBucketsState = yield select(selectBucketState);
  const notReadyUploads = Object.values(uploadState).filter((x) => x.progress !== 100);

  for (const upload of notReadyUploads) {
    const _upload = cloneDeep(upload);
    yield call([s3Client, s3Client.cancelUpload], _upload);
  }

  yield put(clearAllUploads());
}
