import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      search: "Search",
      "trial until": "Trial until",
      "active until": "Active until",
      "expire on": "Will expire on",
      suspended: "Suspended",
      "payment required": "Payment required",
      month: "month",
      storage: "storage",
      transfer: "transfer",
      tariff: "tariff",
      "limits exceed": "limits exceed",
      "period start": "period start",
      "Trial period start": "Trial period start",
      "Trial period end": "Trial period end",
      "period end": "period end",
      "Your account is suspended": "Your account is suspended",
      "Please pay your invoice": "Please pay your invoice",
      "Account Settings": "Account Settings",
      logout: "logout",
      Overview: "Overview",
      Dashboard: "Dashboard",
      "Data Access": "Data Access",
      Buckets: "Buckets",
      "Access Keys": "Access Keys",
      Settings: "Settings",
      Account: "Account",
      Billing: "Billing",
      Shared: "Shared",
      Support: "Support",
      "Back to Site": "Back to Site",
      "See all notifications": "See all notifications",
      "You Don't Have Any Notifications Yet": "You Don't Have Any Notifications Yet",
      _new: "new",
      Trial: "Trial",
      days: "days",
      day_one: "day",
      day_many: "days",
      "Your trial period has expired": "Your trial period has expired",
      "Your tariff has been suspended": "Your account is suspended",
      "Try again. If the problem persists, create a": "Try again. If the problem persists, create a",
      ticket: "ticket",
      Files: "Files",
      Storage: "Storage",
      Bandwidth: "Bandwidth",
      "If you have any questions": "If you have any questions",
      "write&nbsp;to&nbsp;support": "write to support",
      Analytics: "Analytics",
      "You don't have the data yet": "You don't have the data yet",
      Loading: "Loading",
      "Download bandwidth": "Download bandwidth",
      "Total bandwidth": "Total bandwidth",
      "Create bucket": "Create bucket",
      "You do not have": "You do not have",
      "any buckets yet": "any buckets yet",
      Cancel: "Cancel",
      "Bucket name": "Bucket name",
      Access: "Access",
      Region: "Region",
      Server: "Server",
      Copy: "Copy",
      "Fill the field": "Fill the field",
      "Invalid date": "Invalid date",
      "Enter correct email": "Enter correct email",
      "File can't contain slash (/) in its name.": "File can't contain slash (/) in its name.",
      "Enter correct key name": "Enter correct key name",
      "Bucket name can only contain lower case Latin letters (a-b), digits (0-9) and hyphens (-).":
        "Bucket name can only contain lower case Latin letters (a-b), digits (0-9) and hyphens (-).",
      "Bucket name can't start or end with a hyphen.": "Bucket name can't start or end with a hyphen.",
      "Bucket name must be 3 or more characters long.": "Bucket name must be 3 or more characters long.",
      "Bucket name must be less than 63 characters long.": "Bucket name must be less than 63 characters long.",
      "Folder name must contain more than 1 character": "Folder name must contain more than 1 character",
      "User name must contain more than 1 character": "User name must contain more than 1 character",
      "Folder can't contain slash (/) in its name.": "Folder can't contain slash (/) in its name.",
      "Maximum length of Folder name 63 characters": "Maximum length of Folder name 63 characters",
      "Maximum length of user name 63 characters": "Maximum length of user name 63 characters",
      "Rename Bucket": "Rename Bucket",
      Delete: "Delete",
      "Delete Bucket": "Delete Bucket",
      "Are you sure you want to delete bucket": "Are you sure you want to delete bucket",
      "Bucket list": "Bucket list",
      Rename: "Rename",
      Download: "Download",
      "Make Public": "Make Public",
      "Make Private": "Make Private",
      "Custom access": "Custom access",
      "Show versions": "Show versions",
      "Create folder": "Create folder",
      "Upload files": "Upload files",
      "Delete files": "Delete files",
      "Delete file": "Delete file",
      "any uploaded files yet": "any uploaded files yet",
      "File name": "File name",
      Type: "Type",
      Size: "Size",
      "Last Modified": "Last Modified",
      Actions: "Actions",
      "Delete Marker": "Delete Marker",
      "Use this version": "Use this version",
      Folders: "Folders",
      "This action cannot be undone!": "This action cannot be undone!",
      "Are you sure you want to delete": "Are you sure you want to delete",
      "File Overview": "File Overview",
      "File Metadata": "File Metadata",
      "Copied to clipboard": "Copied to clipboard",
      Metadata: "Metadata",
      Key: "Key",
      Value: "Value",
      "Add new metadata": "Add new metadata",
      Save: "Save",
      Versions: "Versions",
      Version: "Version",
      "Current version ID": "Current version ID",
      "Data modified": "Data modified",
      type: "type",
      Status: "Status",
      Latest: "Latest",
      version: "version",
      "Create access key": "Create access key",
      "You do not have any access keys yet": "You do not have any access keys yet",
      "Delete access key": "Delete access key",
      "Delete Key": "Delete Key",
      "Are you sure you want to delete this access key?": "Are you sure you want to delete this access key?",
      User: "User",
      "Created on": "Created on",
      Activated: "Activated",
      Deactivated: "Deactivated",
      Inactive: "Inactive",
      "Access key": "Access key",
      "Secret Access Key": "Secret Access Key",
      Your: "Your",
      Hide: "Hide",
      Show: " Show",
      "Copy keys to clipboard": "Copy keys to clipboard",
      Active: "Active",
      Add: "Add",
      "payment method": "payment method",
      "Last 4 digits": "Last 4 digits",
      "Expires on": "Expires on",
      Default: "Default",
      Backup: "Backup",
      "No cards yet": "No cards yet",
      Verify: "Verify",
      Date: "Date",
      "Invoice Number": "Invoice Number",
      Plan: "Plan",
      Amount: "Amount",
      "No transactions yet": "No transactions yet",
      "Email successfully verified": "Email successfully verified",
      "Choose Plan": "Choose Plan",
      "By clicking &#171; Complete Purchase&#187;, you hereby agree to Petabox":
        "By clicking &#171; Complete Purchase&#187;, you hereby agree to Petabox",
      "Terms of use": "Terms of use",
      "Privacy Policy": "Privacy Policy",
      "Your payment method will be saved for future purchases and, if applicate, recurring subscription payments.":
        "Your payment method will be saved for future purchases and, if applicate, recurring subscription payments.",
      Pay: "Pay",
      Expires: "Expires",
      Saved: "Saved",
      "One-time charge": "One-time charge",
      and: "and",
      New: "New",
      Tariff: "Tariff",
      Change: "Change",
      "You have successfully changed your tariff from": "You have successfully changed your tariff from",
      "Your new tariff will be automatically paid and activated":
        "Your new tariff will be automatically paid and activated",
      "Until that moment, your current tariff will be valid": "Until that moment, your current tariff will be valid",
      "Your new plan will be automatically activated after the expiration of the current plan":
        "Your new plan will be automatically activated after the expiration of the current plan",
      Switching: "Switching",
      Subscription: "Subscription",
      Unsubscribe: "Unsubscribe",
      "from the tariff": "from the tariff",
      "We cancel the auto-subscription for the next month, the tariff is active until the end of its validity":
        "We cancel the auto-subscription for the next month, the tariff is active until the end of its validity",
      "Keep Plan": "Keep Plan",
      "Are you sure you want to": "Are you sure you want to",
      Period: "Period",
      Total: "Total",
      "Storage limit": "Storage limit",
      "Transfer limit": "Transfer limit",
      extra: "extra",
      "Please note that your new tariff will be active immediately after the end of current billing period":
        "Please note that your new tariff will be active immediately after the end of current billing period",
      "Undo Cancellation": "Undo Cancellation",
      "Purchase this plan": "Purchase this plan",
      "Ask for extend trial": "Ask for extend trial",
      "Access to files is temporarily blocked": "Access to files is temporarily blocked",
      "Your account will be suspended, after tariff period exceed":
        "Your account will be suspended, after tariff period exceed",
      "Access to files will be blocked on exceed tariff period":
        "Access to files will be blocked on exceed tariff period",
      "Choose this plan": "Choose this plan",
      "Switch to this plan": "Switch to this plan",
      new: "new",
      "Your tariff plan will not be cancelled at the end of the current billing cycle":
        "Your tariff plan will not be cancelled at the end of the current billing cycle",
      "Confirm Email": "Confirm Email",
      "You are just a few steps away": "You are just a few steps away",
      "We’ve sent an email to": "We’ve sent an email to",
      "with a link to complete your account":
        "with a link to complete your account setup. We promise it won’t take more than a minute",
      "Click to resend": "Click to resend",
      "the email if you can’t find it in your inbox or spam folder":
        "the email if you can’t find it in your inbox or spam folder",
      "If you need assistance, please": "If you need assistance, please",
      "Contact us": "Contact us",
      Trial_many: "Trial",
      "For a comfortable start": "For a comfortable start",
      "1 TB Storage / 1 TB Transfer": "1 TB Storage / 1 TB Transfer",
      "$0.004 / GB Additional Storage": "$0.004 / GB Additional Storage",
      "$0.001 / GB Additional Transfer": "$0.001 / GB Additional Transfer",
      "No commitment": "No commitment",
      "Unlimited API access": "Unlimited API access",
      "Priority email support": "Priority email support",
      "Most popular": "Most popular",
      "10 TB Storage / 10 TB Transfer": "10 TB Storage / 10 TB Transfer",
      "Live chat support": "Live chat support",
      "The most profitable": "The most profitable",
      "50 TB Storage / 50 TB Transfer": "50 TB Storage / 50 TB Transfer",
      "Free data migration": "Free data migration",
      "Access keys list": "Access keys list",
      Owner: "Owner",
      Logging: "Logging",
      "Create ticket": "Create ticket",
      Open: "Open",
      Closed: "Closed",
      "You don't have any open tickets": "You don't have any open tickets",
      "You don't have any closed tickets": "You don't have any closed tickets",
      "Close ticket": "Close ticket",
      "Are you sure you want to close ticket?": "Are you sure you want to close ticket?",
      "My Tickets": "My Tickets",
      "Please help us route your question to the relevant team by choosing a topic":
        "Please help us route your question to the relevant team by choosing a topic",
      "Select a Ticket Topic": "Select a Ticket Topic",
      "Select a Subtopic": "Select a Subtopic",
      "Enter your email": "Enter your email",
      "Please let us know the question you have and any further details":
        "Please let us know the question you have and any further details",
      "Thank you for your message! This is an automatic reply to let you know that we received your email. We will reply to you shortly":
        "Thank you for your message! This is an automatic reply to let you know that we received your email. We will reply to you shortly",
      "Submit Ticket": "Submit Ticket",
      Subject: "Subject",
      "Account details": "Account details",
      "User name": "User name",
      "User ID": "User ID",
      Company: "Company",
      Website: "Website",
      "Time Zone": "Time Zone",
      "Notification Email": "Notification Email",
      Email: "Email",
      "Email for billing notifications": "Email for billing notifications",
      "Change account details": "Change account details",
      "Change your email": "Change your email",
      "Your current email": "Your current email",
      "Enter new email": "Enter new email",
      Password: "Password",
      "Change password": "Change password",
      "New Password": "New Password",
      "with a link to complete your account setup": "with a link to complete your account setup",
      Enter: "Enter",
      Repeat: "Repeat",
      "Change my email": "Change my email",
      "Assign new": "Assign new",
      current: "current",
      password: "password",
      "Password changed": "Password changed",
      "Password successfully changed to a new one": "Password successfully changed to a new one",
      "Please confirm your new email": "Please confirm your new email",
      "Please enter a valid email address": "Please enter a valid email address",
      "Settings: A user with this email address already exists in the system":
        "Settings: A user with this email address already exists in the system",
      "Password is a required field": "Password is a required field",
      "New password is a required field": "New password is a required field",

      "New password must be at least 8 characters long": "New password must be at least 8 characters long",
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long":
        "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long",
      "Access control list": "Access control list",
      "Add an attachment or drop files here": "Add an attachment or drop files here",
      unsubscribed: "unsubscribed",
      "Your account will be blocked at": "Your account will be blocked at",
      "Your account will be expired after": "Your account will be expired after",
      "Key features": "Key features",
      "Contact Sales Departament": "Contact Sales Departament",
      yourAccountIsBlocked: "Your account is blocked",
      contactOurSupportTeamAboutUnlocking: "Contact our support team about unlocking",
    },
  },
  ru: {
    translation: {
      search: "Поиск",
      "trial until": "Пробный до",
      "active until": "Активен до",
      "expire on": "Истекает",
      suspended: "Приостановлен",
      "payment required": "Требуется оплата",
      month: "месяц",
      storage: "хранилище",
      transfer: "трафик",
      tariff: "тариф",
      "Trial period start": "Начало пробного периода ",
      "Trial period end": "Конец пробного периода ",
      "limits exceed": "лимит превышен",
      "period start": "дата начала",
      "period end": "дата окончания",
      "Your account is suspended": "Ваш аккаунт приостановлен",
      "Please pay your invoice": "Оплатите счет",
      "Account Settings": "Настройки Пользователя",
      logout: "выход",
      Overview: "Общее",
      Dashboard: "Управление",
      "Data Access": "Доступ к Данным",
      Buckets: "Хранилище",
      "Access Keys": "Ключи Доступа",
      Settings: "Настройки",
      Account: "Пользователь",
      Billing: "Оплата",
      "Key features": "Преимущества",
      Support: "Помощь",
      "Back to Site": "Вернуться к Сайту",
      "See all notifications": "Все оповещения",
      "You Don't Have Any Notifications Yet": "Нет новых оповещений",
      _new: "новое",
      Trial: "Пробный",
      days: "дней",
      day: "день",
      day_one: "день",
      day_many: "дней",
      "Your trial period has expired": "Ваш пробный период истек",
      "Your tariff has been suspended": "Ваш тариф приостановлен",
      "Try again. If the problem persists, create a": "Попробуйте снова. Если проблема не решена, напишите",
      ticket: "письмо",
      Files: "Файлы",
      Storage: "Хранилище",
      Bandwidth: "Трафик",
      "If you have any questions": "Если у вас есть вопросы",
      "write&nbsp;to&nbsp;support": "напишите в поддержку",
      Analytics: "Статистика",
      "You don't have the data yet": "Нет Данных",
      Loading: "Загрузка",
      "Download bandwidth": "Трафик загрузки",
      "Total bandwidth": "Суммарный трафик",
      "Create bucket": "Создать хранилище",
      "You do not have": "У вас нет",
      "any buckets yet": "ни одного хранилища",
      Cancel: "Отменить",
      "Bucket name": "Имя хранилища",
      Access: "Доступ",
      Region: "Регион",
      Server: "Сервер",
      Copy: "Копировать",
      "Fill the field": "Заполните поле",
      "Invalid date": "Неверная дата",
      "Enter correct email": "Введите корректный почтовый адрес",
      "File can't contain slash (/) in its name.": "Имя файла не может содержать (/) косую черту.",
      "Enter correct key name": "Введите корректное имя ключа",
      "Bucket name can only contain lower case Latin letters (a-b), digits (0-9) and hyphens (-).":
        "Имя хранилища может содержать только буквы Латинского алфавита (a-b) в нижнем регистре, числа (0-9) и разделитель (-).",
      "Bucket name can't start or end with a hyphen.":
        "Имя хранилища не может начинаться или заканчиваться разделителем (-).",
      "Bucket name must be 3 or more characters long.": "Длина имени хранилища должна быть 3 или более символов.",
      "Bucket name must be less than 63 characters long.": "Длина имени хранилища должна быть меньше 63-ех символов.",
      "Folder name must contain more than 1 character": "Имя папки должно содержать более 1 символа",
      "User name must contain more than 1 character": "Имя пользователя должно содержать более 1 символа",
      "Folder can't contain slash (/) in its name.": "Имя папки не может содержать (/) косую черту.",
      "Maximum length of Folder name 63 characters": "Максимальная длина имени папки 63 символа",
      "Maximum length of user name 63 characters": "Максимальная длина имени пользователя 63 символа",
      "Rename Bucket": "Переименовать хранилище",
      Delete: "Удалить",
      "Delete Bucket": "Удалить Хранилище",
      "Are you sure you want to delete bucket": "Вы уверены что хотите удалить хранилище",
      "Bucket list": "Список хранилищ",
      Rename: "Переименовать",
      Download: "Загрузить",
      "Make Public": "Сделать Публичным",
      "Make Private": "Сделать Приватным",
      "Custom access": "Настроить доступ",
      "Show versions": "Показать версии",
      "Create folder": "Создать папку",
      "Upload files": "Скачать файлы",
      "Delete files": "Удалить файлы",
      "Delete file": "Удалить файл",
      "any uploaded files yet": "загруженных файлов",
      "File name": "Имя файла",
      Type: "Тип",
      Size: "Размер",
      "Last Modified": "Изменено",
      Actions: "Действия",
      "Delete Marker": "Метка удаления",
      "Use this version": "Использовать версию",
      Folders: "Папки",
      "This action cannot be undone!": "Это действие невозможно отменить!",
      "Are you sure you want to delete": "Вы уверены что хотите удалить",
      "File Overview": "Обзор файлов",
      "File Metadata": "Мета данные",
      Metadata: "Мета данные",
      "Copied to clipboard": "Скопировано в буфер",
      Key: "Ключ",
      Value: "Значение",
      "Add new metadata": "Добавить метаданные",
      Save: "Сохранить",
      Versions: "Версии",
      "Current version ID": "ID Текущей версии",
      "Data modified": "Изменено",
      type: "тип",
      Status: "Статус",
      Latest: "Последняя",
      version: "версия",
      "Create access key": "Создать ключ доступа",
      "You do not have any access keys yet": "У вас еще нет ключей доступа",
      "Delete access key": "Удалить ключ доступа",
      "Delete Key": "Удалить ключ",
      "Are you sure you want to delete this access key?": "Вы действительно хотите удалить этот ключ?",
      User: "Пользователь",
      "Created on": "Дата создания",
      Activated: "Активно",
      Deactivated: "Неактивно",
      Inactive: "Неактивно",
      "Access key": "Ключ доступа",
      "Secret Access Key": "Секретный Ключ доступа",
      Your: "Ваш",
      Hide: "Спрятать",
      Show: " Показать",
      "Copy keys to clipboard": "Скопировать ключ в буфер",
      Active: "Активный",
      Add: "Добавить",
      "payment method": "платежный метод",
      "Last 4 digits": "Последние 4 цифры",
      "Expires on": "Истекает",
      Default: "По умолчанию",
      Backup: "Резерв",
      "No cards yet": "Нет карт",
      Verify: "Подтвердить",
      Date: "Дата",
      "Invoice Number": "Номер Платежа",
      Plan: "План",
      Amount: "Сумма",
      "No transactions yet": "Список операций пуст",
      "Email successfully verified": "Почта успешно подтверждена",
      "Choose Plan": "Выберете план",
      "By clicking &#171; Complete Purchase&#187;, you hereby agree to Petabox":
        "Нажимая на &#171; Закончить Оплату&#187;, таким образом вы соглашаетесь с",
      "Terms of use": "Правилами пользования",
      "Privacy Policy": "Политикой Приватности",
      "Your payment method will be saved for future purchases and, if applicate, recurring subscription payments.":
        "Ваш платежный способ будет сохнаранен, для использование в будущих покупках.",
      Pay: "Оплатить",
      Expires: "Истекает",
      Saved: "Сохранен",
      "One-time charge": "Единоразовое списание",
      and: "и",
      New: "Новый",
      Tariff: "Тариф",
      Change: "Сменить",
      "You have successfully changed your tariff from": "Вы успешно сменили тариф с",
      "Your new tariff will be automatically paid and activated": "Ваш новый тариф оплачен и активирован",
      "Until that moment, your current tariff will be valid": "С текущего момента, ваш тариф активен",
      "Your new plan will be automatically activated after the expiration of the current plan":
        "Ваш новый план будет автоматически активирован, после истечения текущего",
      Switching: "Смена",
      Subscription: "Подписки",
      Unsubscribe: "Отписаться",
      "from the tariff": "от тарифа",
      "We cancel the auto-subscription for the next month, the tariff is active until the end of its validity":
        "Мы автоматически отменим подписку в следующем месяце, тариф будет активен до даты истечения",
      "Keep Plan": "Оставить текущий",
      "Are you sure you want to": "Вы уверены что хотите",
      Period: "Период",
      Total: "Всего",
      "Storage limit": "Объем Хранилища",
      "Transfer limit": "Объем трафика",
      extra: "сверх",
      "Please note that your new tariff will be active immediately after the end of current billing period":
        "Учтите, что ваш новый тариф, будет активирован сразу после окончания текущего платежного периода",
      "Undo Cancellation": "Отмена действия",
      "Purchase this plan": "Оплатить план",
      "Access to files is temporarily blocked": "Доступ к файлам временно заблокирован",
      "Your account will be suspended, after tariff period exceed":
        "Ваш аккаунт будет временно приостановлен, после того как период тарифного плана истечет",
      "Access to files will be blocked on exceed tariff period":
        "Доступ к файлам будет заблокирован, после истечения периода",
      "Choose this plan": "Выбрать",
      "Switch to this plan": "Сменить",
      new: "новый",
      "Your tariff plan will not be cancelled at the end of the current billing cycle":
        "Ваш тарифный план не будет отменен в конце платежного периода",
      "Confirm Email": "Подтвердить Почту",
      "You are just a few steps away": "Осталось несколько шагов",
      "We’ve sent an email to": "Мы отослали письмо на почту",
      "with a link to complete your account": "в котором содержится ссылка для подтверждения аккаунта",
      "Click to resend": "Нажмите чтобы отослать повторно",
      "the email if you can’t find it in your inbox or spam folder":
        "Если вы не можете найти письмо, посмотрите в папке Спама",
      "If you need assistance, please": "Если вам нужна помощь",
      "Contact us": "Свяжитесь с Нами",
      Trial_many: "Пробных",
      "For a comfortable start": "Для удобного старта",
      "1 TB Storage / 1 TB Transfer": "1 TB Памяти / 1 TB Трафика",
      "$0.004 / GB Additional Storage": "$0.004 / GB Дополнительной Памяти",
      "$0.001 / GB Additional Transfer": "$0.001 / GB Дополнительного Трафика",
      "No commitment": "Без обязательств",
      "Unlimited API access": "Неограниченный доступ к API",
      "Priority email support": "Приоритетная помощь по почте",
      "Most popular": "Самый популярный",
      "10 TB Storage / 10 TB Transfer": "10 TB Памяти / 10 TB Трафика",
      "Live chat support": "Помощь в реальном времени",
      "The most profitable": "Самый выгодный",
      "50 TB Storage / 50 TB Transfer": "50 TB Памяти / 50 TB Трафика",
      "Free data migration": "Бесплатный перенос данных",
      "Access keys list": "Список ключей доступа",
      Owner: "Владелец",
      Logging: "Логирование",
      "Create ticket": "Создать запрос",
      Open: "Открыть",
      Closed: "Закрыто",
      "You don't have any open tickets": "У вас нету открытых запросов",
      "You don't have any closed tickets": "У вас нету закрытых запросов",
      "Close ticket": "Закрыть запрос",
      "Are you sure you want to close ticket?": "Вы уверены что хотите закрыть запрос?",
      "My Tickets": "Мои Запросы",
      "Please help us route your question to the relevant team by choosing a topic":
        "Помогите вас правильно скоординировать, выберите подходящую тему",
      "Select a Ticket Topic": "Выбрать тему",
      "Select a Subtopic": "Выбрать подтему",
      "Enter your email": "Введите ваш почтовый адрес",
      "Please let us know the question you have and any further details":
        "Пожалуйста, сообщите нам интересующий вас вопрос и любую дополнительную информацию",
      "Thank you for your message! This is an automatic reply to let you know that we received your email. We will reply to you shortly":
        "Спасибо за ваше сообщение! Это автоматический ответ, чтобы сообщить вам, что мы получили ваше письмо. Мы ответим вам в ближайшее время",
      "Submit Ticket": "Отправить запрос",
      Subject: "Тема",
      "Account details": "Детали аккаунта",
      "User name": "Имя пользователя",
      "User ID": "ID пользователя",
      Company: "Компания",
      Website: "Сайт",
      "Time Zone": "Часовой пояс",
      "Notification Email": "Почта для уведомлений",
      Email: "Почта",
      "Email for billing notifications": "Почта для выставления счетов",
      "Change account details": "Изменить данные",
      "Change your email": "Изменить адрес почты",
      "Your current email": "Ваш текущий адрес почты",
      "Enter new email": "Введите новую почту",
      Password: "Пароль",
      "Change password": "Смена пароля",
      "New Password": "Новый пароль",
      "with a link to complete your account setup": "со ссылкой для завершения настройки учетной записи",
      Enter: "Введите",
      Repeat: "Повторите",
      "Change my email": "Сменить почту",
      "Assign new": "Назначить новый",
      current: "текущий",
      password: "пароль",
      "Password changed": "Пароль изменен",
      "Password successfully changed to a new one": "Пароль успешно изменен на новый",
      "Please confirm your new email": "Пожалуйста введите вашу новую почту",
      "Please enter a valid email address": "Пожалуйста, введите действительный адрес электронной почты",
      "Settings: A user with this email address already exists in the system":
        "Пользователь с таким адресом электронной почты уже существует в системе.",
      "Password is a required field": "Пароль это обязательное поле",
      "New password is a required field": "Новый пароль это обязательное поле",
      "New password must be at least 8 characters long": "Новый пароль должен содержать не менее 8 символов",
      "Password must contain at least 1 Сapital character, 1 lowercase character, 1 number, 1 special symbol and be at least 8 characters long":
        "Пароль должен содержать не менее 1 заглавной буквы, 1 строчной буквы, 1 цифры, 1 специального символа и иметь длину не менее 8 символов.",
      "Access control list": "Список контроля доступа",
      "Add an attachment or drop files here": "Добавьте вложение или перетащите сюда файлы",
      unsubscribed: "отписан",
      "Your account will be blocked at": "Ваш аккаунт будет заблокирован через",
      "Your account will be expired after": "Ваша подписка истечет через",
      "Ask for extend trial": "Продлить пробный период",
      "Contact Sales Departament": "Связь с отделом продаж",
      yourAccountIsBlocked: "Ваш аккаунт заблокирован",
      contactOurSupportTeamAboutUnlocking: "Свяжитесь с нашей службой поддержки по поводу разблокировки",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
