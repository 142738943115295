import { PayloadAction } from "@reduxjs/toolkit";
import { IBucketRename } from "actions/interfaces";
import { s3Client } from "api/s3.client";
import { defaultError } from "helpers/errorHandler";
import { call, put } from "redux-saga/effects";
import { setCommonError } from "Redux/ErrorReducer/ErrorReducer";
import { getBucketsList } from "../Actions/bucketsActions";

export function* renameBucketProcess({ payload }: PayloadAction<IBucketRename>) {
  const { name, nameBucket, afterLoad, setOuterError } = payload;
  try {
    const pagination = { Page: 1, PerPage: 100 };
    yield call([s3Client, s3Client.renameBucketFunc], { nameBucket, name });
    yield put(getBucketsList({ pagination, afterLoad: afterLoad }));
  } catch (err) {
    console.log("catch err renameBucket", err?.message, err?.code, err?.Code, err?.status);
    if (err?.message) {
      setOuterError(err?.message);
    } else {
      yield put(setCommonError({ message: defaultError, isBottomText: true }));
    }
  }
}
