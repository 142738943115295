import React from "react";
import { ReactSvgProps } from "../models/ReactSvgProps";

export const CardSvg: React.FC<ReactSvgProps> = (props) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.333 0.666687H1.66634C0.931008 0.666687 0.333008 1.26469 0.333008 2.00002V3.33335H13.6663V2.00002C13.6663 1.26469 13.0683 0.666687 12.333 0.666687ZM0.333008 10C0.333008 10.7354 0.931008 11.3334 1.66634 11.3334H12.333C13.0683 11.3334 13.6663 10.7354 13.6663 10V6.00002H0.333008V10ZM2.33301 8.00002H6.33301V9.33335H2.33301V8.00002Z"
        fill="evenodd"
      />
    </svg>
  );
};
