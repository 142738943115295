import Button from "components/UI/Button/Button";
import { DragAndDrop, File } from "components/UI/DragAndDrop/DragAndDrop";
import Link from "components/UI/Link/Link";
import Pagination from "components/UI/Pagination/Pagination";
import { size } from "lodash";
import { UploadFileType } from "models/UploadFileType";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelAllUploadAction,
  cancelUploadAction,
  prepareDroppedFilesAction,
  startUploadAction,
} from "Redux/buckets/Actions/bucketsActions";
import { setUpload } from "Redux/buckets/reducer";
import { selectUpload } from "Redux/buckets/Selectors/selectUpload";
import { selectPrettyTotalUploadSize, selectUploadState } from "Redux/buckets/Selectors/selectUploadInfo";
import { CoreModal } from "../Modal";
import style from "./uploadFileModal.module.scss";

const PER_PAGE = 5;

export const UploadModal: React.FC = () => {
  const { isModalUpload, isLoadingFiles } = useSelector(selectUpload);
  const uploadFiles = useSelector(selectUploadState);
  const prettyTotalUploadSize = useSelector(selectPrettyTotalUploadSize);
  const [currentPage, setCurrentPage] = useState(0);

  const filesPage = useMemo(() => {
    const start = PER_PAGE * currentPage;
    return uploadFiles.slice(start, start + PER_PAGE);
  }, [currentPage, uploadFiles]);

  const disableUploadBtn = isLoadingFiles || size(uploadFiles) === 0;

  const dispatch = useDispatch();
  const onCancelAll = () => dispatch(cancelAllUploadAction());
  const onCloseModal = () => {
    dispatch(setUpload({ isModalUpload: false }));
    if (!isLoadingFiles) onCancelAll();
  };
  const onFilesDrop = (files: UploadFileType[]) => dispatch(prepareDroppedFilesAction(files));
  const onCancelUpload = (filepath: string) => dispatch(cancelUploadAction(filepath));

  const onStartUpload = () => {
    dispatch(startUploadAction());
  };

  return (
    <CoreModal
      uploadFiles={uploadFiles.length}
      closeOutClick
      disabled={isLoadingFiles}
      visible={isModalUpload}
      onClose={onCloseModal}
      title="Upload files"
      className={style.wrapper}
    >
      <div className={style.container}>
        <div className={style.dragWrapper}>
          {!isLoadingFiles && (
            <DragAndDrop
              size={prettyTotalUploadSize}
              onUploadFiles={onFilesDrop}
              disabled={isLoadingFiles}
              typePage="backet"
            />
          )}
        </div>

        <div className={style.footerWrapper}>
          <div className={style.helpText}>
            Any problems? Please try again or check out our <Link href="/dashboard/support" children="help center" />{" "}
            page.
          </div>

          <div className={style.buttonsRow}>
            <div className={style.wrapBtn1}>
              <Button onClick={onCancelAll} color="additional">
                Cancel
              </Button>
            </div>
            <div className={style.wrapBtn2}>
              <Button onClick={onStartUpload} color={"primary"} disabled={disableUploadBtn}>
                Upload files
              </Button>
            </div>
          </div>
        </div>
        <div className={style.filesList}>
          {filesPage.map((x) => (
            <File
              key={x.filemeta.filepath}
              onDelete={() => onCancelUpload(x.filemeta.filepath)}
              object={{ file: x.filemeta.originFile, progress: x.progress }}
              noProgress={false}
            />
          ))}
        </div>

        {Math.ceil(uploadFiles.length / PER_PAGE) > 1 && (
          <div className={style.paginationWrapper}>
            <Pagination
              hasSelect={false}
              activePage={currentPage + 1}
              count={Math.ceil(uploadFiles.length / PER_PAGE)}
              onSelect={() => false}
              onClickPage={(n) => setCurrentPage(n - 1)}
            />
          </div>
        )}
      </div>
    </CoreModal>
  );
};
