import { PayloadAction } from "@reduxjs/toolkit";
import { S3File } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { getFileInfo } from "Redux/buckets/Actions/bucketsActions";
import { handleSagaError } from "Redux/helpers";

export function* setCurrentFileVersionProcess({ payload }: PayloadAction<S3File>) {
  try {
    const { nameBucket, nameFile, pathFolder, versionId } = payload;
    yield call([s3Client, s3Client.setDefaultFileVersion], {
      nameBucket,
      nameFile,
      pathFolder,
      versionId,
    });
    yield put(
      getFileInfo({
        nameFile,
        nameBucket,
        pathFolder,
        afterLoad: () => {},
      })
    );
  } catch (err) {
    yield handleSagaError(err);
  }
}
