import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type RouteWithMainLayout = Record<string, string>;

type ListenActionType = string;
type AsyncButtonID = string;
export type AsyncButtonStateMap = {
  [key: ListenActionType]: AsyncButtonID;
};

export type UIState = {
  isMob: boolean;
  successModal: {
    isOpen: boolean;
    text: string | null;
  };
  forcePushDashboard: boolean;
  skipDashboard: {
    skipChecked: boolean;
    state: boolean;
  };
  billingPageOnly: boolean;
  routesWithMainLayout: RouteWithMainLayout;
  asyncButtonStateDisabled: boolean;
  confirmModalState: boolean;
  settingsModalState: boolean;
  asyncProcess: boolean;
  asyncButtonState: AsyncButtonStateMap;
  suspendedModalState: boolean;
  disabledBucketVersioningModalState: boolean;
};

const initialState: UIState = {
  isMob: false,
  successModal: {
    isOpen: false,
    text: null,
  },
  forcePushDashboard: false,
  skipDashboard: {
    skipChecked: false,
    state: false,
  },
  billingPageOnly: false,
  routesWithMainLayout: {
    "": "",
    "/": "/",
    "/dashboard/blocked": "/dashboard/blocked",
  },
  asyncButtonStateDisabled: false,
  confirmModalState: false,
  settingsModalState: false,
  asyncProcess: false,
  asyncButtonState: {},
  suspendedModalState: false,
  disabledBucketVersioningModalState: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsMob(state, action: PayloadAction<boolean>) {
      return { ...state, isMob: action.payload };
    },
    openSuccessModal(state, action: PayloadAction<UIState["successModal"]>) {
      return { ...state, successModal: action.payload };
    },
    closeSuccessModal(state) {
      return {
        ...state,
        successModal: {
          isOpen: false,
          text: null,
        },
      };
    },
    setAllowDashboard(state, action: PayloadAction<boolean>) {
      return { ...state, forcePushDashboard: action.payload };
    },
    setActionButtonState(state, action: PayloadAction<boolean>) {
      return { ...state, asyncButtonStateDisabled: action.payload };
    },
    setConfirmModalState(state, action: PayloadAction<boolean>) {
      return { ...state, confirmModalState: action.payload };
    },
    setSettingsModalState(state, action: PayloadAction<boolean>) {
      return { ...state, settingsModalState: action.payload };
    },
    setRouteWithMainLayout(state, action: PayloadAction<string>) {
      const originPath = action.payload.replace(/:(\w+)/g, "");
      return {
        ...state,
        routesWithMainLayout: {
          ...state.routesWithMainLayout,
          [action.payload]: originPath,
          [action.payload + "/"]: originPath,
        },
      };
    },
    setLoadingAsyncButton(state, action: PayloadAction<{ buttonId: string; endActionType: string }>) {
      return {
        ...state,
        asyncButtonState: {
          ...state.asyncButtonState,
          [action.payload.endActionType]: action.payload.buttonId,
        },
      };
    },
    removeLoadingAsyncButton(state, action: PayloadAction<string>) {
      const { [action.payload]: removedKey, ...asyncButtonState } = state.asyncButtonState;
      return { ...state, asyncButtonState };
    },
    clearAllLoadingAsyncButtonState(state) {
      return {
        ...state,
        asyncButtonState: {},
      };
    },
    setSuspendedModal(state, action: PayloadAction<boolean>) {
      return { ...state, suspendedModalState: action.payload };
    },
    setDisabledBucketVersioningModal(state, action: PayloadAction<boolean>) {
      return { ...state, disabledBucketVersioningModalState: action.payload };
    },
  },
});

export const {
  setIsMob,
  openSuccessModal,
  closeSuccessModal,
  setDisabledBucketVersioningModal,
  setActionButtonState,
  setConfirmModalState,
  setSettingsModalState,
  setRouteWithMainLayout,
  setLoadingAsyncButton,
  removeLoadingAsyncButton,
  clearAllLoadingAsyncButtonState,
  setSuspendedModal,
} = uiSlice.actions;

export const uiStateReducer = uiSlice.reducer;
