import { format as originFormat, isDate } from "date-fns";
import { enGB, ru } from "date-fns/locale";
import { isNil } from "./utils";

const locales = { enGB, ru };

// m - month, d - day, t - time, y - year
export const FORMAT_DATE_TEMPLATES = {
  mdty: "MMM d, yyyy h:mm aa" as string, // Oct 5, 2022 09:05 AM | rus -> окт. 5, 2022 09:05
  dmyh: "dd.MM.yyyy HH:mm", // 03.09.2021 14:23
  dmy: "dd/MM/yyyy", // 03/09/2021
} as const;

export function format(date?: Date | string, formatStr = FORMAT_DATE_TEMPLATES.mdty) {
  if (isNil(date)) return;

  if (!isDate(date)) {
    date = new Date(date);
  }
  if (window.__localeId__ === "ru") {
    formatStr = formatStr.replace("h", "k");
    formatStr = formatStr.replace("aa", "");
  }

  return originFormat(date as Date, formatStr, {
    locale: locales[window.__localeId__],
  });
}
