import clsx from "clsx";
import React from "react";
import styles from "./ButtonIcon.module.scss";

type ButtonIconProps = {
  icon: React.ReactElement;
  onClick?: () => void;
  className?: string;
  size?: number;
};

export const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const { className, size = 48, icon, onClick } = props;

  return (
    <button
      style={{ height: `${size}px`, width: `${size}px` }}
      onClick={onClick}
      className={clsx(styles.btn, className)}
      type="button"
    >
      {React.cloneElement(icon, {
        style: { width: "54%", height: "auto", margin: "auto" },
      })}
    </button>
  );
};
