import axios from "axios";
import { AppConfig } from "config";
import { format } from "date-fns";
import i18n from "i18n";
import moment from "moment";
import "moment/locale/en-gb";
import { useEffect, useRef } from "react";
import { getToken } from "shared/src/helpers/token";
import io from "socket.io-client";

export const formatDate = (date) => {
  /*Mar 12, 2021 03:25 AM*/
  return moment(date).format("MMM D, YYYY hh:mm A");
};
export const formatDateTransactions = (date) => {
  /*03.09.2021 14:23*/
  return moment(date).format("DD.MM.YYYY hh:mm ");
};

export const { baseUrl, urlAuthNode, apiUrl, type, technicalProcess } = AppConfig;

export function usePrevious<T>(value: T): T {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current as T;
}

export interface IFetchParams {
  url: string;
  body?: any;
  query?: any;
  mainUrl?: string;
  headers?: any;
}

export const fetchApi = (method: string, codeSuccess = "200", params: IFetchParams) => {
  const { url, body, query, mainUrl, headers } = params;
  const firstUrl = mainUrl ? mainUrl : baseUrl;

  const token = getToken();
  let headersFull = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  if (isFull(headers)) {
    headersFull = { ...headersFull, ...headers };
  }
  let config = {
    url: `${firstUrl}${url}`,
    method: method,
    headers: headersFull,
  } as any;

  if (query) {
    config.params = query;
  }
  if (body) {
    config.data = body;
  }
  return axios(config)
    .then((res) => {
      if (+res.status !== +codeSuccess && +res.status !== 200) {
        throwError({ status: res.status, message: res?.data?.message });
      }
      if (res.data.error) {
        throwError({ status: res.status, message: res?.data?.message });
      }
      return res.data as any;
    })
    .catch((error) => {
      if (error.response) {
        throwError({
          status: error.response.status,
          message: error.response?.data?.message || error?.response?.statusText || error.response?.data?.Error?.Message,
        });
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        throwError({ status: "", message: "no response" });
      } else if (error.status) {
        // todo: check for working correctly
        throwError({ status: error.status, message: error.message });
      } else {
        throwError({ status: "", message: error });
      }
    });
};

export const throwError = (error) => {
  const err = new Error(error.message) as any;
  err.status = error.status;
  throw err;
};

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function isFull(elem: any) {
  if (typeof elem === "undefined") {
    return false;
  }
  if (typeof elem === "object") {
    const stringifiedElem = JSON.stringify(elem, getCircularReplacer());
    if (elem === null) return false;
    try {
      if (stringifiedElem === "{}") return false;
      if (stringifiedElem === "[]") return false;
      if (stringifiedElem === "undefined") return false;
    } catch (err) {}
    for (const key in elem) {
      if (isFull(elem[key])) {
        return true;
      }
    }
    return Object.keys(elem)?.length === 0 && stringifiedElem?.length > 0;
  }
  if (typeof elem === "string") {
    if (elem === "{}" || elem === "[]") return false;
    if (elem === "''" || elem === '""') return false;
    return elem.length > 0;
  }
  if (Array.isArray(elem)) {
    if (elem.length > 0) {
      for (let i = 0; i < elem.length; i++) {
        if (isFull(elem[i])) return true;
      }
      return false;
    }
    return false;
  }
  if (typeof elem === "boolean") {
    return elem;
  }
  if (typeof elem === "number") {
    return true;
  }
  return true;
}

export const arrayClone = (arr) => {
  let i, copy;
  if (Array.isArray(arr)) {
    copy = arr.slice(0);
    for (i = 0; i < copy.length; i++) {
      copy[i] = arrayClone(copy[i]);
    }
    return copy;
  } else if (typeof arr === "object") {
    let obj = {};
    for (let key in arr) {
      if (arr.hasOwnProperty(key)) {
        if (Array.isArray(arr[key]) || typeof arr[key] === "object") {
          obj = { ...obj, [key]: arrayClone(arr[key]) };
        } else {
          obj = { ...obj, [key]: arr[key] };
        }
      }
    }
    return obj;
  } else {
    return arr;
  }
};

export function capitalize(string = "") {
  return string.length > 1 ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const formBreadcrumbs = ({ initElem, pathFolder, nameBucket, file }) => {
  let result = [initElem];
  let elems = pathFolder.split("/");
  if (elems[elems.length - 1]?.length === 0) {
    elems.pop();
  }
  elems.unshift(nameBucket);

  const n = elems.length;

  let prev = initElem.path;
  for (let i = 0; i < n; i++) {
    const path = `${prev}/${elems[i]}`;
    let elem = elems[i];
    // const decoded = decodeURI(elem);
    //result.push({title: decodeURIComponent(elems[i]), path: path});
    result.push({ title: elem, path: path.replace("//", "/") });
    prev = path;
  }

  if (file) {
    result.push({ title: file });
  }
  return result;
};

export const downloadFileByLink = async (link, name) => {
  return new Promise((resolve) => {
    if (typeof document !== "undefined") {
      const linkElem = document.createElement("a");
      linkElem.setAttribute("href", link);
      linkElem.setAttribute("download", name);
      linkElem.setAttribute("_target", "_blank");
      linkElem.style.display = "none";
      document.body.appendChild(linkElem);
      linkElem.click();
      document.body.removeChild(linkElem);
      resolve(true);
    } else {
      resolve(true);
    }
  });
};

export function downloadFiles(files) {
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    const a = document.createElement("a");
    a.href = files[i].link;
    a.target = "_parent";
    // Use a.download if available, it prevents plugins from opening.
    if ("download" in a) {
      a.download = files[i].name;
    } else {
      a.setAttribute("download", files[i].name);
    }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    }
    // Delete the temporary link.
    (document.body || document.documentElement).removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      download_next(i + 1);
    }, 1000);
  }

  // Initiate the first download.
  download_next(0);
}

export function formatBytes(a, b = 2) {
  try {
    if (0 === a) return "0 B";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return (
      parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
      " " +
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    );
  } catch (err) {
    console.error("catch formatBytes", err);
    return "0 B";
  }
}

export const initSocket = () => {
  const token = getToken() as string;
  return io(apiUrl, {
    path: "/socket.io",
    query: { token: token },
    forceNew: true,
    transports: ["websocket", "polling"],
  });
};

export function validateBucketName(bucketName: string): string {
  if (bucketName?.length === 0) {
    return i18n.t("Fill the field");
  }
  if (!bucketName.match(/^[a-z0-9-]+$/)) {
    return i18n.t("Bucket name can only contain lower case Latin letters (a-b), digits (0-9) and hyphens (-).");
  }
  if (bucketName[0] === "-" || bucketName?.[bucketName.length - 1] === "-") {
    return i18n.t("Bucket name can't start or end with a hyphen.");
  }
  if (bucketName?.length < 3) {
    return i18n.t("Bucket name must be 3 or more characters long.");
  }
  if (bucketName?.length > 63) {
    return i18n.t("Bucket name must be less than 63 characters long.");
  }
  return "";
}

export function isFolderNameValid(nameFolder: string): boolean {
  return nameFolder.length >= 1 && nameFolder.length <= 63 && nameFolder.indexOf("/") <= -1;
}

export const validateFolderName = (nameFolder: string): string => {
  switch (true) {
    case nameFolder.length < 1: {
      return i18n.t("Folder name must contain more than 1 character");
    }
    case nameFolder.indexOf("/") !== -1: {
      return i18n.t("Folder can't contain slash (/) in its name.");
    }
    case nameFolder.length >= 63: {
      return i18n.t("Maximum length of Folder name 63 characters");
    }
    default: {
      return "";
    }
  }
};

export const validateUserName = (nameFolder: string): string => {
  switch (true) {
    case nameFolder.length < 1: {
      return i18n.t("User name must contain more than 1 character");
    }
    case nameFolder.length >= 63: {
      return i18n.t("Maximum length of user name 63 characters");
    }
    default: {
      return "";
    }
  }
};

export function isMetadataKeyValid(metadataKey: string): boolean {
  return (
    metadataKey.length >= 1 &&
    metadataKey.length <= 63 &&
    !!metadataKey.match(/^[a-zA-Z0-9-]*$/) &&
    !metadataKey.match(/^[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+$/)
  );
}

export function uriEncode(input: string, encodeSlash = true): string {
  let result = "";
  const te = new TextEncoder();

  for (let i = 0; i < input.length; i++) {
    const ch = input[i];

    if (
      (ch.charCodeAt(0) >= "A".charCodeAt(0) && ch.charCodeAt(0) <= "Z".charCodeAt(0)) ||
      (ch.charCodeAt(0) >= "a".charCodeAt(0) && ch.charCodeAt(0) <= "z".charCodeAt(0)) ||
      (ch.charCodeAt(0) >= "0".charCodeAt(0) && ch.charCodeAt(0) <= "9".charCodeAt(0)) ||
      ch === "_" ||
      ch === "-" ||
      ch === "~" ||
      ch === "."
    ) {
      result += ch;
    } else if (ch === "/") {
      result += encodeSlash ? "%2F" : "/";
    } else {
      te.encode(ch).forEach((code) => {
        result += "%" + code.toString(16).padStart(2, "0").toUpperCase();
      });
    }
  }

  return result;
}

export function removeZeros(num: number | string): string {
  let value = num.toString();

  function removeZero(val: string): string {
    let idx = val.indexOf(".");
    if (idx < 0) {
      idx = val.indexOf(",");
    }
    if (idx > 0) {
      if ((val[0] === "0" && idx > 1) || val[val?.length - 1] === "0") {
        if (val[val?.length - 1] === "0") {
          val = val.substring(0, val.length - 1);
        }
        if (val[0] === "0" && idx > 1) {
          val = val.substring(1);
        }
        return removeZero(val);
      } else {
        if (val[val?.length - 1] === "." || val[val?.length - 1] === ",") {
          val = val.substring(0, val.length - 1);
        }
        return val;
      }
    } else {
      if (val[0] === "0" && val.length > 1) {
        val = val.substring(1);
        return removeZeros(val);
      } else {
        return val;
      }
    }
  }

  if (value?.toString() === "0") {
    return "0";
  }
  return removeZero(value);
}

export const voidCallback = () => null as unknown as void;

export const formateDateFns = (date: Date) => format(date, "dd/MM/yyyy");

export const isFolderType = <T extends { type?: string }>(x: T) => {
  return x.type === "folder";
};
