//@ts-nocheck

import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import { i18n, withTranslation } from "react-i18next";
import { predicateOrUndefined } from "../../../helpers/utils";
import "./DayPicker.css";

export const CALENDAR_LOCALES = {
  ru: {
    month: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    weekdaysLong: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пн", "Сб"],
  },
};

type DayPickerRangeProps = {
  handleFromChange: (to: Date) => void;
  handleToChange: (to: Date) => void;
  from: Date;
  to: Date;
  ref: React.RefObject<DayPickerRange>;
  i18n: i18n;
  classNameFrom?: string;
  classNameTo?: string;
};

class DayPickerRange extends React.Component<DayPickerRangeProps> {
  componentDidUpdate() {
    const daypickerInput = this.props.innerRef.current as DayPickerInput;
    const input = daypickerInput.getDayPicker();
    if (!input) return;
    const dayPicker = input.dayPicker;
    const weeks = dayPicker.querySelectorAll(".DayPicker-Week");

    for (const week of weeks) {
      const days = week.querySelectorAll(".DayPicker-Day");
      const daysArray = Array.from(days);
      const selectedDays = week.querySelectorAll(".DayPicker-Day--selected");

      for (const selectedDay of selectedDays) {
        const idx = daysArray.indexOf(selectedDay);
        const prevDay = selectedDay.previousSibling;
        if (prevDay) {
          const prevDayHidden = (prevDay as HTMLElement).classList.contains("DayPicker-Day--outside");
          if (prevDayHidden) {
            selectedDay.classList.add("DayPicker-Day-noBefore");
          }
        }
        if (idx === 0) {
          selectedDay.classList.add("DayPicker-Day-noBefore");
        }
        if (idx === 6) {
          selectedDay.classList.add("DayPicker-Day-noAfter");
        }
      }
    }
  }
  render() {
    const { from, to, handleFromChange, handleToChange, i18n } = this.props;
    const { language } = i18n;
    const ruLocale = language === "ru";
    const modifiers = { start: from, end: to };

    const modifiersStyles = {
      birthday: {
        color: "white",
        backgroundColor: "#ffc107",
      },
      thursdays: {
        color: "#ffc107",
        backgroundColor: "#fffdee",
      },
    };

    const datePickerLocaleProps = {
      months: predicateOrUndefined(ruLocale, CALENDAR_LOCALES.ru.month),
      weekdaysLong: predicateOrUndefined(ruLocale, CALENDAR_LOCALES.ru.weekdaysLong),
      weekdaysShort: predicateOrUndefined(ruLocale, CALENDAR_LOCALES.ru.weekdaysShort),
    };

    return (
      <div className="InputFromTo">
        <div className={`InputFromTo-from ${this.props.classNameFrom}`}>
          <div className="labelInput">From:</div>
          <DayPickerInput
            modifiersStyles={modifiersStyles}
            value={from}
            placeholder=""
            format="YYYY-MM-DD"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { after: to },
              toMonth: to,
              modifiers,
              numberOfMonths: 1,
              onDayClick: () => this.props.innerRef.current.getInput().focus(),
              ...datePickerLocaleProps,
            }}
            onDayChange={handleFromChange}
          />
        </div>

        <div className={`InputFromTo-to ${this.props.classNameTo}`}>
          <div className="labelInput">To:</div>
          <DayPickerInput
            ref={this.props.innerRef}
            value={to}
            placeholder=""
            format="YYYY-MM-DD"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 1,
              ...datePickerLocaleProps,
            }}
            onDayChange={handleToChange}
          />
        </div>
      </div>
    );
  }
}

const _DayPicker = withTranslation()(DayPickerRange);

export default React.forwardRef((props, ref) => <_DayPicker innerRef={ref} {...props} />);
