import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { DeleteFileFolderMultiple } from "models/Bucket";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { getFilesList } from "../Actions/bucketsActions";

export function* deleteFileFolderMultipleProcess({ payload }: PayloadAction<DeleteFileFolderMultiple>) {
  const {
    nameBucket,
    isSharedList,
    items,
    pathFolder,
    onSuccess,
    perPage,
    bucketVersioningEnabled,
    bypassGovernanceRetention,
  } = payload;
  try {
    yield call([s3Client, s3Client.deleteMultiplyFilesFunc], {
      nameBucket,
      pathFolder,
      items,
      bucketVersioningEnabled,
      bypassGovernanceRetention,
    });
    onSuccess();
    if (!isSharedList) {
      yield put(
        getFilesList({
          nameBucket,
          pathFolder,
          afterLoad: () => {},
          pagination: { Page: 1, PerPage: perPage as number },
        })
      );
    }
  } catch (err) {
    yield handleSagaError(err);
  }
}
