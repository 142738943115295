import { PayloadAction } from "@reduxjs/toolkit";
import { EditUserDataPayload } from "actions/actionTypes";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { fetchApi, urlAuthNode } from "../../../helpers/common";
import { getSettingsInfo } from "../Actions/userActions";

export function* setUserDataSettingsProcess({ payload }: PayloadAction<EditUserDataPayload>) {
  const { name, companyName, website, lastName, country, phone } = payload;
  try {
    yield call(fetchApi, "put", "200", {
      url: "/me",
      body: { name, companyName, website, lastName, country, phone },
      mainUrl: urlAuthNode,
    });
    yield put(getSettingsInfo());
  } catch (err) {
    yield handleSagaError(err, "Settings");
  }
}
