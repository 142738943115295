import { PayloadAction } from "@reduxjs/toolkit";
import { ApiClient } from "helpers/ApiClient";
import { notNil } from "helpers/utils";
import { call, put } from "redux-saga/effects";
import { UserProfile } from "shared/src/models/UserModel";
import { handleSagaError } from "../../helpers";
import { setUnAuthorizedAction, setUserData } from "../reducer";
import { init } from "./init";

export function* checkAuthNew({ payload }: PayloadAction<() => void>) {
  try {
    const userProfile: UserProfile = yield call(ApiClient.getUserProfile);
    const { tariffId } = userProfile;

    if (notNil(tariffId)) {
      yield call(init);
    }

    yield put(setUserData({ user: userProfile, authResult: { checked: true, result: true } }));

    if (payload) {
      payload();
    }
  } catch (e) {
    yield put(setUnAuthorizedAction());
    yield handleSagaError(e);
  }
}
