import { ApiClient } from "helpers/ApiClient";
import { NotificationRep } from "models/Notification";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { setNotificationsPanel } from "../notification";

export function* getNotificationsPanelProcess() {
  try {
    const result: NotificationRep = yield call(ApiClient.getNotifications, { last: 3 });
    yield put(setNotificationsPanel(result));
  } catch (err) {
    yield handleSagaError(err, "Notifications");
  }
}
