import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BucketSelectItem } from "containers/BucketPage/BucketPage";

export type DownloadFilesState = {
  filesToDownload: BucketSelectItem[];
  foldersCnt: number;
  filesCnt: number;
  isModalOpen: boolean;
  bucketName: string;
  pathFolder: string;
  loading: boolean;
  zipId: string;
  filesSizeSumReadable: string;
};

export type PrepareFilesForDownloadPayload = {
  files: BucketSelectItem[];
  pathFolder: string;
  bucketName: string;
};

export type SetZipInfoPayload = {
  foldersCnt: number;
  filesCnt: number;
  zipId: string;
  loading: boolean;
  filesSizeSumReadable: string;
};

const initialState: DownloadFilesState = {
  filesToDownload: [],
  isModalOpen: false,
  bucketName: "",
  pathFolder: "",
  foldersCnt: 0,
  filesCnt: 0,
  loading: false,
  zipId: "",
  filesSizeSumReadable: "0 KB",
};

const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    prepareFilesForDownload(state, action: PayloadAction<PrepareFilesForDownloadPayload>) {
      const { files, bucketName, pathFolder } = action.payload;
      return { ...state, filesToDownload: files, bucketName, pathFolder };
    },
    openConfirmModalAction(state) {
      return { ...state, isModalOpen: true, loading: true };
    },
    setZipInfo(state, action: PayloadAction<SetZipInfoPayload>) {
      return { ...state, ...action.payload, loading: false };
    },
    closeConfirmModalAction() {
      return initialState;
    },
  },
});

export const { prepareFilesForDownload, openConfirmModalAction, closeConfirmModalAction, setZipInfo } =
  downloadSlice.actions;

export const downloadReducer = downloadSlice.reducer;
