import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import style from "./link.module.scss";

interface IProps {
  color?: "primary" | "secondary";
  href?: string;
  outerLink?: boolean;
  onClickHandler?: () => void;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}
const LinkComponent: React.FC<IProps> = ({
  children,
  onClick,
  href,
  onClickHandler,
  outerLink = false,
  className,
}: IProps) => {
  return (
    <React.Fragment>
      {onClickHandler ? (
        <span onClick={onClickHandler} className={clsx(style.item, className)}>
          {children}
        </span>
      ) : outerLink ? (
        <a rel="noreferrer" href={href} target="_blank" className={clsx(style.item, className)}>
          {children}
        </a>
      ) : (
        <Link onClick={onClick} to={href ?? ""} className={clsx(style.item, className)}>
          {children}
        </Link>
      )}
    </React.Fragment>
  );
};
export default LinkComponent;
