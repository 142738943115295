import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { S3 } from "aws-sdk";
import { call, put } from "redux-saga/effects";
import { setBucketPolicy } from "Redux/buckets/reducer";

export function* getBucketPolicyProcess({ payload }: PayloadAction<string>) {
  try {
    const bucketPolicyOutput: S3.GetBucketPolicyOutput = yield call([s3Client, s3Client.getBucketPolicy], payload);
    yield put(setBucketPolicy(bucketPolicyOutput.Policy));
  } catch (err) {
    yield put(setBucketPolicy(undefined));
    console.error(err);
  }
}
