import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { parseISO } from "date-fns";

type BaseRequestParams = {
  url: string;
  params?: AxiosRequestConfig;
  data?: unknown;
};

export const filterNullable = <T>(items: T[]) => {
  return items.filter((x): x is Exclude<T, null | undefined> => x !== undefined && x !== null);
};

const handleDatesInterceptor = () => {
  const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

  const isIsoDateString = (value: any): boolean => {
    return value && typeof value === "string" && isoDateFormat.test(value);
  };

  const handleDates = (body: any) => {
    if (body === null || body === undefined || typeof body !== "object") return body;

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (isIsoDateString(value)) body[key] = parseISO(value);
      else if (typeof value === "object") handleDates(value);
    }
  };

  return (rep: AxiosResponse) => {
    handleDates(rep);
    return rep;
  };
};

type Primitive = string | number | boolean | undefined | null;

export type BaseParams = {
  [key: string]: Primitive | Primitive[];
};

export const httpFormatDate = (date?: Date) => date?.toISOString() || "";

export class BaseRestClient {
  axiosInstance: AxiosInstance;
  isBearerAuth = false;
  getToken?: () => string | null;

  constructor(config: { axiosConfig: AxiosRequestConfig; getToken?: () => string | null; isBearerAuth?: boolean }) {
    const { axiosConfig, getToken, isBearerAuth = true } = config;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf8",
    };
    axiosConfig.headers = { ...headers, ...axiosConfig.headers };

    const client = axios.create(axiosConfig);
    client.interceptors.response.use(handleDatesInterceptor());

    this.axiosInstance = client;
    this.isBearerAuth = isBearerAuth;
    this.getToken = getToken;
    this.apiCall = this.apiCall.bind(this);
  }

  updateAuthToken() {
    let token = this.getToken?.();
    token = this.isBearerAuth ? `Bearer ${token}` : token;
    this.axiosInstance.defaults.headers.common["Authorization"] = token;
  }

  async apiCall<T>(params: AxiosRequestConfig): Promise<T> {
    this.updateAuthToken();
    const response = await this.axiosInstance(params);
    return response.data;
  }

  async apiGet<T>({ url, params }: BaseRequestParams): Promise<T> {
    return this.apiCall({ ...params, url, method: "GET" });
  }

  async apiPost<T>({ url, params, data }: BaseRequestParams): Promise<T> {
    return this.apiCall({ ...params, url, method: "POST", data });
  }

  async apiPut<T>({ url, params, data }: BaseRequestParams): Promise<T> {
    return this.apiCall({ ...params, url, method: "PUT", data });
  }
  async apiDelete<T>({ url, params, data }: BaseRequestParams): Promise<T> {
    return this.apiCall({ ...params, url, method: "DELETE", data });
  }
  async apiMultiPart<T, U extends FormData>({
    url,
    params,
    data,
  }: Omit<BaseRequestParams, "data"> & { data: U }): Promise<T> {
    return this.apiCall({ ...params, url, method: "POST", data });
  }
}

export const withParams = (patch: string, params: BaseParams) => {
  const args = new URLSearchParams();
  for (let [key, val] of Object.entries(params)) {
    if (val === undefined || val === null) continue;

    if (Array.isArray(val)) {
      val = filterNullable(val)
        .map((x) => x.toString())
        .filter((x) => x.length > 0)
        .join(",");
    }

    val = val.toString();
    if (val.length === 0) continue;

    args.set(key, val);
  }

  const search = args.toString();
  return search.length > 0 ? `${patch}?${search}` : patch;
};
