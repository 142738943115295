import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { GetResourcesSharedWithMeQuery, ResourcesSharedWithMe } from "../../../models/ShareList";
import { setResourcesSharedWithMe } from "../reducer";

export function* getListResourcesSharedWithMeProcess({ payload }: PayloadAction<GetResourcesSharedWithMeQuery>) {
  try {
    const list: ResourcesSharedWithMe = yield call([s3Client, s3Client.getSharedWithMe], payload);
    yield put(setResourcesSharedWithMe(list));
  } catch (err) {
    yield handleSagaError(err);
  }
}
