import clsx from "clsx";
import { DisabledBucketVersioning } from "components/UI/Modal/DisabledBucketVersioning/DisabledBucketVersioning";
import { AppConfig } from "config";
import { TrialBadge } from "containers/BillingPage/partials/TrialBadge/TrialBadge";
import { AddKeyframes, Bin, HomeSimple, KeyAltBack, QuestionMarkCircle } from "iconoir-react";
import { SettingsSvg } from "icons/sidebarIcons";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearCommonErrors, selectErrors } from "Redux/ErrorReducer/ErrorReducer";
import { selectDisabledBucketVersioningModal, selectSuspendedModal } from "Redux/ui/selectors";
import { selectUserStatus } from "Redux/user/Selectors/selectUserStatus";
import { UserStatusEnum } from "shared/src/models/UserModel";
import { isFull, usePrevious } from "../../helpers/common";
import { selectUserData } from "../../Redux/user/Selectors/selectUserData";
import { Header } from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import { ISidebarBlock } from "../Sidebar/types";
import { CommonErrorModal } from "../UI/Modal/CommonErrorModal/CommonErrorModal";
import { SuspendedModal } from "../UI/Modal/SuspendedModal/SuspendedModal";
import style from "./layout.module.scss";

const IS_DEV = !AppConfig.isProd;

interface IProps {
  children: React.ReactNode;
  isAuthorized: boolean;
}
const withoutLayoutPages = ["/dashboard/email-verify", "/dashboard/suspend-payment-result"];

const Layout: React.FC<IProps> = ({ children, isAuthorized }) => {
  const [collapsedDesktop, setCollapsedDesktop] = useState(false);
  const [collapsedMobile, setCollapsedMobile] = useState(true);
  const navigate = useNavigate();
  const [width, setWidth] = useState(320);
  const [modalErr, setModalErr] = useState(false);
  const accountSuspended = useSelector(selectSuspendedModal);
  const disabledBucketVersioningModal = useSelector(selectDisabledBucketVersioningModal);
  const errors = useSelector(selectErrors);
  const prevErrors = usePrevious(errors);
  const userStatus = useSelector(selectUserStatus);
  const userData = useSelector(selectUserData);
  const isCollapsed = useMemo(
    () => (width >= 1440 ? collapsedDesktop : collapsedMobile),
    [width, collapsedDesktop, collapsedMobile]
  );
  const { pathname } = useLocation();
  const { i18n, t } = useTranslation();

  const menuItems = useMemo(() => {
    const data = [
      {
        title: t("Overview"),
        items: [
          {
            text: t("Dashboard"),
            link: "/dashboard/",
            icon: <HomeSimple />,
            exactMatch: true,
          },
          // {
          //   text: "UI page",
          //   link: "/dashboard/uipage",
          //   icon: <SunIcon />,
          //   exactMatch: true,
          //   devOnly: true,
          // },
        ],
      },
      {
        title: t("Data Access"),
        items: [
          {
            text: t("Buckets"),
            link: "/dashboard/buckets",
            icon: <Bin />,
          },
          {
            text: t("Shared"),
            link: "/dashboard/shared",
            icon: <AddKeyframes />,
          },
          {
            text: t("Access Keys"),
            link: "/dashboard/accesskeys",
            icon: <KeyAltBack />,
          },
        ],
      },
      {
        title: t("Account"),
        items: [
          {
            text: t("Settings"),
            link: "/dashboard/settings",
            icon: <SettingsSvg />,
          },
          // {
          //   text: t("Billing"),
          //   link: "/dashboard/billing",
          //   icon: <Wallet />,
          // },
          {
            text: t("Support"),
            link: "/dashboard/support",
            icon: <QuestionMarkCircle />,
          },
        ],
      },
    ] as ISidebarBlock[];

    const notPartnerClient = data.map((x) => ({
      ...x,
      items: x.items.filter((i) => (userData.partner_id ? i.text !== "Billing" : true)),
    }));

    return IS_DEV
      ? notPartnerClient
      : notPartnerClient.map((x) => ({ ...x, items: x.items.filter((i) => !i.devOnly) }));
  }, [i18n.language]);

  const onClickMenu = () => {
    if (width >= 1440) {
      return setCollapsedDesktop((prev) => !prev);
    }
    setCollapsedMobile((prev) => !prev);
  };
  const collapseMobileClickItem = () => setCollapsedMobile(true);
  const dispatch = useDispatch();
  const onCloseError = () => {
    setModalErr(false);
    dispatch(clearCommonErrors());
  };
  const location = useLocation();

  useEffect(() => {
    if (userStatus === UserStatusEnum.blocked) {
      navigate("/dashboard/blocked", { replace: true });
    }
  }, [userStatus, location.pathname]);

  useEffect(() => {
    if (document) {
      location.pathname.includes("/dashboard/support")
        ? document.querySelector("#hs-script-loader")?.classList.remove("tidioHidden")
        : document.querySelector("#hs-script-loader")?.classList.add("tidioHidden");
    }
  }, [location]);

  useEffect(() => {
    if (prevErrors !== errors && isFull(errors) && !modalErr) {
      setModalErr(true);
    }
  }, [prevErrors, errors, modalErr]);

  useEffect(() => {
    if (modalErr) {
      document.body.classList.add("hiddenError");
    }

    if (!modalErr) {
      document.body.classList.remove("hiddenError");
    }
  }, [modalErr]);
  useEffect(() => {
    const resizeHandler = () => {
      const width = window.innerWidth;
      setWidth(width);
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const icludeLayout = () => {
    const _pathname = pathname as string;
    const basePath = ["", "/"];
    const routeKeys = withoutLayoutPages.filter((x) => basePath.every((a) => x !== a));
    const match = routeKeys.find((x) => _pathname.indexOf(x) !== -1);
    return !!match;
  };

  const layoutLoggedIn = (
    <div className={style.layout}>
      <Helmet>
        <title>Petabox: Dashboard </title>
        <meta name="description" content="Petabox: Dashboard" />
      </Helmet>
      <div
        className={`${style.sidebarWrap} ${collapsedDesktop ? style.sidebarWrapCollapsed : ""}  ${
          collapsedMobile ? style.sidebarWrapCollapsedMobile : ""
        } ${style.sidebarWrapCollapsedTablet}`}
      >
        <Sidebar
          collapseMobileClickItem={collapseMobileClickItem}
          data={menuItems}
          isCollapsedDesktop={collapsedDesktop}
          isCollapsedMobile={collapsedMobile}
          closeMobile={() => setCollapsedMobile(true)}
        />
      </div>
      <div
        id="right-part"
        className={clsx(style.rightWrap, collapsedDesktop && style.rightWrapCollapsed, style.rightWrapCollapsedTablet)}
      >
        <TrialBadge />
        <Header onClickMenu={onClickMenu} isCollapsed={isCollapsed} />
        <div className={style.rightPart}>
          <div className={style.content}>{children}</div>
        </div>
      </div>
    </div>
  );

  if (!isAuthorized) {
    return <>{children}</>;
  }

  return (
    <div>
      {icludeLayout() ? <>{children}</> : layoutLoggedIn}
      <CommonErrorModal visible={modalErr} errors={errors} onClose={onCloseError} />
      <SuspendedModal visible={accountSuspended} />
      <DisabledBucketVersioning visible={disabledBucketVersioningModal} />
    </div>
  );
};

export default Layout;
