import { PayloadAction } from "@reduxjs/toolkit";
import { ApiClient } from "helpers/ApiClient";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { StripeCard } from "shared/src/models/StripeCard";
import { SetDefaultPaymentMethodPayload } from "../Actions/userActions";
import { setPaymetMethod } from "../reducer";

export function* setDefaultPaymentMethodSaga({ payload }: PayloadAction<SetDefaultPaymentMethodPayload>) {
  try {
    const cards: StripeCard[] = yield call(ApiClient.setDefaultStripeCard, payload.id);
    yield put(setPaymetMethod(cards));
  } catch (err) {
    console.error(err);
    yield handleSagaError(err);
  }
}
