import clsx from "clsx";
import { addDays, differenceInCalendarDays } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTariffUserPlan } from "Redux/account/Selectors/selectTariffUserPlan";
import { selectUserProfile } from "Redux/user/Selectors/selectUserState";
import { SafeRenderTsc } from "shared/src/components/SafeRenderTsc";
import { GetTariffUserPlanTO } from "shared/src/models/TariffUserPlan";
import { UserStatusEnum } from "shared/src/models/UserModel";
import styles from "./TrialBadge.module.scss";

const BackgroundMap: Record<UserStatusEnum, string> = {
  [UserStatusEnum.active]: styles.active,
  [UserStatusEnum.trial]: styles.active,
  [UserStatusEnum.blocked]: styles.end,
  [UserStatusEnum.deleted]: styles.end,
  [UserStatusEnum.suspended]: styles.end,
  [UserStatusEnum.unsubscribed]: styles.end,
  [UserStatusEnum.payment_required]: styles.end,
  [UserStatusEnum.expired]: styles.willExpire,
};

type BadgeTextProps = {
  status: UserStatusEnum;
  userPlan: GetTariffUserPlanTO;
};

const DefaultText: React.FC<BadgeTextProps> = (props) => {
  const { status, userPlan } = props;
  const now = new Date();
  const endAt = userPlan.end_at;
  const diff = Math.abs(differenceInCalendarDays(endAt, now));
  const daysLeft = Math.abs(diff >= 0 ? diff : 0);
  const expiredDate = addDays(endAt, 10);
  const diffExpiredDateAndNow = differenceInCalendarDays(expiredDate, now);
  const leftToExpired = diffExpiredDateAndNow >= 0 ? diffExpiredDateAndNow : 0;
  const { t } = useTranslation();

  return (
    <div className={styles.textBlock}>
      {status === UserStatusEnum.trial && (
        <>
          <span>{userPlan.tariff.name}</span>{" "}
          <span className={styles.bold}>
            {t("Trial")} {daysLeft} {t("day", { count: daysLeft })}
          </span>
        </>
      )}
      {status === UserStatusEnum.payment_required && (
        <span className={styles.bold}>{t("Please Pay your invoice")}</span>
      )}
      {status === UserStatusEnum.expired && (
        <span className={styles.bold}>
          {t("Your account will be expired after")} {diff} {t("days", { count: leftToExpired })}
        </span>
      )}
      {status === UserStatusEnum.suspended && <span className={styles.bold}>Your account is Suspended</span>}
      {status === UserStatusEnum.unsubscribed && (
        <span className={styles.bold}>
          {t("Your account will be blocked at")} {daysLeft} {t("day", { count: daysLeft })}
        </span>
      )}
    </div>
  );
};

export const TrialBadge: React.FC = () => {
  const userPlan = useSelector(selectTariffUserPlan);
  const userProfile = useSelector(selectUserProfile);
  const now = new Date();
  const endAt = userPlan?.end_at;
  const status = defineExpiredTariff(now, endAt, userProfile);

  function defineExpiredTariff(now, endAt, userProfile) {
    if (userProfile.status === UserStatusEnum.active && differenceInCalendarDays(endAt, now) <= 7) {
      return UserStatusEnum.expired;
    } else {
      return userProfile.status;
    }
  }

  if (status === UserStatusEnum.active) return null;

  return (
    <SafeRenderTsc value={userPlan}>
      {(userPlan) => (
        <div className={clsx(styles.badge, BackgroundMap[status])}>
          <DefaultText status={status} userPlan={userPlan} />
        </div>
      )}
    </SafeRenderTsc>
  );
};
