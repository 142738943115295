import { DeleteCircledOutline } from "iconoir-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/Button";
import LinkComponent from "../../Link/Link";
import { CoreModal } from "../Modal";
import style from "./commonErrorModal.module.scss";

type ModalErrObjectType = {
  message: string;
  isBottomText?: boolean;
};
type ModalErrStringType = string;
type ModalErr = ModalErrStringType | ModalErrObjectType;
type CommonErrorModalProps = {
  visible: boolean;
  onClose: () => void;
  errors?: ModalErr[];
};

const getObjectsErrType = (errors?: ModalErr[]) => {
  if (!errors) return [];
  return errors.reduce((errs, err) => {
    return typeof err === "object" ? [...errs, err] : [...errs, { message: err }];
  }, [] as ModalErrObjectType[]);
};

export const CommonErrorModal: React.FC<CommonErrorModalProps> = ({ visible = false, onClose, errors }) => {
  const navigate = useNavigate();
  const objErrors = getObjectsErrType(errors);
  const { t } = useTranslation();

  return (
    <CoreModal className={style.wrap} closeOutClick={true} onClose={onClose} visible={visible} titleMargin="10px">
      <div>
        <div className={style.icon}>
          <DeleteCircledOutline strokeWidth={1} width={40} height={40} color="#FE5F55" />
        </div>
        <div className={style.errors}>
          {objErrors.map((err, idx) => (
            <div key={idx} className={style.errorBlock}>
              {t(err?.message)}
            </div>
          ))}
        </div>
        {objErrors.find((err) => err.isBottomText) && (
          <div className={style.bottomErrors}>
            {t("Try again. If the problem persists, create a")}{" "}
            <LinkComponent
              onClickHandler={() => {
                navigate("/dashboard/support");
                onClose();
              }}
            >
              {t("ticket")}
            </LinkComponent>
          </div>
        )}
        <Button color="secondary" size="buttonlarge" onClick={onClose}>
          Ok
        </Button>
      </div>
    </CoreModal>
  );
};
