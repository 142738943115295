import { createSelector } from "@reduxjs/toolkit";
import { selectAccountState } from "./selectAccountState";

export const selectUserUsage = createSelector([selectAccountState], (accountState) => accountState.userUsage);
export const selectStorageUsage = createSelector([selectAccountState], ({ userUsage }) => {
  if (!userUsage) {
    return { storageUsage: 0, bandwidthUsage: 0 };
  }
  return { storageUsage: Number(userUsage.storageUsage.size), bandwidthUsage: Number(userUsage.bandwidthUsage.size) };
});
