import clsx from "clsx";
import React from "react";
import styles from "./Heading.module.scss";

type ClassNameProp = {
  className?: string;
};

type HeadingProps = {
  variant: "h1" | "h2" | "h3" | "h4";
} & ClassNameProp;

const ClassNamesMap = {
  h1: styles.h1,
  h2: styles.h2,
  h3: styles.h3,
  h4: styles.h4,
};

export const Heading: React.FC<HeadingProps> = ({ variant = "h2", className, children }) => {
  const HeadingTag = `${variant}` as unknown as React.FC<HeadingProps>;
  const headingClass = ClassNamesMap[variant];
  return <HeadingTag variant={variant} className={clsx(className, headingClass)} children={children} />;
};
