import { useDispatch, useSelector } from "react-redux";
import { setUpload } from "Redux/buckets/reducer";
import style from "./uploadMini.module.scss";
import { formatBytes } from "../../helpers/common";
import { EmptyPage, Enlarge } from "iconoir-react";
import { selectTotalUpload, selectUploadStateInfo } from "Redux/buckets/Selectors/selectUploadInfo";

export const UploadMini = () => {
  const { filesNumber, loadedFilesNumber, filesTotalSize } = useSelector(selectUploadStateInfo);

  const totalPercent = useSelector(selectTotalUpload);

  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setUpload({ isModalUpload: true }));
  };

  return (
    <div className={style.containerUpload}>
      <div className={style.topPart}>
        <div className={style.topLeft}>
          <div className={style.topText}>{`Uploading ${filesNumber} ${filesNumber === 1 ? "file" : "files"}`}</div>
        </div>
        <div className={style.topRight}>
          <div className={style.percentageBlock}>{totalPercent}% </div>
          <div className={style.iconBlock} onClick={onClick}>
            <Enlarge color={"#ffffff"} width={24} height={24} />
          </div>
        </div>
      </div>
      <div className={style.bottomPart}>
        <div className={style.bottomRow}>
          <div className={style.bottomLeft}>
            <div className={style.iconFile}>
              <EmptyPage width={24} height={24} color="#D4D7E0" />
            </div>
            <div className={style.filesLeft}>{`${loadedFilesNumber || 0}/${filesNumber || 0} files`}</div>
          </div>
          <div className={style.bottomRight}>{filesTotalSize ? formatBytes(filesTotalSize) : "0 Gb"}</div>
        </div>
        <div className={style.progress}>
          <div className={style.innerProgress} style={{ width: `${totalPercent || 0}%` }} />
        </div>
      </div>
    </div>
  );
};
