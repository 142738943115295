import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { FileMetadata } from "models/Bucket";
import { call, put } from "redux-saga/effects";
import { getFileInfo } from "../Actions/bucketsActions";

export function* metadataFileProcess({ payload }: PayloadAction<FileMetadata>) {
  const { nameBucket, nameFile, CopySource, Metadata, pathFolder, versionId, contentType } = payload;
  try {
    yield call([s3Client, s3Client.metadataFileFunc], {
      nameBucket,
      nameFile,
      pathFolder,
      CopySource,
      Metadata,
      versionId,
      contentType,
    });
    yield put(
      getFileInfo({
        nameFile,
        nameBucket,
        pathFolder,
        versionId,
        afterLoad: () => {},
      })
    );
  } catch (err) {}
}
