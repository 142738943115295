import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { BucketVersioningStatus } from "aws-sdk/clients/s3";
import { call, put } from "redux-saga/effects";
import { setCurrentBucketVersioningStatus } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getBucketVersioningStatusProcess({ payload }: PayloadAction<string>) {
  try {
    const status: BucketVersioningStatus | undefined = yield call([s3Client, s3Client.checkBucketVersioning], payload);
    yield put(setCurrentBucketVersioningStatus(status));
  } catch (err) {
    yield handleSagaError(err);
  }
}
