import { Versions } from "models/FileVersion";
import { Region } from "models/S3Regions";
import { EmptyPage, Folder, MediaImage, MediaVideo, Music1 } from "iconoir-react";

export enum TBucketFiles {
  folder = "folder",
  image = "image",
  file = "file",
  video = "video",
  audio = "audio",
}

export type VersioningBucketStatus = "Disabled" | "Enabled" | "Suspended";

export const iconsFiles = {
  [TBucketFiles.folder]: <Folder color="#41A8ED" width={24} height={24} />,
  [TBucketFiles.image]: <MediaImage color="#6E7E9D" width={24} height={24} />,
  [TBucketFiles.file]: <EmptyPage color="#6E7E9D" width={24} height={24} />,
  [TBucketFiles.video]: <MediaVideo color="#6E7E9D" width={24} height={24} />,
  [TBucketFiles.audio]: <Music1 color="#6E7E9D" width={24} height={24} />,
};

export interface IBucketListItem {
  CreationDate: any;
  Name: string;
  Region: Region;
  ACL: "public-read" | "private";
  ObjectsWithoutFoldersCount: string;
  VersioningStatus: VersioningBucketStatus;
  PreviousVersions?: Versions;
}
