import { nanoid } from "nanoid";
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TStore } from "Redux/rootReducer";
import { selectIsLoadingAsyncButton } from "Redux/ui/selectAsyncButtonState";
import { removeLoadingAsyncButton, setLoadingAsyncButton } from "Redux/ui/uiState";
import { PbButton, PbButtonProps } from "shared/src/components/PbButton/PbButton";

export type AsyncSagaButtonMethods = { clearState: () => void };
type AsyncSagaButtonProps = {
  endActionType: string;
  dataTest?: string;
  children?: React.ReactNode;
} & PbButtonProps;
export const AsyncSagaButton = forwardRef<AsyncSagaButtonMethods, AsyncSagaButtonProps>(
  ({ onClick, endActionType, ...props }, ref) => {
    const buttonId = useRef(nanoid()).current;
    const isLoading = useSelector<TStore, boolean>((state) => selectIsLoadingAsyncButton(state, buttonId));

    const dispatch = useDispatch();
    const clearState = useCallback(() => dispatch(removeLoadingAsyncButton(endActionType)), []);

    useImperativeHandle(ref, () => ({ clearState }));

    const handleClick = () => {
      dispatch(setLoadingAsyncButton({ buttonId, endActionType }));
      onClick?.();
    };

    return <PbButton onClick={handleClick} {...props} loading={isLoading} disabled={isLoading || props.disabled} />;
  }
);
