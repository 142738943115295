import { TestAvatar } from "components/Avatar/Avatar";
import { AsyncSagaButton, AsyncSagaButtonMethods } from "components/UI/AsyncSagaButton/AsyncSagaButton";
import { ProgressBar } from "components/UI/ProgressBar/ProgressBar";
import { StyledLink, StyledLinkSize } from "components/UI/StyledLink/StyledLink";
import { TB, TB_10, TB_50 } from "helpers/contants";
import { AddCircledOutline, FastArrowDown, Restart, Trash } from "iconoir-react";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getStorageClassesInfo } from "Redux/account/Actions/accountActions";
import { setStorageClassesInfo } from "Redux/buckets/reducer";
import Checkbox from "shared/src/components/Checkbox/Checkbox";
import { TestTable } from "shared/src/components/CommonTable/CommonTable";
import { Heading } from "shared/src/components/Heading/Heading";
import { PbButton, PbButtonSize } from "shared/src/components/PbButton/PbButton";
import styles from "./UIpage.module.scss";

export const UIpage = () => {
  const [loading, setLoding] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [size, setSize] = useState<PbButtonSize>("md");
  const [range, setRange] = useState(0);
  const [linkSize, setLinkSize] = useState<StyledLinkSize>("md");
  const [trialLimit, setTrialLimit] = useState(false);
  const [limit, setLimit] = useState(TB);

  const asyncBtnRef = useRef<AsyncSagaButtonMethods>(null);
  const onClick = () => asyncBtnRef?.current?.clearState();

  const dispatch = useDispatch();

  const triggerSagaCall = () => {
    dispatch(getStorageClassesInfo());
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageRow}>
        <TestAvatar />
      </div>
      <div className={styles.pageRow}>
        <TestTable />
      </div>
      <div className={styles.pageRow}>
        <Heading variant="h2">Heading level 1</Heading>
        <Heading variant="h2">Heading level 2</Heading>
        <Heading variant="h3">Heading level 3</Heading>
        <Heading variant="h4">Heading level 4</Heading>
      </div>
      {/* Buttons */}
      <div className={styles.flexCol}>
        <Heading variant="h2">Buttons</Heading>
        <div className={styles.pageRow}>
          <Checkbox onChange={() => setLoding((x) => !x)} id={1} label="loading" name="1" checked={loading} />
          <Checkbox onChange={() => setDisabled((x) => !x)} id={1} label="disabled" name="2" checked={disabled} />
          <select value={size} onChange={({ target }) => setSize(target.value as PbButtonSize)}>
            <option value="md">md</option>
            <option value="lg">lg</option>
            <option value="sm">sm</option>
          </select>
        </div>
        <div className={styles.pageRow}>
          <PbButton loading={loading} disabled={disabled} size={size}>
            Primary
          </PbButton>
          <PbButton loading={loading} disabled={disabled} size={size} color="success">
            Success
          </PbButton>
          <PbButton loading={loading} disabled={disabled} size={size} color="danger">
            Danger
          </PbButton>
          <PbButton loading={loading} disabled={disabled} size={size} color="secondary">
            Secondary
          </PbButton>
          <PbButton loading={loading} disabled={disabled} size={size} color="secondary-bordered">
            Secondary Bordered
          </PbButton>

          <PbButton
            icon={<AddCircledOutline />}
            loading={loading}
            disabled={disabled}
            size={size}
            color="secondary-bordered"
          >
            Button Icon
          </PbButton>

          <AsyncSagaButton endActionType={setStorageClassesInfo.type} onClick={triggerSagaCall}>
            async 1
          </AsyncSagaButton>
          <AsyncSagaButton ref={asyncBtnRef} endActionType="END_SAGA_2">
            async 2
          </AsyncSagaButton>

          <PbButton onClick={onClick}>clear state 2 async button</PbButton>
        </div>

        <div className={styles.pageRow}>
          <div style={{ minWidth: "300px" }} className={styles.flexCol}>
            <Heading variant="h2">Progress bar</Heading>
            <input
              type="range"
              min="0"
              max={3 * limit}
              value={range}
              onChange={(v) => setRange(parseInt(v.target.value, 10))}
            />

            <div className={styles.pageRow}>
              <div className={styles.input}>
                <Checkbox
                  onChange={() => setTrialLimit((x) => !x)}
                  id={1}
                  label="Enable Trial"
                  name="trial"
                  checked={trialLimit}
                />
              </div>
              <div className={styles.input}>
                <span>limit / TB</span>
                <select value={limit} onChange={({ target }) => setLimit(Number(target.value))}>
                  <option value={TB}>1 TB</option>
                  <option value={TB_10}>10 TB</option>
                  <option value={TB_50}>50 TB</option>
                </select>
              </div>
            </div>

            <ProgressBar label="Storage" limit={trialLimit ? TB : limit} value={range} />
          </div>
        </div>
      </div>
      {/* Links */}
      <div className={styles.flexCol}>
        <Heading variant="h2">Links</Heading>
        <select
          style={{ width: "max-content" }}
          value={linkSize}
          onChange={({ target }) => setLinkSize(target.value as StyledLinkSize)}
        >
          <option value="md">md</option>
          <option value="lg">lg</option>
        </select>
        <div className={styles.pageRow}>
          <StyledLink size={linkSize}>Styled Link</StyledLink>
          <StyledLink icon size={linkSize}>
            Styled Link Arrow
          </StyledLink>
        </div>
      </div>

      <div className={styles.flexCol}>
        <Heading variant="h2">Links</Heading>
        <select
          style={{ width: "max-content" }}
          value={linkSize}
          onChange={({ target }) => setLinkSize(target.value as StyledLinkSize)}
        >
          <option value="md">md</option>
          <option value="lg">lg</option>
        </select>
        <div className={styles.pageRow}>
          <StyledLink size={linkSize}>Styled Link</StyledLink>
          <StyledLink icon size={linkSize}>
            Styled Link Arrow
          </StyledLink>
        </div>
      </div>
      {/* ICON NOIR EXAMPLE */}
      <div className={styles.flexCol}>
        <Heading variant="h2">Icon Noir Example</Heading>
        <div>
          <StyledLink blankLink to="https://iconoir.com/" type="native">
            Show All Icons
          </StyledLink>
        </div>
        <div className={styles.pageRow}>
          <AddCircledOutline />
          <FastArrowDown />
          <Restart />
          <Trash />
        </div>
      </div>
    </div>
  );
};
