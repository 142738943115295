import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { Policy } from "aws-sdk/clients/s3";
import { call, put } from "redux-saga/effects";
import { setBucketPolicy } from "Redux/buckets/reducer";
import { handleSagaError } from "../../helpers";
import { openSuccessModal } from "../../ui/uiState";

export function* putBucketPolicyProcess({ payload }: PayloadAction<{ bucketName: string; policy: Policy }>) {
  try {
    yield call([s3Client, s3Client.putBucketPolicy], payload.bucketName, payload.policy);
    yield put(setBucketPolicy(payload.policy));
    yield put(
      openSuccessModal({
        isOpen: true,
        text: "The changes have been successfully saved",
      })
    );
  } catch (err) {
    yield handleSagaError(err);
  }
}
