import { ArrowRight } from "iconoir-react";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import style from "./dashboardCard.module.scss";

export interface IDashboardCard {
  title: string;
  number: number | string;
  numberPostfix?: string;
  link?: string;
  setCardClick?: (item: number) => void;
  tabs?: number;
  icon: ReactNode | null;
  color?: string;
}

const DashboardCard: React.FC<IDashboardCard> = ({
  title,
  number,
  numberPostfix,
  link,
  setCardClick,
  tabs,
  icon,
  color = "#a1d3f7",
}: IDashboardCard) => {
  const isInnerAppLink = link && link.charAt(0) === "/";
  return (
    <div className={style.container}>
      <div className={style.inner}>
        <div className={style.titleCardRow}>
          <div className={style.title}>{title}</div>
          {link &&
            (isInnerAppLink ? (
              <Link to={link}>
                <ArrowRight color="#6E7E9D" height={24} width={24} />
              </Link>
            ) : (
              <a href={link} onClick={() => tabs && setCardClick?.(tabs)}>
                <ArrowRight color="#6E7E9D" height={24} width={24} />
              </a>
            ))}
        </div>
        <div className={style.bottomRow}>
          <div className={style.number}>
            <div className={style.numberValue}>{number}</div>
            {numberPostfix && <div className={style.numberPostfix}>{numberPostfix}</div>}
          </div>
          <div className={style.arrow}>{icon}</div>
        </div>
      </div>
      <div style={{ background: color }} className={style.bottomLine} />
    </div>
  );
};

export default DashboardCard;
