import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import style from "./sidebar.module.scss";

interface IProps {
  text: string;
  link: string;
  icon: React.ReactNode;
  isCollapsed: boolean;
  exactMatch?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const SidebarItem: React.FC<IProps> = ({ text, link, icon, isCollapsed, exactMatch, onClick }) => {
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        clsx(style.item, style.itemCollapsedTablet, isCollapsed && style.itemCollapsed, isActive && style.item_active)
      }
      onClick={(e) => onClick?.(e)}
    >
      <div className={style.icon}>{icon}</div>
      <div className={style.itemText}>{text}</div>
    </NavLink>
  );
};

export default SidebarItem;
