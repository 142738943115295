import clsx from "clsx";
import { getFilesFromDataTransferItems } from "datatransfer-files-promise";
import { Upload } from "iconoir-react";
import { UploadFileType } from "models/UploadFileType";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatBytes } from "../../../helpers/common";
import { cutoutString } from "../../../helpers/cutoutString";
import { truncateString } from "../../../helpers/truncateString";
import { SvgCloseRound } from "../../../icons/Close";
import SvgUploadFile from "../../../icons/UploadFile";
import { zero } from "../../../models/DragEndDrop";
import { iconsFiles, TBucketFiles } from "../../Table/types";
import Button from "../Button/Button";
import { InputFile } from "../InputFile/InputFile";
import style from "./dragAndDrop.module.scss";

type DragAndDropProps = {
  onUploadFiles: (files: UploadFileType[]) => void;
  disabled: boolean;
  typePage?: string;
  size?: string;
};

export const DragAndDrop: React.FC<DragAndDropProps> = ({ onUploadFiles, size, disabled, typePage = "backet" }) => {
  const [drag, setDrag] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const { t } = useTranslation();

  const handleDrag = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (disabled) return false;
    setDragCounter((prev) => prev + 1);
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (disabled) return false;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };

  const onDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return false;
    setDrag(false);
    const items = e.dataTransfer.items;
    const files = await getFilesFromDataTransferItems(items);
    onUploadFiles(files as UploadFileType[]);
  };
  const onInputChangeHandler = (files: FileList) => {
    if (disabled) return false;
    const arr = Array.from(files);
    const filesCopy: UploadFileType[] = arr.map((el) => {
      return Object.assign(el, { filepath: el.name });
    });
    onUploadFiles(filesCopy);
  };

  return (
    <>
      {typePage === "backet" && (
        <div
          className={clsx(style.container, drag && style.active)}
          onDragEnter={handleDragIn}
          onDragOver={handleDrag}
          onDrop={onDrop}
          onDragLeave={() => {
            if (dragCounter - 1 === 0) {
              setDrag(false);
            }
            setDragCounter((prev) => prev - 1);
          }}
        >
          <div className={style.content}>
            <div className={style.icon}>
              <SvgUploadFile />
            </div>
            <div className={clsx(style.buttonContainer, disabled && style.buttonContainerDisabled)}>
              <div className={style.text}>Drag and drop files, or&nbsp;</div>
              <InputFile id="files" onInputChangeHandler={onInputChangeHandler}>
                <Button size="link">Browse</Button>
              </InputFile>
            </div>
          </div>
        </div>
      )}

      {typePage === "backet" && (
        <div className={style.mobileSizeUploadRow}>
          <div className={style.checkrowWrapper}>
            <div className={style.checkrow}>
              <div className={style.textSize}>Total upload size:&nbsp;</div>
              <div className={style.size}>{(size !== zero.zeroKb && size) || 0}</div>
            </div>
          </div>
          <div className={style.uploadMobileWrapper}>
            <InputFile id="files" onInputChangeHandler={onInputChangeHandler}>
              <Button size="linkIcon">
                <Upload width={24} height={24} color="#41A8ED" />
                Browse
              </Button>
            </InputFile>
          </div>
        </div>
      )}
      {typePage === "support" && (
        <div
          className={clsx(style.container_support, style.active)}
          onDragEnter={handleDragIn}
          onDragOver={handleDrag}
          onDrop={onDrop}
          onDragLeave={() => {
            if (dragCounter - 1 === 0) {
              setDrag(false);
            }
            setDragCounter((prev) => prev - 1);
          }}
        >
          <div className={style.content}>
            <div className={clsx(style.buttonContainer, disabled && style.buttonContainerDisabled)}>
              <InputFile id="files" onInputChangeHandler={onInputChangeHandler}>
                <div className={style.button}>{t("Add an attachment or drop files here")}</div>
              </InputFile>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const formatsFiles = {
  [TBucketFiles.image]: ["jpg", "jpeg", "png", "svg"],
  [TBucketFiles.video]: ["mov", "mp4", "webm", "mpeg", "avi"],
  [TBucketFiles.audio]: ["mp3", "wma"],
};

export const getIconByFile = (name: string) => {
  try {
    if (name?.length === 0) {
      return iconsFiles[TBucketFiles.file];
    }
    const idx = name.lastIndexOf(".");
    const ext = name.slice(idx + 1).toLowerCase();
    if (formatsFiles[TBucketFiles.image].indexOf(ext) >= 0) {
      return iconsFiles[TBucketFiles.image];
    }
    if (formatsFiles[TBucketFiles.video].indexOf(ext) >= 0) {
      return iconsFiles[TBucketFiles.video];
    }
    if (formatsFiles[TBucketFiles.audio].indexOf(ext) >= 0) {
      return iconsFiles[TBucketFiles.audio];
    }
  } catch (err) {
    return iconsFiles[TBucketFiles.file];
  }
  return iconsFiles[TBucketFiles.file];
};

type FileProps = {
  onDelete?: () => void;
  object: { file: UploadFileType; progress?: number };
  noProgress?: boolean;
};

export const File: React.FC<FileProps> = ({ object, onDelete, noProgress = false }) => {
  const icon = getIconByFile(object?.file?.name);

  return (
    <div className={style.wrap}>
      <div className={style.file}>
        <div className={clsx(style.fileRow, style.grow)}>
          <div className={style.fileIcon}>{icon}</div>
          <div className={style.fileName}>{cutoutString(object?.file?.name, 28, 5)}</div>
          <div className={style.fileNameMobile}>{truncateString(object?.file?.name, 15)}</div>
        </div>
        <div className={style.fileRow}>
          <div className={style.fileSize}>
            {object?.progress ? <span className={style.filePercent}>{`${object.progress}%  •  `}</span> : ""}
            {formatBytes(object?.file?.size)}
          </div>
          <div className={style.removeFile} onClick={onDelete}>
            <SvgCloseRound />
          </div>
        </div>
      </div>
      {!noProgress && (
        <div className={style.progress}>
          <div className={style.innerProgress} style={{ width: `${object.progress}%` }} />
        </div>
      )}
    </div>
  );
};
