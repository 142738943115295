import { format, FORMAT_DATE_TEMPLATES } from "helpers/date-fns";
import { notNil } from "helpers/utils";
import { size } from "lodash";
import { Notification } from "models/Notification";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SvgArrowRight } from "../../icons/ArrowsLeftRight";
import style from "./notificationsPanel.module.scss";
import { INotificationsItem } from "./types";

type NotificationPanelProps = {
  dropRef: React.RefObject<HTMLDivElement>;
  items?: Notification[];
  close: () => void;
};

export const NotificationsPanel: React.FC<NotificationPanelProps> = ({ items, dropRef, close }) => {
  const navigate = useNavigate();
  const onClick = useCallback(
    (id?: number) => {
      close();
      navigate(`/dashboard/notifications${id ? "?id=" + id : ""}`);
    },
    [close, navigate]
  );
  const { t } = useTranslation();

  return (
    <div className={style.dropdownPanel} ref={dropRef}>
      {notNil(items) &&
        items.map(({ title, createdAt, readAt, id }) => (
          <NotificationsItem
            key={createdAt as unknown as string}
            text={title}
            onClick={() => onClick(id)}
            isNew={!readAt}
            date={createdAt as unknown as string}
          />
        ))}
      {size(items) ? (
        <div className={style.seeAllContainer} onClick={() => onClick()}>
          <div className={style.seeAllText}>{t("See all notifications")}</div>
          <SvgArrowRight color={"secondary"} />
        </div>
      ) : (
        <div className={style.emptyNotifications}>{t("You Don't Have Any Notifications Yet")}</div>
      )}
    </div>
  );
};

const NotificationsItem = (item: INotificationsItem) => {
  const { date, isNew, text, onClick } = item;
  const { t } = useTranslation();

  return (
    <div onClick={onClick} className={`${style.itemNotification} ${isNew ? style.itemNotificationOpen : ""}`}>
      <div className={style.itemTopRow}>
        <div className={style.itemLeft}>
          <div className={style.itemDate}>{format(date, FORMAT_DATE_TEMPLATES.dmyh)}</div>
          {isNew && (
            <div className={style.itemLabel}>
              <LabelNotification text={t("_new")} />
            </div>
          )}
        </div>
        <div className={style.itemArrow}>
          <SvgArrowRight color={"main"} />
        </div>
      </div>
      <div className={style.itemText}>{text}</div>
    </div>
  );
};

export const LabelNotification = ({ text }) => {
  return <div className={style.labelNotification}>{text}</div>;
};
