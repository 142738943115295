import { PayloadAction } from "@reduxjs/toolkit";
import { ISearchedFilesListData } from "actions/interfaces";
import { ISearchFilesResult } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { setSearchedFilesList } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* searchFiles({ payload }: PayloadAction<ISearchedFilesListData>) {
  try {
    const list: ISearchFilesResult = yield call([s3Client, s3Client.getSearchedFilesList], payload);
    yield put(setSearchedFilesList(list));
  } catch (err) {
    yield handleSagaError(err);
  }
}
