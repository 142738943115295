import { ISearchedFilesListData } from "actions/interfaces";
import { Endpoint, S3 } from "aws-sdk";
import { BucketVersioningStatus, ObjectIdentifierList, ObjectLockRetention, Policy } from "aws-sdk/clients/s3";
import aws4Interceptor from "aws4-axios";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { BucketSelectItem } from "containers/BucketPage/BucketPage";
import { baseUrl, isFolderType, isFull, throwError, uriEncode } from "helpers/common";
import { GetFileAclPayload, PutBucketAclPayload, PutFileAclPayload } from "models/ACL";
import { DeleteFileFolderMultiple } from "models/Bucket";
import { BucketLogging } from "models/BucketLogging";
import { ListVersionsOfSpecificObjectResponse } from "models/FileVersion";
import { PutObjectLegalHold, PutObjectLockConfig } from "models/ObjectLock";
import { S3Regions } from "models/S3Regions";
import {
  BucketSharingList,
  DeleteResourceFromSharedWithYouListParams,
  GetResourcesSharedWithMeQuery,
  KeySharingListRoot,
  PrefixSharingListRoot,
  ResourcesSharedWithMe,
  SharingListType,
} from "models/ShareList";
import { UploadManager } from "Redux/buckets/Sagas/prepareDropedFilesSaga";
import { withParams } from "shared/src/http";
import {
  CreateZipResponse,
  GetFileListPayload,
  ICheckCanDownload,
  ICheckCanUpload,
  IFetchClientApiParams,
  IFileInfo,
  IGetListBuckets,
  IGetListFiles,
  IGetSearchedFilesList,
  PutVersioningBucketReply,
  S3File,
  VersioningBucketStatus,
  ZipStatsResponse,
} from "./s3-client.types";

/**
 * @example
 * addRegionToS3ApiUrl('https://s3.petabox.io', 'eu-west-1') === 'https://s3.eu-west-1.petabox.io'
 * addRegionToS3ApiUrl('http://localhost:3000', 'eu-west-1') === 'http://localhost:3000'
 */
export function addRegionToS3ApiUrl(s3ApiUrl: string, region: string): string {
  // @ts-ignore
  return s3ApiUrl.replace(s3ApiUrl.match(/^https?:\/\/s3\./)[0], s3ApiUrl.match(/^https?:\/\/s3\./)[0] + region + ".");
}

class NotInitializedError extends Error {
  constructor() {
    super("S3Client not initialized");
  }
}

export const DEFAULT_REGION = "eu-central-1";

class S3Client {
  _s3?: S3;
  _clientApi?: AxiosInstance;

  init({
    accessKeyId,
    secretAccessKey,
    region = DEFAULT_REGION,
  }: {
    accessKeyId: string;
    secretAccessKey: string;
    region?: string;
  }) {
    this._s3 = new S3({
      accessKeyId,
      secretAccessKey,
      region,
      signatureVersion: "v4",
      endpoint: addRegionToS3ApiUrl(baseUrl, DEFAULT_REGION),
    });

    const clientApi = axios.create({
      baseURL: addRegionToS3ApiUrl(baseUrl, DEFAULT_REGION),
    });

    clientApi.interceptors.request.use(
      aws4Interceptor(
        {
          region: DEFAULT_REGION,
          service: "s3",
        },
        {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
        }
      )
    );

    this._clientApi = clientApi;
  }
  set s3(instance: AWS.S3) {
    this._s3 = instance;
  }

  get s3(): AWS.S3 {
    if (!this._s3) throw new NotInitializedError();
    return this._s3;
  }

  get clientApi(): AxiosInstance {
    if (!this._clientApi) throw new NotInitializedError();
    return this._clientApi;
  }

  async promise(method: string, codeSuccess = "200", params: IFetchClientApiParams) {
    const { url, query, headers, noFormat, body } = params;

    try {
      let paramsFormed = noFormat ? {} : { format: "json" };
      if (isFull(query)) {
        paramsFormed = { ...paramsFormed, ...query };
      }
      const response = await this.clientApi(url, {
        method: method?.toUpperCase() as any,
        headers: { ...headers },
        params: paramsFormed,
        data: body,
      });

      if (+response.status !== +codeSuccess && +response.status !== 200) {
        throwError({ status: response.status, message: response?.data?.message });
      }
      return response;
    } catch (err) {
      console.error(err);
      this.parseError(err);
    }
  }

  parseError(err: unknown) {
    let error = err as any;

    if (error?.response) {
      console.log(
        "fetchClientApi error.response",
        error.response,
        "error.response?.data?.Error?.Message",
        error.response?.data?.Error?.Message,
        "error.response.status",
        error.response.status
      );
      throwError({
        status: error.response.status,
        message: error.response?.data?.Error?.Message || error?.response?.statusText,
      });
      /* The request was made and the server responded with a status code that falls out of the range of 2xx*/
    } else if (error?.request) {
      /* The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js*/
      throwError({ status: "", message: "No response" });
    } else if (error?.status) {
      // todo1: check for working correctly
      throwError({ status: error?.status, message: error?.message });
    } else {
      // Something happened in setting up the request and triggered an Error
      throwError({ status: "", message: error });
    }
  }

  updateS3Region(region: string) {
    const { clientApi, s3 } = this;
    clientApi.defaults.baseURL = addRegionToS3ApiUrl(baseUrl, region);
    s3.endpoint = new Endpoint(addRegionToS3ApiUrl(baseUrl, region));
    s3.config.endpoint = new Endpoint(addRegionToS3ApiUrl(baseUrl, region));
    s3.config.update({ region: region });
  }

  async getS3egions() {
    let url = `/?regions`;
    const response: AxiosResponse<S3Regions> | undefined = await this.promise("get", "204", {
      url,
    });
    return response?.data;
  }

  async checkBucketRegionAndUpdateClient(nameBucket: string) {
    const region = await this.s3.getBucketLocation({ Bucket: nameBucket }).promise();

    if (region?.LocationConstraint && region?.LocationConstraint !== this.s3.config.region) {
      this.updateS3Region(region.LocationConstraint);
    }
  }

  async getFileVersions(file: S3File) {
    const { nameBucket, nameFile, pathFolder } = file;
    await this.checkBucketRegionAndUpdateClient(nameBucket);

    const nameEncoded = uriEncode(nameFile, false);
    const pathFolderEncoded = pathFolder ? uriEncode(pathFolder, false) : "";
    let key = `${pathFolderEncoded ? pathFolderEncoded : ""}${pathFolderEncoded ? "/" : ""}${nameEncoded}`;
    key = key.replace("//", "/");

    const response: AxiosResponse<ListVersionsOfSpecificObjectResponse> | undefined = await this.promise("get", "200", {
      url: `${nameBucket}/${key}?versions`,
    });

    return response?.data;
  }

  async checkCanUpload() {
    const response: AxiosResponse<ICheckCanUpload> | undefined = await this.promise("get", "200", {
      url: "/?canUpload",
      noFormat: true,
    });
    return response?.data;
  }

  async checkCanDownload() {
    const response: AxiosResponse<ICheckCanDownload> | undefined = await this.promise("get", "200", {
      url: "/?canDownload",
      noFormat: true,
    });
    return response?.data;
  }

  async getSearchedFilesList({ filename, pagination }: ISearchedFilesListData) {
    const response: AxiosResponse<IGetSearchedFilesList> | undefined = await this.promise("get", "200", {
      url: "/?searchFiles",
      query: {
        filename: filename,
        format: "json",
        page: pagination.Page,
        perPage: pagination.PerPage,
      },
    });
    return response?.data?.SearchFilesResult;
  }

  async getListBuckets() {
    const response: AxiosResponse<IGetListBuckets> | undefined = await this.promise("get", "200", {
      url: "/?extendedBuckets",
    });
    return response?.data?.ListAllMyBucketsExtendedResult;
  }

  async getListFiles(options: GetFileListPayload) {
    const { excludeRootFolder = "", nameBucket, pathFolder } = options;
    const { Page, PerPage, allTree, showVersions } = options;
    await this.checkBucketRegionAndUpdateClient(nameBucket);

    let params = {
      format: "json",
      perPage: PerPage,
      page: Page,
    } as any;
    if (!allTree) {
      params.delimiter = "/";
    }
    const prefix = pathFolder ? `&prefix=${uriEncode(pathFolder, false)}/` : "";
    const response: AxiosResponse<IGetListFiles> | undefined = await this.promise("get", "200", {
      url: `/${nameBucket}?extendedObjects${excludeRootFolder}${showVersions ? "&previousVersions=true" : ""}${
        prefix ? prefix : ""
      }`,
      query: params,
    });
    return response?.data;
  }

  async getFileInfoFunc(object: {
    nameFile: string;
    nameBucket: string;
    pathFolder?: string;
    versionId?: string | null;
  }) {
    const { nameFile, nameBucket, pathFolder, versionId } = object;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    const pathFolderEncoded = pathFolder ? uriEncode(pathFolder, false) : "";
    const nameEncoded = uriEncode(nameFile, false);
    const versionQuery = !!versionId ? "&versionId=" + versionId : "";
    let url = `/${nameBucket}/${pathFolderEncoded ? pathFolderEncoded : ""}${
      pathFolderEncoded ? "/" : ""
    }${nameEncoded}?extendedInfo${versionQuery}`;
    url = url.replace("//", "/");
    const response: AxiosResponse<IFileInfo> | undefined = await this.promise("get", "200", {
      url,
    });
    return response?.data?.ObjectExtendedInfo;
  }

  async renameFileFunc(file: { nameBucket: string; name: string; newName: string; pathFolder?: string }) {
    const { nameBucket, name, newName, pathFolder } = file;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    const pathFolderEncoded = pathFolder ? uriEncode(pathFolder, false) : "";
    const nameEncoded = uriEncode(name, false);
    const newNameEncoded = uriEncode(newName, false);

    let url = `/${nameBucket}/${pathFolderEncoded ? pathFolderEncoded : ""}${
      pathFolderEncoded ? "/" : ""
    }${nameEncoded}`;
    url = url.replace("//", "/");

    const response = await this.promise("move", "204", {
      url: url,
      headers: {
        Destination: `${pathFolder ? pathFolderEncoded : ""}${pathFolder ? "/" : ""}${newNameEncoded}`,
      },
    });
    return response;
  }
  async renameFolderFunc(folder: { nameBucket: string; pathFolder: string; name: string; newName: string }) {
    const { nameBucket, pathFolder, name, newName } = folder;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    const pathFolderEncoded = pathFolder ? uriEncode(pathFolder, false) : "";
    const nameEncoded = uriEncode(name, false);
    const newNameEncoded = uriEncode(newName, false);

    let url = `/${nameBucket}/${pathFolderEncoded ? pathFolderEncoded : ""}${
      pathFolderEncoded ? "/" : ""
    }${nameEncoded}/`;
    url = url.replace("//", "/");

    const response = await this.promise("move", "200", {
      url: url,
      headers: {
        Destination: `${pathFolder ? pathFolderEncoded : ""}${pathFolder ? "/" : ""}${newNameEncoded}/`,
        "X-ColdStack-Prefix": "true",
      },
    });

    return response;
  }

  // @todo1: change name & target name props
  async renameBucketFunc(payload: { nameBucket: string; name: string }) {
    const { nameBucket, name } = payload;

    let url = `/${nameBucket}`;
    const response = await this.promise("move", "204", {
      url: url,
      headers: {
        Destination: name,
      },
    });
    return response;
  }

  async putBucketAcl({ nameBucket, grants }: PutBucketAclPayload) {
    const rep = await this.s3
      .putBucketAcl({
        Bucket: nameBucket,
        AccessControlPolicy: {
          Grants: grants,
        },
      })
      .promise();
    const error = rep.$response.error;
    if (error) throw new Error(error.message);
    return rep;
  }

  async getBucketAcl(nameBucket: string) {
    const rep = await this.s3
      .getBucketAcl({
        Bucket: nameBucket,
      })
      .promise();
    const error = rep.$response.error;
    if (error) throw new Error(error.message);
    return rep;
  }

  async getFileAcl({ nameBucket, nameFile }: GetFileAclPayload) {
    const rep = await this.s3
      .getObjectAcl({
        Bucket: nameBucket,
        Key: nameFile,
      })
      .promise();
    const error = rep.$response.error;
    if (error) throw new Error(error.message);
    return rep;
  }

  async getBucketShare(nameBucket: string) {
    let url = `${nameBucket}/?sharingList`;
    const response: AxiosResponse<BucketSharingList> | undefined = await this.promise("get", "204", {
      url,
    });
    return response?.data;
  }

  async getPrefixSharingList(path: string) {
    let url = `${path}/?sharingList&prefix=true`;
    const response: AxiosResponse<PrefixSharingListRoot> | undefined = await this.promise("get", "204", {
      url,
    });
    return response?.data;
  }

  async geKeySharingList(path: string) {
    let url = `${path}?sharingList`;
    const response: AxiosResponse<KeySharingListRoot> | undefined = await this.promise("get", "204", {
      url,
    });
    return response?.data;
  }

  async getSharedWithMe(query: GetResourcesSharedWithMeQuery) {
    const url = withParams("/", { ...query, resourcesSharedWithMe: true });

    const response: AxiosResponse<ResourcesSharedWithMe> | undefined = await this.promise("get", "204", {
      url,
    });
    return response?.data;
  }

  async deleteResourceFromSharedWithYouList(query: DeleteResourceFromSharedWithYouListParams) {
    const url = withParams("/", { ...query, resourcesSharedWithMe: true });

    const response: AxiosResponse<ResourcesSharedWithMe> | undefined = await this.promise("delete", "204", {
      url,
    });
    return response?.data;
  }

  async putSharingList(
    path: string,
    sharingList: BucketSharingList | PrefixSharingListRoot | KeySharingListRoot,
    type: SharingListType
  ) {
    let url = "";
    switch (type) {
      case SharingListType.Bucket:
        url = `${path}/?sharingList`;
        break;
      case SharingListType.Folder:
        url = `${path}/?sharingList&prefix=true`;
        break;
      case SharingListType.File:
        url = `${path}?sharingList`;
        break;
    }

    const res: AxiosResponse<void> | undefined = await this.promise("put", "200", {
      url,
      noFormat: true,
      headers: {
        "Content-Type": "application/json",
      },
      body: sharingList,
    });
    return res;
  }

  async putFileAcl({ nameBucket, grants, fileName }: PutFileAclPayload) {
    const rep = await this.s3
      .putObjectAcl({
        Bucket: nameBucket,
        AccessControlPolicy: {
          Grants: grants,
        },
        Key: fileName,
      })
      .promise();

    const error = rep.$response.error;
    if (error) throw new Error(error.message);

    return rep;
  }

  async putVersioningBucket(VersioningStatus: VersioningBucketStatus, Name: string): Promise<PutVersioningBucketReply> {
    const rep = await this.s3
      .putBucketVersioning({
        Bucket: Name,
        VersioningConfiguration: { Status: VersioningStatus },
      })
      .promise();
    const error = rep.$response.error;
    if (error) throw new Error(error.message);
    return { Name, VersioningStatus };
  }

  async privacyFileFunc(payload: {
    nameBucket: string;
    pathFolder: string;
    name: string;
    typePrivacy: string;
    versionId?: string;
  }) {
    const { nameBucket, pathFolder, name, typePrivacy, versionId } = payload;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    const pathFolderEncoded = pathFolder;
    let url = `${pathFolderEncoded ? pathFolderEncoded : ""}${pathFolderEncoded ? "/" : ""}${name}`;
    url = url.replace("//", "/");

    const data = await this.s3
      .putObjectAcl({
        Bucket: nameBucket,
        Key: url,
        ACL: typePrivacy,
        VersionId: versionId,
      })
      .promise();
    return data;
  }

  async privacyBucketFunc(payload: { nameBucket: string; typePrivacy: string }) {
    const { nameBucket, typePrivacy } = payload;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    const data = await this.s3
      .putBucketAcl({
        Bucket: nameBucket,
        ACL: typePrivacy,
      })
      .promise();
    return data;
  }

  async metadataFileFunc(payload: {
    nameBucket: string;
    nameFile: string;
    pathFolder?: string;
    versionId?: string | null;
    contentType?: string;
    CopySource: string;
    Metadata: Record<string, any>;
  }) {
    const { nameBucket, nameFile, pathFolder, CopySource, Metadata, versionId, contentType } = payload;
    const data = await this.s3
      .copyObject({
        ContentType: contentType,
        Bucket: nameBucket,
        Key: pathFolder ? pathFolder + "/" + nameFile : nameFile,
        CopySource: uriEncode(CopySource, false),
        Metadata: Metadata,
        MetadataDirective: "REPLACE",
      })
      .promise();

    return data;
  }

  async deleteFileFunc(file: S3File) {
    const { nameBucket, nameFile, pathFolder, versionId, bypassGovernanceRetention } = file;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    let url = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}${nameFile}`;
    url = url.replace("//", "/");

    const data = await this.s3
      ?.deleteObject({
        Bucket: nameBucket,
        Key: url,
        VersionId: versionId === null ? "null" : versionId,
        BypassGovernanceRetention: bypassGovernanceRetention,
      })
      .promise();
    return data;
  }

  async deleteMultiplyFilesFunc(
    payload: Pick<DeleteFileFolderMultiple, "nameBucket" | "pathFolder" | "items" | "bucketVersioningEnabled"> & {
      bypassGovernanceRetention?: boolean;
    }
  ) {
    const { nameBucket, pathFolder, items, bucketVersioningEnabled, bypassGovernanceRetention } = payload;

    const firstPartUrl = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}`;
    const urls = items.map((i) => ({
      Key: `${firstPartUrl}${i.name}`.replace("//", "/"),
      VersionId: bucketVersioningEnabled ? i?.version : undefined,
    }));
    const data = await this.s3
      .deleteObjects({
        Bucket: nameBucket,
        Delete: { Objects: urls },
        BypassGovernanceRetention: bypassGovernanceRetention,
      })
      .promise();
    return data;
  }

  async deleteBucketFunc({ nameBucket }: { nameBucket: string }) {
    return new Promise((resolve, reject) => {
      const data = this.s3.deleteBucket(
        {
          Bucket: nameBucket,
        },
        function (err, data) {
          if (err) {
            console.error("Error s3.deleteBucketFunc", err);
            reject(err);
          } else {
            console.log("Success s3.deleteBucketFunc", data);
            resolve(data);
          }
        }
      );
      return data;
    });
  }

  async deleteAllObjectsInBucketFunc({ nameBucket }: { nameBucket: string }) {
    try {
      const objects = await this.s3.listObjects({ Bucket: nameBucket }).promise();
      if (!objects.Contents?.length) {
        return;
      }
      const result = await this.s3
        .deleteObjects({
          Bucket: nameBucket,
          Delete: {
            Objects: objects.Contents?.map((contents) => ({
              Key: contents.Key,
            })) as ObjectIdentifierList,
          },
        })
        .promise();
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteBucketIfExists({ nameBucket }: { nameBucket: string }) {
    let bucketExists = await this.s3
      .headBucket({ Bucket: nameBucket })
      .promise()
      .then(() => true)
      .catch(() => false);

    if (bucketExists) {
      await this.emptyBucket(nameBucket);
      await this.s3.deleteBucket({ Bucket: nameBucket }).promise();
    }
  }

  async emptyBucket(bucket: string): Promise<void> {
    const { Status } = await this.s3.getBucketVersioning({ Bucket: bucket }).promise();
    if (Status === "Disabled") {
      await this.deleteAllObjectsInBucketFunc({ nameBucket: bucket });
    } else {
      await this.deleteAllVersionsInBucket(bucket);
    }
  }

  async deleteAllVersionsInBucket(bucket: string): Promise<void> {
    const objects = await this.s3.listObjectVersions({ Bucket: bucket }).promise();

    if (!objects.Versions?.length && !objects.DeleteMarkers?.length) {
      return;
    }

    const result = await this.s3
      .deleteObjects({
        Bucket: bucket,
        BypassGovernanceRetention: true,
        Delete: {
          Objects: [
            ...(objects.Versions?.map((contents) => ({
              Key: contents.Key,
              VersionId: contents.VersionId,
            })) as ObjectIdentifierList),
            ...(objects.DeleteMarkers?.map((contents) => ({
              Key: contents.Key,
              VersionId: contents.VersionId,
            })) as ObjectIdentifierList),
          ],
        },
      })
      .promise();

    if (result.Errors) {
      console.log(`Errors on deleteAllVersionsInBucket: ${JSON.stringify(result.Errors)}`);
    }
  }

  async createFolderS3(payload: { nameFolder: string; nameBucket: string; pathFolder?: string }) {
    const { nameFolder, nameBucket, pathFolder } = payload;
    await this.checkBucketRegionAndUpdateClient(nameBucket);

    return new Promise((resolve, reject) => {
      const data = this.s3.putObject(
        {
          Key: `${pathFolder ? pathFolder.replace("/", "") : ""}${pathFolder ? "/" : ""}${nameFolder}`,
          Bucket: nameBucket,
        },
        function (err, data) {
          if (err) {
            console.error("Error s3.createFolder", err);
            reject(err);
          } else {
            console.log("Success s3.createFolder", data);
            resolve(data);
          }
        }
      );
      console.log("s3.createFolder S3 data", data);
    });
  }

  async createBucketS3(name: string, ACL?: string, region?: string | null, objectLock?: boolean) {
    return new Promise((resolve, reject) => {
      const res = this.s3.createBucket(
        {
          Bucket: name,
          ACL: ACL,
          CreateBucketConfiguration: { LocationConstraint: region ? region : undefined },
          ObjectLockEnabledForBucket: objectLock,
        },
        function (err, data) {
          if (err) {
            console.log("Error s3.createBucket", err, "Code", err?.code, err?.statusCode);
            reject(err);
          } else {
            console.log("Success s3.createBucket", data);
            resolve(data);
          }
        }
      );
      console.log("res createBucket", res);
    });
  }

  async getDownloadLinkS3(file: S3File) {
    const { nameBucket, nameFile, pathFolder, versionId } = file;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    const nameEncoded = nameFile;
    const pathFolderEncoded = pathFolder ? pathFolder : "";
    let key = `${pathFolderEncoded ? pathFolderEncoded : ""}${pathFolderEncoded ? "/" : ""}${nameEncoded}`;
    key = key.replace("//", "/");
    const link = this.s3.getSignedUrl("getObject", {
      Bucket: nameBucket,
      Key: key,
      ResponseContentDisposition: `attachment;"`,
      VersionId: versionId,
    });

    return { link: link, name: nameFile };
  }

  async setDefaultFileVersion(file: S3File) {
    const { nameBucket, nameFile, pathFolder, versionId } = file;
    await this.checkBucketRegionAndUpdateClient(nameBucket);

    const nameEncoded = nameFile;
    const pathFolderEncoded = pathFolder ? pathFolder : "";
    let key = `${pathFolderEncoded ? pathFolderEncoded : ""}${pathFolderEncoded ? "/" : ""}${nameEncoded}`;
    key = key.replace("//", "/");

    await this.s3
      .copyObject({
        Bucket: nameBucket,
        Key: key,
        CopySource: `${uriEncode(nameBucket + "/" + key, false)}?versionId=${versionId}`,
      })
      .on("build", (request) => {
        request.httpRequest.headers["x-coldstack-object-lock-directive"] = "COPY";
        request.httpRequest.headers["x-coldstack-acl-directive"] = "COPY";
      })
      .promise();
  }

  async getBucketLogging(nameBucket: string) {
    const loggingStatus = await this.s3
      .getBucketLogging({
        Bucket: nameBucket,
      })
      .promise();

    return loggingStatus?.LoggingEnabled;
  }

  async checkBucketVersioning(nameBucket: string): Promise<BucketVersioningStatus | undefined> {
    const bucketVersioning = await this.s3
      ?.getBucketVersioning({
        Bucket: nameBucket,
      })
      .promise();

    return bucketVersioning?.Status;
  }

  async toggleBucketLogging(logging: BucketLogging) {
    const { Bucket, TargetBucket, TargetPrefix = "", enable } = logging;
    await this.s3
      .putBucketLogging({
        Bucket,
        BucketLoggingStatus: enable
          ? {
              LoggingEnabled: {
                TargetBucket,
                TargetPrefix,
              },
            }
          : {},
      })
      .promise();
  }

  async getObjectLockConfiguration(nameBucket: string) {
    return this.s3
      .getObjectLockConfiguration({
        Bucket: nameBucket,
      })
      .promise();
  }

  async putObjectLockConfiguration(config: PutObjectLockConfig) {
    const { nameBucket, enabled, retentionMode, type, amount } = config;

    return this.s3
      .putObjectLockConfiguration({
        Bucket: nameBucket,
        ObjectLockConfiguration: {
          ObjectLockEnabled: enabled ? "Enabled" : undefined,
          Rule: {
            DefaultRetention: {
              Mode: retentionMode,
              Days: type === "Days" ? (amount ? +amount : undefined) : undefined,
              Years: type === "Years" ? (amount ? +amount : undefined) : undefined,
            },
          },
        },
      })
      .promise();
  }

  async getObjectRetention(file: S3File) {
    const { nameBucket, nameFile, pathFolder, versionId } = file;
    await this.checkBucketRegionAndUpdateClient(nameBucket);

    let key = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}${nameFile}`;
    key = key.replace("//", "/");

    return this.s3
      .getObjectRetention({
        Bucket: nameBucket,
        Key: key,
        VersionId: !!versionId ? versionId : undefined,
      })
      .promise();
  }

  async putObjectRetention(file: S3File & { retention: ObjectLockRetention }) {
    const { nameBucket, nameFile, pathFolder, versionId, retention } = file;
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    let key = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}${nameFile}`;
    key = key.replace("//", "/");

    return this.s3
      .putObjectRetention({
        Bucket: nameBucket,
        Key: key,
        VersionId: !!versionId ? versionId : undefined,
        Retention: {
          Mode: retention.Mode,
          RetainUntilDate: retention.RetainUntilDate,
        },
      })
      .promise();
  }

  async getObjectLegalHold(file: S3File) {
    const { nameBucket, nameFile, versionId, pathFolder } = file;

    await this.checkBucketRegionAndUpdateClient(nameBucket);
    let key = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}${nameFile}`;
    key = key.replace("//", "/");

    return this.s3
      .getObjectLegalHold({
        Bucket: nameBucket,
        Key: key,
        VersionId: !!versionId ? versionId : undefined,
      })
      .promise();
  }

  async getBucketPolicy(nameBucket: string) {
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    return this.s3
      .getBucketPolicy({
        Bucket: nameBucket,
      })
      .promise();
  }

  async deleteBucketPolicy(nameBucket: string) {
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    return this.s3
      .deleteBucketPolicy({
        Bucket: nameBucket,
      })
      .promise();
  }

  async putBucketPolicy(nameBucket: string, policy: Policy) {
    await this.checkBucketRegionAndUpdateClient(nameBucket);
    return this.s3
      .putBucketPolicy({
        Bucket: nameBucket,
        Policy: policy,
      })
      .promise();
  }

  async putObjectLegalHold(objectHold: PutObjectLegalHold) {
    const { nameBucket, nameFile, versionId, pathFolder, objectLockLegalHoldStatus } = objectHold;

    await this.checkBucketRegionAndUpdateClient(nameBucket);
    let key = `${pathFolder ? pathFolder : ""}${pathFolder ? "/" : ""}${nameFile}`;
    key = key.replace("//", "/");

    return this.s3
      ?.putObjectLegalHold({
        Bucket: nameBucket,
        Key: key,
        VersionId: !!versionId ? versionId : undefined,
        LegalHold: {
          Status: objectLockLegalHoldStatus,
        },
      })
      .promise();
  }

  cancelUpload(upload: UploadManager) {
    const {
      instance,
      filemeta: { filepath },
    } = upload;

    try {
      instance.abort();
    } catch {
      console.log(`File ${filepath} doesn't start upload process`);
    }
  }

  async getZipStats(bucketName: string, folder: string, subFolderPath?: string) {
    const folderPath = `${subFolderPath ? subFolderPath + "/" : ""}${folder}`;

    const response: AxiosResponse<ZipStatsResponse> | undefined = await this.promise("get", "200", {
      url: `${bucketName}/${folderPath}/?zipStats`,
      query: {
        format: "json",
      },
    });
    return response?.data;
  }

  async getSignedUrlForDownloadZip(bucketName: string, zipId: string) {
    if (!this.s3) throw new Error();
    const req = this.s3.listObjects({ Bucket: bucketName });

    req.on("build", () => {
      req.httpRequest.path += `?zip&zipId=${zipId}`;
    });

    const presignedUrl = req["presign"]();

    req.abort();

    return presignedUrl;
  }

  async createZip(bucketName: string, files: BucketSelectItem[], rootFolder?: string) {
    const setName = (item: BucketSelectItem) => {
      let name = item.name + (isFolderType(item) ? "/" : "");

      if (rootFolder) {
        name = `${rootFolder}/` + name;
      }

      return name;
    };

    const folders = files.filter(isFolderType).map(setName);
    const _files = files.filter((x) => !isFolderType(x)).map(setName);

    const response = await this.promise("POST", "201", {
      url: `${bucketName}/?zip`,
      noFormat: true,
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        CreateZip: {
          Contents: {
            Prefixes: folders,
            Keys: _files,
          },
        },
      },
    });

    return response?.data?.CreateZip as unknown as CreateZipResponse;
  }
}

export const s3Client = new S3Client();
