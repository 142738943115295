import React, { useMemo } from "react";
import style from "./pages.module.scss";
import { SvgArrowLeft, SvgArrowRight } from "../../../../icons/ArrowsLeftRight";
import clsx from "clsx";

interface IProps {
  pages: number;
  activePage: number;
  onClickPage: (n: number) => void;
}

const Pages: React.FC<IProps> = ({ pages, activePage, onClickPage }: IProps) => {
  const pagesMas = useMemo(() => {
    let mas = [] as number[];
    for (let i = 0; i < pages; i++) {
      mas[i] = i + 1;
    }
    if (pages > 5) {
      let first;
      let last;
      if (activePage <= 3) {
        first = 1;
        last = 5;
      } else {
        // if (activePage + 2 >= pages) {
        //   console.log("activePage + 2 >= pages", activePage + 2 >= pages);
        //   first = pages - 5;
        //   last = pages + 1;
        // } else {
        first = activePage - 2;
        last = Math.min(activePage + 2, pages);
        // }
      }
      mas = mas.slice(first - 1, last);
    }
    return mas;
  }, [pages, activePage]);
  return (
    <div className={style.container}>
      {activePage !== 1 && (
        <div
          className={style.button}
          onClick={() => {
            if (activePage - 1 > 0) {
              onClickPage(activePage - 1);
            }
          }}
        >
          <SvgArrowLeft />
        </div>
      )}
      {pagesMas[0] > 1 && (
        <>
          <div
            className={`${style.button}`}
            onClick={() => {
              onClickPage(1);
            }}
          >
            {1}
          </div>
          {pagesMas[0] > 2 && <div className={clsx(style.button, style.buttonPoints)}>...</div>}
        </>
      )}

      {pagesMas.map((page, index, array) => {
        return (
          <div
            key={page}
            className={`${style.button} ${activePage === page ? style.buttonActive : ""}`}
            onClick={() => {
              onClickPage(page);
            }}
          >
            {page}
          </div>
        );
      })}

      {pagesMas[4] < pages && (
        <>
          {pagesMas[4] < pages - 1 && <div className={clsx(style.button, style.buttonPoints)}>...</div>}

          <div
            className={`${style.button}`}
            onClick={() => {
              onClickPage(pages);
            }}
          >
            {pages}
          </div>
        </>
      )}
      {pages !== activePage && (
        <div
          className={style.button}
          onClick={() => {
            if (activePage + 1 <= pages) {
              onClickPage(activePage + 1);
            }
          }}
        >
          <SvgArrowRight color={"main"} />
        </div>
      )}
    </div>
  );
};
export default Pages;
