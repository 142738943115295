import clsx from "clsx";
import { StripeForm } from "components/StripeForm/StripeForm";
import Button from "components/UI/Button/Button";
import { CoreModal } from "components/UI/Modal/Modal";
import { NextTariffCard } from "containers/BillingPage/partials/SelectPlan/SelectPlan";
import { useEventBus } from "helpers/hooks/useEventBus";
import React, { useEffect, useState } from "react";
import { useGetTariffFromCatalog } from "Redux/catalogs/selectors/selectTariffCatalog";
import { SafeRenderTsc } from "shared/src/components/SafeRenderTsc";
import { centsToUsd } from "shared/src/helpers/utils";
import { useDialogState } from "shared/src/hooks/useDialogState";
import { CardSvg } from "shared/src/images/CardSvg";
import styles from "./billingHistory.module.scss";

type PaymentDialogProps = {
  onClose: () => void;
  paymentId: string;
  active: boolean;
  tariffId: number;
};

const PaymentDialog: React.FC<PaymentDialogProps> = (props) => {
  const { paymentId, onClose, active, tariffId } = props;
  const tariffData = useGetTariffFromCatalog()(tariffId);
  const [error, setError] = useState("");
  const [suspend, setSuspendForm] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const eventBus = useEventBus();
  const submitEnventName = "STRIPE_SUB";
  const returnUrl = "/dashboard/billing";

  const submit = () => eventBus.publish({ topic: submitEnventName, payload: null });

  useEffect(() => {
    if (!active) setFormReady(false);
  }, [active]);

  return (
    <CoreModal maxWidth={478} title="Purchase Order" closeOutClick onClose={onClose} visible={active}>
      <div className={styles.modal}>
        <SafeRenderTsc value={tariffData}>
          {(tariff) => (
            <>
              <NextTariffCard title={tariff.name} charge={tariff.price} date={new Date()} />
              {paymentId && (
                <StripeForm
                  onFormReady={() => setFormReady(true)}
                  formReady
                  submitEnventName={submitEnventName}
                  getPaymentSecret={async () => ({
                    client_secret: paymentId,
                  })}
                  onError={setError}
                  setSuspendForm={setSuspendForm}
                  returnTo={returnUrl}
                />
              )}
              <div>
                {error && <small style={{ color: "tomato", marginBottom: "15px", display: "block" }}>{error}</small>}
                <div className={styles.buttons}>
                  <Button onClick={onClose} color="outlined">
                    Cancel
                  </Button>
                  <Button onClick={submit} loading={suspend} disabled={!formReady || suspend}>
                    Pay {centsToUsd(tariffData?.price ?? 0)}
                  </Button>
                </div>
              </div>
            </>
          )}
        </SafeRenderTsc>
      </div>
    </CoreModal>
  );
};

type PayButtonProps = {
  paymentId: string;
  tariffId: number;
  renderButton?: (open: () => void) => JSX.Element;
  className?: string;
};

export const PayButton: React.FC<PayButtonProps> = (props) => {
  const { paymentId, className, tariffId, renderButton } = props;
  const { open, active, close } = useDialogState();

  return (
    <>
      {renderButton ? (
        renderButton(open)
      ) : (
        <Button
          className={clsx(styles.payBtn, className)}
          color="accent"
          size="sm"
          icon={<CardSvg className={styles.payBtnIcon} />}
          onClick={() => open()}
        >
          Pay
        </Button>
      )}

      <PaymentDialog tariffId={tariffId} paymentId={paymentId} onClose={close} active={active} />
    </>
  );
};
