import { PayloadAction } from "@reduxjs/toolkit";
import { S3File } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { ObjectLockRetention } from "aws-sdk/clients/s3";
import { call, put } from "redux-saga/effects";
import { getObjectRetention } from "Redux/buckets/Actions/bucketsActions";
import { handleSagaError } from "Redux/helpers";

export function* putObjectRetentionProcess({ payload }: PayloadAction<S3File & { retention: ObjectLockRetention }>) {
  try {
    yield call([s3Client, s3Client.putObjectRetention], payload);
    yield put(
      getObjectRetention({
        nameBucket: payload.nameBucket,
        versionId: payload.versionId,
        pathFolder: payload.pathFolder,
        nameFile: payload.nameFile,
      })
    );
  } catch (err) {
    yield handleSagaError(err);
  }
}
