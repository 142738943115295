import clsx from "clsx";
import { prettySize } from "shared/src/helpers/utils";
import styles from "./ProgressBar.module.scss";

type ProgressBarProps = {
  value: number;
  limit: number;
  // trialLimit?: number;
  className?: string;
  label?: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ limit, label, value, className }) => {
  const widthNormal = (value / limit) * 100;
  const width2x = value >= limit ? ((value - limit) / limit) * 100 : 0;
  const width3x = value >= 2 * limit ? ((value - 2 * limit) / limit) * 100 : 0;

  // const { widthNormal, width2x, width3x } = useMemo(() => {
  //   let [widthNormal, width2x, width3x] = [0, 0, 0];

  //   if (trialLimit) {
  //     widthNormal = (value / trialLimit) * 100;
  //     width2x = value >= trialLimit ? (value / (2 * limit)) * 100 : 0;
  //     width3x = value >= 2 * limit ? ((value - 2 * limit) / limit) * 100 : 0;
  //     return { widthNormal, width2x, width3x };
  //   }

  //   widthNormal = (value / limit) * 100;
  //   width2x = value >= limit ? ((value - limit) / limit) * 100 : 0;
  //   width3x = value >= 2 * limit ? ((value - 2 * limit) / limit) * 100 : 0;

  //   return { widthNormal, width2x, width3x };
  // }, [limit, value, trialLimit]);

  return (
    <div className={clsx(styles.container, className)}>
      {label && (
        <div className={styles.label}>
          <span className={styles.labelText}>{label}</span>
          <span className={styles.labelUnits}>
            {prettySize(String(value))} / {prettySize(String(limit))}
          </span>
        </div>
      )}
      <div className={styles.bar}>
        <div style={{ width: `${widthNormal}%` }} className={clsx(styles.progress, styles.progressNormal)}></div>
        <div style={{ width: `${width2x}%` }} className={clsx(styles.progress, styles.progressOverNormal2x)}></div>
        <div style={{ width: `${width3x}%` }} className={clsx(styles.progress, styles.progressOverNormal3x)}></div>
      </div>
    </div>
  );
};
