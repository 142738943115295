import { CoreModal } from "components/UI/Modal/Modal";
import { AppConfig } from "config";
import { getHours, getMinutes, setHours, setMinutes } from "date-fns";
import { format } from "helpers/date-fns";
import { GitHubOutline } from "iconoir-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectTariffUserPlan } from "Redux/account/Selectors/selectTariffUserPlan";
import { updateUserStatusAction } from "Redux/user/reducer";
import { selectUserData } from "Redux/user/Selectors/selectUserData";
import { selectUserStatus } from "Redux/user/Selectors/selectUserStatus";
import { ButtonIcon } from "shared/src/components/ButtonIcon/ButtonIcon";
import { SpinnerCute } from "shared/src/components/Spinner/Spinner";
import { UserStatusEnum } from "shared/src/models/UserModel";
import { setTariffUserPlan } from "../../../Redux/account/reducer";
import styles from "./HiddenDevTools.module.scss";

type ValueOrFallBackProps = {
  value?: React.ReactNode;
  fallback: React.ReactNode;
};
const ValueOrFallBack: React.FC<ValueOrFallBackProps> = ({ value, fallback }) => {
  return <>{value ?? fallback}</>;
};

type HiddenDevToolsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const HiddenDevTollsModal: React.FC<HiddenDevToolsModalProps> = ({ isOpen, onClose }) => {
  const userData = useSelector(selectUserData);
  const userPlan = useSelector(selectTariffUserPlan);
  const [locale, setLocale] = useState("en");
  const { i18n } = useTranslation();

  const reloadPage = () => (window.location.href = window.location.origin);
  const onChangeLocale = (e: any) => {
    const value = e.target.value as string;

    window.__localeId__ = value === "en" ? "enGB" : "ru";

    i18n.changeLanguage(value);
    setLocale(value);
  };

  const userStatus = useSelector(selectUserStatus);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userStatusOptions = [
    UserStatusEnum.trial,
    UserStatusEnum.active,
    UserStatusEnum.expired,
    UserStatusEnum.payment_required,
    UserStatusEnum.unsubscribed,
    UserStatusEnum.suspended,
  ];

  const setUserStatus = async (nextStatus: UserStatusEnum) => {
    setLoading(true);
    dispatch(updateUserStatusAction(nextStatus));
    setLoading(false);
  };

  const formatDateInput = (x: Date) => format(x, "yyyy-MM-dd");
  const formatTimeInput = (x: Date) => {
    const hours = `0${getHours(x)}`.slice(-2);
    const minutes = `0${getMinutes(x)}`.slice(-2);
    const value = `${hours}:${minutes}`;
    return value;
  };

  const [expirationDate, setExpirationDate] = useState(new Date());
  const [loadingExparationDate, setLoadingExparationDate] = useState(false);

  const changeExparationDate = async (expirationDate, userPlan) => {
    setLoadingExparationDate(true);
    dispatch(setTariffUserPlan({ ...userPlan, end_at: expirationDate }));
    setLoadingExparationDate(false);
  };

  const handleChangeDate = (x: string) => {
    let date = new Date(x);
    const hours = getHours(expirationDate);
    const minutes = getMinutes(expirationDate);
    date = setHours(date, hours);
    date = setMinutes(date, minutes);
    setExpirationDate(date);
  };

  return (
    <CoreModal className={styles.serviceModal} visible={isOpen} closeOutClick onClose={onClose}>
      <div className={styles.serviceModalBody}>
        <h3>User DTO view:</h3>

        <table className={styles.table}>
          <tr>
            <th>User Status</th>
            <th>Tariff start at</th>
            <th>Tariff end at</th>
          </tr>

          <tbody>
            <tr>
              <td>{userData.status}</td>
              <td>
                <ValueOrFallBack value={format(userPlan?.created_at)} fallback="no value" />
              </td>
              <td>
                <ValueOrFallBack value={format(userPlan?.end_at)} fallback="no value" />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="flex gap-x-4">
          <label>User Status:</label>
          <select
            value={userStatus}
            onChange={(e) => setUserStatus(e.target.value as UserStatusEnum)}
            disabled={loading}
          >
            {userStatusOptions.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          {loading && <SpinnerCute classNames={styles.spinner} />}
        </div>

        <div className="flex flex-col">
          <h3>Select Tariff expiration date: </h3>
          <div className="flex gap-x-4 items-center">
            <label>Date:</label>{" "}
            <input
              disabled={loadingExparationDate}
              value={formatDateInput(expirationDate)}
              onChange={({ target }) => handleChangeDate(target.value)}
              type="date"
            />
            <label>Time:</label>{" "}
            <input
              disabled={loadingExparationDate}
              value={formatTimeInput(expirationDate)}
              onChange={({ target }) => {
                const [hours, minutes] = target.value.split(":");
                let newDate = new Date(expirationDate);
                newDate = setHours(newDate, Number(hours));
                newDate = setMinutes(newDate, Number(minutes));
                setExpirationDate(newDate);
              }}
              type="time"
            />
            <button onClick={() => changeExparationDate(expirationDate, userPlan)}>change</button>
            {loadingExparationDate && <SpinnerCute classNames={styles.spinner} />}
          </div>
        </div>

        <select value={locale} onChange={onChangeLocale}>
          <option value="en">Eng</option>
          <option value="ru">Rus</option>
        </select>
      </div>
    </CoreModal>
  );
};

export const HiddenDevToolsButton = () => {
  const [open, setOpen] = useState(false);

  if (AppConfig.environment === "production") return null;
  return (
    <>
      <ButtonIcon onClick={() => setOpen(true)} className={styles.btn} icon={<GitHubOutline />} />
      <HiddenDevTollsModal isOpen={open} onClose={() => setOpen(false)} />
    </>
  );
};
