import { CompanyDto, UpdateCompanyDto } from "./Company";
import { SubPlanEnum } from "./SubPlan";

export enum UserRole {
  SUPERADMIN = "superadmin",
  ADMIN = "admin",
  MODERATOR = "moderator",
  SUPPORT = "support",
  CUSTOMER = "customer",
}

export type UserInfo = {
  alphaTesterWalletConnected: boolean | null;
  arn: string;
  createdAt: Date;
  email: string | null;
  emailVerified: boolean | null;
  id: string;
  publicKey: string;
};

export type JWTUserPayload = {
  iat: number;
  sub: {
    public: string;
    user: UserInfo;
  };
};

export enum UserStatusEnum {
  /**
   * Пользователь в триальном периоде, полный доступ к файлам и дашборду
   */
  trial = "trial",
  /**
   * Все нормально с пользователем, у него есть подтвержденная карта, полный доступ к файлам и дашборду
   */
  active = "active",
  /**
   * За неделю до окончания билингового периода, полный доступ к файлам и дашборду
   */
  expired = "expired",
  /**
   * Пользователь может продолжать использовать сервис, при посещении дашборда отображаем
   * перетяжку (информационная строка-полоска) с просьбой обновить карты/пополнить баланс
   * карты. Полный доступ к файлам и дашборду
   */
  payment_required = "payment_required",
  /**
   * Пользователь отписался от тарифа, до конца оплаченного периода полный доступ и если
   * не подпишется снова, переводим в состояние blocked и далее в deleted
   */
  unsubscribed = "unsubscribed",
  /**
   * Есть только возможность просмотра файлов, но нет доступа к загрузке/выгрузке файлов.
   * При попытке загрузки/выгрузки файлов с помощью s3 client выводим сообщение об этом.
   * Отображаем перетяжку с информацией об этом на дашборде
   */
  suspended = "suspended",
  /**
   * Аккаунт заблокирован, нет доступ ни к чему, при переходе на дашборд показываем попап
   * с информацией что аккаунт заблокирован (дашборд закрыт) и просьбой связаться по почте.
   * Доступа к файлам у пользователя нет, даже на просмотр. Если коннектся через s3 то выводим
   * сообщение что пользователь заблокирован.
   */
  blocked = "blocked",
  /**
   * Аккаунт удален, данные удалены, больше с этим емайлом нельзя зарегистрироваться
   */
  deleted = "deleted",
}

export type UserProfile = {
  id: string;
  name: string;
  isCanShare: boolean;
  lastName?: string;
  companyName?: string;
  website?: string;
  createdAt: Date;
  publicKey: string;
  arn: string;
  finishedRegistration: boolean;
  hasStoredPassword: boolean;
  tariffId: SubPlanEnum | null;
  nextTariffId?: number;
  email?: string;
  newEmail?: string;
  emailVerified?: boolean;
  alphaTesterWalletConnected?: boolean;
  status: UserStatusEnum;
  role: UserRole;
  company?: CompanyDto;
  partner_id: number | null;
};

export type UserDto = Omit<UserProfile, "tariffId"> & {
  tariffId: number | null;
};

export type UpdateUserDto = {
  id: string;
  password?: string;
  role?: string;
  status?: string;
  tariffId?: number | null;
  name?: string;
  email?: string;
  company?: UpdateCompanyDto;
  isCanShare?: boolean;
};
