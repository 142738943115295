import clsx from "clsx";
import React from "react";
import { SpinnerCute } from "../Spinner/Spinner";
import styles from "./PbButton.module.scss";

export type PbButtonColor = "primary" | "success" | "danger" | "secondary" | "secondary-bordered" | "outlined";
export type PbButtonSize = "lg" | "md" | "sm";

export type PbButtonProps = {
  type?: "submit" | "button";
  className?: string;
  onClick?: () => void;
  color?: PbButtonColor;
  size?: PbButtonSize;
  disabled?: boolean;
  loading?: boolean;
  fullwidth?: boolean;
  icon?: JSX.Element;
  dataTest?: string;
};

const SizeMap = {
  lg: styles.lg,
  md: styles.md,
  sm: styles.sm,
};
const ColorMap = {
  primary: styles.primary,
  success: styles.success,
  danger: styles.danger,
  secondary: styles.secondary,
  "secondary-bordered": styles["secondary-bordered"],
};

export const PbButton: React.FC<PbButtonProps> = ({
  type = "button",
  onClick,
  className,
  color = "primary",
  size = "md",
  children,
  disabled,
  loading,
  icon,
  fullwidth,
  dataTest,
}) => {
  const sizeClass = SizeMap[size];
  const colorClass = ColorMap[color];

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
      className={clsx(
        fullwidth && styles.fullwidth,
        styles.pbButton,
        sizeClass,
        colorClass,
        className,
        disabled ? "disabled" : ""
      )}
      type={type}
    >
      {children}
      {(loading || icon) && <div className={styles.icon}>{loading ? <SpinnerCute /> : icon}</div>}
    </button>
  );
};
