import * as React from "react";

export const SvgCloseIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
                                                                        color = "#5A5D65",
                                                                        width = "24",
                                                                        height = "24",
                                                                      }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0009 12L17.2435 17.2426M6.7583 17.2426L12.0009 12L6.7583 17.2426ZM17.2435 6.75735L12.0009 12L17.2435 6.75735ZM12.0009 12L6.7583 6.75735L12.0009 12Z"
        stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default SvgCloseIcon;

function SvgCloseRound() {
  return (
    <svg className="closeRound"  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" />
      <path
        d="M6.66602 13.667L10.166 10.167M10.166 10.167L13.666 6.66699M10.166 10.167L6.66602 6.66699M10.166 10.167L13.666 13.667"
        stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}

export {SvgCloseRound};
