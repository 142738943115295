import { PayloadAction } from "@reduxjs/toolkit";
import { S3File } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { S3 } from "aws-sdk";
import { call, put } from "redux-saga/effects";
import { setObjectLegalHold } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getObjectLegalHoldProcess({ payload }: PayloadAction<S3File>) {
  try {
    const objectLegalHold: S3.GetObjectLegalHoldOutput = yield call([s3Client, s3Client.getObjectLegalHold], payload);

    if (objectLegalHold.LegalHold) {
      yield put(setObjectLegalHold(objectLegalHold.LegalHold));
    }
  } catch (err) {
    yield put(setObjectLegalHold(undefined));
    yield handleSagaError(err);
  }
}
