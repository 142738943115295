import { createAction } from "@reduxjs/toolkit";
import { ChangeStatusPayload } from "actions/actionTypes";
import { BillingHistoryPagination } from "shared/src/models/BillingHistory";
import { CheckProofPayload } from "../../../models/CheckProofTonWallet";

export type CreateAccessKeyPayloadAction = { onSuccess: () => void };
export type ChangePasswordPayload = {
  oldPassword: string;
  newPassword: string;
};
export type SetDefaultPaymentMethodPayload = { id: string };
export type DeletePaymentPayload = { id: string };
export type SetEmailSettingPayload = string;
export type GetBillingHistoryPayload = BillingHistoryPagination;

export const checkIsAuthAction = createAction<(() => void) | undefined>("checkIsAuthAction");
export const getSettingsInfo = createAction("getSettingsInfo");
export const createAccessKey = createAction<CreateAccessKeyPayloadAction>("createAccessKey");
export const deleteAccessKey = createAction("deleteAccessKey");
export const setStatusAccessKey = createAction<ChangeStatusPayload>("setStatusAccessKey");
export const initS3Action = createAction("initS3Action");
export const getPaymetMethod = createAction("getPaymetMethod");
export const setDefaultPaymentMethod = createAction<SetDefaultPaymentMethodPayload>("setDefaultPaymentMethod");
export const deletePaymentMethod = createAction<DeletePaymentPayload>("deletePaymentMethod");
export const setEmailSettings = createAction<SetEmailSettingPayload>("setEmailSettings");
export const getEmailSettings = createAction("getEmailSettings");
export const changePasswordAction = createAction<ChangePasswordPayload>("changePasswordAction");
export const getBillingHistory = createAction<GetBillingHistoryPayload>("getBillingHistory");
export const getTonConnectPayload = createAction("getTonConnectPayload");
export const checkProofTonWallet = createAction<CheckProofPayload>("checkProofTonWallet");
