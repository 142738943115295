import { createAction } from "@reduxjs/toolkit";
import { IGetSupportDataPayload, IGetTicketsPayload, Status } from "actions/accountActionTypes";
import { ITicket } from "actions/interfaces";

export const getSupportData = createAction<IGetSupportDataPayload>("getSupportData");
export const getTickets = createAction<IGetTicketsPayload>("getTickets");
export const createTicket = createAction<ITicket>("createTicket");
export const deleteTicket = createAction<string>("deleteTicket");
export const setStatusTicket = createAction<{ id: string; status: Status }>("setStatusTicket");
export const getPriceLanding = createAction("getPriceLanding");
export const getTariffUserPlan = createAction("getTariffUserPlan");
export const getBillingUsage = createAction("getBillingUsage");
export const getStorageClassesInfo = createAction("getStorageClassesInfo");
