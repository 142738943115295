import { takeEvery, takeLatest } from "redux-saga/effects";
import {
  createTicket,
  deleteTicket,
  getBillingUsage,
  getPriceLanding,
  getStorageClassesInfo,
  getSupportData,
  getTariffUserPlan,
  getTickets,
  setStatusTicket,
} from "Redux/account/Actions/accountActions";
import { getStorageClassesInfoProcess } from "Redux/account/Sagas/getStorageClassesInfoProcess";
import { createTicketProcess } from "./Sagas/createTicketProcess";
import { deleteTicketProcess } from "./Sagas/deleteTicketProcess";
import { getBillingUsageProcess } from "./Sagas/getBillingUsageProcess";
import { getPriceLandingProcess } from "./Sagas/getPriceLanding";
import { getSupportDataProcess } from "./Sagas/getSupportDataProcess";
import { getTariffUserPlanProcess } from "./Sagas/getTariffUserPlanProcess";
import { getTicketsProcess } from "./Sagas/getTicketsProcess";
import { setStatusTicketProcess } from "./Sagas/setStatusTicketProcess";

export function* accountSaga() {
  yield takeLatest(getSupportData.type, getSupportDataProcess);
  yield takeLatest(getTickets.type, getTicketsProcess);
  yield takeLatest(createTicket.type, createTicketProcess);
  yield takeLatest(deleteTicket.type, deleteTicketProcess);
  yield takeLatest(setStatusTicket.type, setStatusTicketProcess);
  yield takeLatest(getPriceLanding.type, getPriceLandingProcess);
  yield takeLatest(getTariffUserPlan.type, getTariffUserPlanProcess);
  yield takeEvery(getBillingUsage.type, getBillingUsageProcess);
  yield takeLatest(getStorageClassesInfo.type, getStorageClassesInfoProcess);
}
