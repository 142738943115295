import { PayloadAction } from "@reduxjs/toolkit";
import { ISelectedBuckets } from "actions/interfaces";
import { s3Client } from "api/s3.client";
import { call, put } from "redux-saga/effects";
import { getBucketsList } from "Redux/buckets/Actions/bucketsActions";
import { handleSagaError } from "Redux/helpers";

export function* deleteSelectedBucketsAndFilesProcess({ payload }: PayloadAction<ISelectedBuckets>) {
  const { items, afterLoad } = payload;
  try {
    for (let i = 0; i < items.length; i++) {
      yield call([s3Client, s3Client.deleteBucketIfExists], {
        nameBucket: items[i].name,
      });
    }

    yield put(getBucketsList({ pagination: { Page: 1, PerPage: 100 }, afterLoad: afterLoad }));
  } catch (err) {
    yield handleSagaError(err);
  }
}
