import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceLandingPayloadType } from "models/PriceLandingPayloadType";
import { SupportTopic } from "models/SupportTopic";
import { GetTariffUserPlanTO } from "shared/src/models/TariffUserPlan";
import { Ticket } from "shared/src/models/TicketDetails";
import { UserUsage } from "../../models/UserUsege";

const initialState: IAccountState = {
  tickets: [],
  topics: [] as SupportTopicItem[],
  userUsage: undefined,
  tariffUserPlan: undefined,
  price: {
    standard: {},
    intelligentTiering: {},
    standardIA: {},
    glacier: {},
    deepArchive: {},
  },
};

export type SupportTopicItem = {
  topic: SupportTopic;
  subtopics: SupportTopic[];
};

export interface IAccountState {
  tickets: Ticket[];
  topics: SupportTopicItem[];
  userUsage?: UserUsage;
  tariffUserPlan?: GetTariffUserPlanTO;
  price: {
    standard: {};
    intelligentTiering: {};
    standardIA: {};
    glacier: {};
    deepArchive: {};
  };
}

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setTariffUserPlan(state, action: PayloadAction<GetTariffUserPlanTO | undefined>) {
      return { ...state, tariffUserPlan: action.payload };
    },
    setBillingUsage(state, action: PayloadAction<UserUsage>) {
      return { ...state, userUsage: action.payload };
    },
    setPriceLanding(state, action: PayloadAction<PriceLandingPayloadType>) {
      return { ...state, price: action.payload };
    },
    setTickets(state, action: PayloadAction<Ticket[]>) {
      return { ...state, tickets: action.payload };
    },
    setSupportData(state, action: PayloadAction<any>) {
      return { ...state, tickets: action.payload?.[0]?.data, topics: action.payload?.[1] };
    },
  },
});

export const account = accountSlice.reducer;
export const { setTariffUserPlan, setBillingUsage, setPriceLanding, setTickets, setSupportData } = accountSlice.actions;
