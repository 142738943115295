import { createAction } from "@reduxjs/toolkit";
import { GetStatisticsHomePayload } from "actions/actionTypes";
import {
  IBucketDelete,
  IBucketRename,
  IBucketsListData,
  IFilesListData,
  ISearchedFilesListData,
  ISelectedBuckets,
} from "actions/interfaces";
import { S3File } from "api/s3-client.types";
import { ObjectLockRetention, Policy } from "aws-sdk/clients/s3";
import { INewFolder } from "containers/BucketPage/BucketPage";
import { INewBucket } from "containers/BucketsPage/BucketsPage";
import { GetFileAclPayload, PutBucketAclPayload, PutFileAclPayload } from "models/ACL";
import {
  DeleteFileFolder,
  DeleteFileFolderMultiple,
  FileFolderMultiple,
  FileMetadata,
  RenameFileFolder,
  RequestVersioningBucket,
} from "models/Bucket";
import { BucketLogging } from "models/BucketLogging";
import { PutObjectLegalHold, PutObjectLockConfig } from "models/ObjectLock";
import { PrivacyBucket } from "models/PrivacyBucket";
import { PrivacyFilePayload } from "models/PrivacyFilePayload";
import { UploadFileType } from "models/UploadFileType";
import {
  BucketSharingList,
  DeleteResourceFromSharedWithYouListParams,
  GetResourcesSharedWithMeQuery,
  KeySharingListRoot,
  PrefixSharingListRoot,
  SharingListType,
} from "../../../models/ShareList";

export const getBucketsList = createAction<IBucketsListData>("getBucketsList");
export const getFilesList = createAction<IFilesListData>("getFilesList");
export const getSearchedFilesList = createAction<ISearchedFilesListData>("getSearchedFilesList");
export const getFileInfo = createAction<S3File & { afterLoad: () => void }>("getFileInfo");
export const renameBucket = createAction<IBucketRename>("renameBucket");
export const renameFileFolder = createAction<RenameFileFolder>("renameFileFolder");
export const privacyFile = createAction<PrivacyFilePayload>("privacyFile");
export const privacyBucket = createAction<PrivacyBucket>("privacyBucket");
export const metadataFile = createAction<FileMetadata>("metadataFile");
export const deleteFileFolder = createAction<DeleteFileFolder>("deleteFileFolder");
export const deleteFileFolderMultiple = createAction<DeleteFileFolderMultiple>("deleteFileFolderMultiple");
export const downloadFile = createAction<S3File>("downloadFileFolder");
export const deleteBucket = createAction<IBucketDelete>("deleteBucket");
export const deleteSelectedBucketsAndFiles = createAction<ISelectedBuckets>("deleteSelectedBucketsAndFiles");
export const createBucket = createAction<INewBucket>("createBucket");
export const createFolder = createAction<INewFolder>("createFolder");
export const getBucketsGuides = createAction("getBucketsGuides");
export const getStatisticsHome = createAction<GetStatisticsHomePayload>("getStatisticsHome");
export const requestVersioningBucket = createAction<RequestVersioningBucket>("requestVersioningBucket");
export const setCurrentFileVersion = createAction<S3File>("setCurrentFileVersion");
export const getBucketLoggingStatus = createAction<string>("getBucketLoggingStatus");
export const getObjectLockConfig = createAction<string>("getObjectLockConfig");
export const putObjectLockConfig = createAction<PutObjectLockConfig>("putObjectLockConfig");
export const getObjectRetention = createAction<S3File>("getObjectRetention");
export const putObjectRetention = createAction<S3File & { retention: ObjectLockRetention }>("putObjectRetention");
export const getObjectLegalHold = createAction<S3File>("getObjectLegalHold");
export const putObjectLegalHold = createAction<PutObjectLegalHold>("putObjectLegalHold");
export const getBucketAcl = createAction<string>("getBucketAcl");
export const getBucketPolicy = createAction<string>("getBucketPolicy");
export const deleteBucketPolicy = createAction<string>("deleteBucketPolicy");
export const putBucketPolicy = createAction<{ bucketName: string; policy: Policy }>("putBucketPolicy");
export const getFileAcl = createAction<GetFileAclPayload>("getFileAcl");
export const putBucketAcl = createAction<PutBucketAclPayload>("putBucketAcl");
export const putFileAcl = createAction<PutFileAclPayload>("putFileAcl");
export const getShareList = createAction<{ path: string; type: SharingListType }>("getShareList");
export const deleteResourceFromSharedWithYouList = createAction<DeleteResourceFromSharedWithYouListParams>(
  "deleteResourceFromSharedWithYouList"
);
export const putShareList = createAction<{
  path: string;
  sharingList: BucketSharingList | PrefixSharingListRoot | KeySharingListRoot;
  type: SharingListType;
}>("putShareList");
export const setBucketLogging = createAction<BucketLogging>("setBucketLogging");
export const getBucketVersioningStatus = createAction<string>("getBucketVersioningStatus");
export const getListResourcesSharedWithMe = createAction<GetResourcesSharedWithMeQuery>("getListResourcesSharedWithMe");
export const downloadFileFolderMultiple = createAction<FileFolderMultiple>("downloadFileFolderMultiple");
export const prepareDroppedFilesAction = createAction<UploadFileType[]>("prepareDroppedFilesAction");
export const startUploadAction = createAction("startUploadAction");
export const blockFunctionsSuspendedAccountAction = createAction<() => void>("blockFunctionsSuspendedAccountAction");
export const cancelUploadAction = createAction<string>("cancelUpload");
export const cancelAllUploadAction = createAction("cancelAllUploadAction");
