import { takeEvery, takeLatest } from "redux-saga/effects";
import {
  blockFunctionsSuspendedAccountAction,
  cancelAllUploadAction,
  cancelUploadAction,
  createBucket,
  createFolder,
  deleteBucket,
  deleteBucketPolicy,
  deleteFileFolder,
  deleteFileFolderMultiple,
  deleteResourceFromSharedWithYouList,
  deleteSelectedBucketsAndFiles,
  downloadFile,
  downloadFileFolderMultiple,
  getBucketAcl,
  getBucketLoggingStatus,
  getBucketPolicy,
  getBucketsGuides,
  getBucketsList,
  getBucketVersioningStatus,
  getFileAcl,
  getFileInfo,
  getFilesList,
  getListResourcesSharedWithMe,
  getObjectLegalHold,
  getObjectLockConfig,
  getObjectRetention,
  getSearchedFilesList,
  getShareList,
  getStatisticsHome,
  metadataFile,
  prepareDroppedFilesAction,
  privacyBucket,
  privacyFile,
  putBucketAcl,
  putBucketPolicy,
  putFileAcl,
  putObjectLegalHold,
  putObjectLockConfig,
  putObjectRetention,
  putShareList,
  renameBucket,
  renameFileFolder,
  requestVersioningBucket,
  setBucketLogging,
  setCurrentFileVersion,
  startUploadAction,
} from "Redux/buckets/Actions/bucketsActions";
import { deleteSelectedBucketsAndFilesProcess } from "Redux/buckets/Sagas/deleteSelectedBucketsAndFilesProcess";
import { getBucketAclProcess } from "Redux/buckets/Sagas/getBucketAclProcess";
import { getBucketLoggingStatusProcess } from "Redux/buckets/Sagas/getBucketLoggingStatusProcess";
import { getBucketVersioningStatusProcess } from "Redux/buckets/Sagas/getBucketVersioningStatusProcess";
import { getFileAclProcess } from "Redux/buckets/Sagas/getFileAclProcess";
import { getObjectLegalHoldProcess } from "Redux/buckets/Sagas/getObjectLegalHoldProcess";
import { getObjectLockConfigProccess } from "Redux/buckets/Sagas/getObjectLockConfigProccess";
import { getObjectRetentionProccess } from "Redux/buckets/Sagas/getObjectRetentionProccess";
import { putBucketAclProcess } from "Redux/buckets/Sagas/putBucketAclProcess";
import { putFileAclProcess } from "Redux/buckets/Sagas/putFileAclProcess";
import { putObjectLegalHoldProcess } from "Redux/buckets/Sagas/putObjectLegalHoldProcess";
import { renameBucketProcess } from "Redux/buckets/Sagas/renameBucketProcess";
import { setBucketLoggingProcess } from "Redux/buckets/Sagas/setBucketLoggingProcess";
import { setCurrentFileVersionProcess } from "Redux/buckets/Sagas/setCurrentFileVersionProcess";
import { blockFunctionsSuspendedAccountProcess } from "./Sagas/blockFunctionsSuspendedAccountProcess";
import { cancelAllUploadsProcess, cancelSingleUploadProcess } from "./Sagas/cancelUploadProcess";
import { createBucketProcess } from "./Sagas/createBucketProcess";
import { createFolderProcess } from "./Sagas/createFolderProcess";
import { deleteBucketProcess } from "./Sagas/deleteBucket";
import { deleteBucketPolicyProcess } from "./Sagas/deleteBucketPolicyProcess";
import { deleteFileFolderMultipleProcess } from "./Sagas/deleteFileFolderMultipleProcess";
import { deleteFileFolderProcess } from "./Sagas/deleteFileFolderProcess";
import { deleteResourceFromSharedWithYouListProcess } from "./Sagas/deleteResourceFromSharedWithYouListProcess";
import { downloadFileMultipleProcess } from "./Sagas/downloadFileMultipleProcess";
import { downloadFileProcess } from "./Sagas/downloadFileProcess";
import { getBucketPolicyProcess } from "./Sagas/getBucketPolicyProcess";
import { getBuckets } from "./Sagas/getBuckets";
import { getBucketsGuidesProcess } from "./Sagas/getBucketsGuides";
import { getFileInfoProcess } from "./Sagas/getFileInfoProcess";
import { getFilesListProcess } from "./Sagas/getFilesListProcess";
import { getListResourcesSharedWithMeProcess } from "./Sagas/getListResourcesSharedWithMeProcess";
import { getShareListProcess } from "./Sagas/getShareListProcess";
import { getStatisticsHomeProcess } from "./Sagas/getStatisticsHomeProcess";
import { metadataFileProcess } from "./Sagas/metadataFileProcess";
import { prepareDropedFilesSaga } from "./Sagas/prepareDropedFilesSaga";
import { privacyBucketProcess } from "./Sagas/privacyBucketProcess";
import { privacyFileProcess } from "./Sagas/privacyFileProcess";
import { putBucketPolicyProcess } from "./Sagas/putBucketPolicyProcess";
import { putObjectLockConfigProccess } from "./Sagas/putObjectLockConfigProccess";
import { putObjectRetentionProcess } from "./Sagas/putObjectRetentionProcess";
import { putShareListProcess } from "./Sagas/putShareListProcess";
import { renameFileFolderProcess } from "./Sagas/renameFileFolderProcess";
import { searchFiles } from "./Sagas/searchFiles";
import { startUploadSaga } from "./Sagas/uploadFilesProcess";
import { versioningBucketSaga } from "./Sagas/versioningBucketSaga";

export function* bucketsSaga() {
  yield takeLatest(getBucketsList.type, getBuckets);
  yield takeLatest(getFilesList.type, getFilesListProcess);
  yield takeLatest(getSearchedFilesList.type, searchFiles);
  yield takeLatest(getFileInfo.type, getFileInfoProcess);
  yield takeLatest(renameBucket.type, renameBucketProcess);
  yield takeLatest(deleteBucket.type, deleteBucketProcess);
  yield takeLatest(deleteSelectedBucketsAndFiles.type, deleteSelectedBucketsAndFilesProcess);
  yield takeLatest(getBucketsGuides.type, getBucketsGuidesProcess);
  yield takeLatest(createBucket.type, createBucketProcess);
  yield takeLatest(createFolder.type, createFolderProcess);
  yield takeLatest(renameFileFolder.type, renameFileFolderProcess);
  yield takeLatest(privacyFile.type, privacyFileProcess);
  yield takeLatest(privacyBucket.type, privacyBucketProcess);
  yield takeLatest(metadataFile.type, metadataFileProcess);
  yield takeLatest(deleteFileFolder.type, deleteFileFolderProcess);
  yield takeLatest(deleteFileFolderMultiple.type, deleteFileFolderMultipleProcess);
  yield takeLatest(downloadFile.type, downloadFileProcess);
  yield takeLatest(downloadFileFolderMultiple.type, downloadFileMultipleProcess);
  yield takeLatest(getStatisticsHome.type, getStatisticsHomeProcess);
  yield takeLatest(setCurrentFileVersion.type, setCurrentFileVersionProcess);
  yield takeEvery(requestVersioningBucket.type, versioningBucketSaga);
  yield takeEvery(getBucketLoggingStatus.type, getBucketLoggingStatusProcess);
  yield takeEvery(setBucketLogging.type, setBucketLoggingProcess);
  yield takeEvery(getBucketVersioningStatus.type, getBucketVersioningStatusProcess);
  yield takeEvery(getBucketAcl.type, getBucketAclProcess);
  yield takeEvery(getFileAcl.type, getFileAclProcess);
  yield takeEvery(putBucketAcl.type, putBucketAclProcess);
  yield takeEvery(putFileAcl.type, putFileAclProcess);
  yield takeEvery(getShareList.type, getShareListProcess);
  yield takeEvery(putShareList.type, putShareListProcess);
  yield takeEvery(getObjectLockConfig.type, getObjectLockConfigProccess);
  yield takeEvery(putObjectLockConfig.type, putObjectLockConfigProccess);
  yield takeEvery(getObjectRetention.type, getObjectRetentionProccess);
  yield takeEvery(putObjectRetention.type, putObjectRetentionProcess);
  yield takeEvery(getObjectLegalHold.type, getObjectLegalHoldProcess);
  yield takeEvery(putObjectLegalHold.type, putObjectLegalHoldProcess);
  yield takeEvery(getBucketPolicy.type, getBucketPolicyProcess);
  yield takeEvery(putBucketPolicy.type, putBucketPolicyProcess);
  yield takeEvery(deleteBucketPolicy.type, deleteBucketPolicyProcess);
  yield takeEvery(blockFunctionsSuspendedAccountAction.type, blockFunctionsSuspendedAccountProcess);
  yield takeEvery(getListResourcesSharedWithMe.type, getListResourcesSharedWithMeProcess);
  yield takeEvery(deleteResourceFromSharedWithYouList.type, deleteResourceFromSharedWithYouListProcess);

  yield takeEvery(cancelUploadAction.type, cancelSingleUploadProcess);
  yield takeEvery(cancelAllUploadAction.type, cancelAllUploadsProcess);

  // new upload sagas
  yield takeLatest(prepareDroppedFilesAction.type, prepareDropedFilesSaga);
  yield takeEvery(startUploadAction.type, startUploadSaga);
}
