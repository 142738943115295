import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { selectCatalogState } from "./selectCatalogState";

export const selectTariffCatalog = createSelector([selectCatalogState], (state) => state.tariffCatalog);

export const useGetTariffFromCatalog = () => {
  const tariffsCatalog = useSelector(selectTariffCatalog);

  return (tariffId?: number | null) => {
    return tariffsCatalog.find((x) => x.id === tariffId);
  };
};
