import { useLocation } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();

  const get = (param: string) => {
    const params = new URLSearchParams(search);
    try {
      return params.get(param);
    } catch (e) {
      return null;
    }
  };
  return { get };
}

export function useQueryParams() {
  const { search } = useLocation();

  const params = new URLSearchParams(search.substring(search.indexOf("?") + 1));
  const map: Record<string, string | undefined> = {};
  for (const [key, value] of params.entries()) {
    map[key] = value;
  }
  return map;
}
