import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { ApiClient } from "../../../helpers/ApiClient";
import { setTonWalletPayload } from "../reducer";

export function* getTonConnectPayloadProcess() {
  try {
    const result: { payload: string } = yield call(ApiClient.getTonConnectPayload);
    yield put(setTonWalletPayload(result));
  } catch (err) {
    yield handleSagaError(err, "Settings");
  }
}
