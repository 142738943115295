export const changeArrayElementByIdx = <T, U>(idx: number, payload: T, array: U[]) => {
  return [...array.slice(0, idx), payload, ...array.slice(idx + 1)];
};

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const mergeObjectInArray = <T extends Record<string, any>, U extends T>(
  element: T,
  array: U[],
  criteria: keyof T
) => {
  const idx = array.findIndex((el) => el[criteria] === element[criteria]);
  if (idx === -1) {
    return array;
  }
  return [...array.slice(0, idx), { ...array[idx], ...element }, ...array.slice(idx + 1)];
};

export const voidCb = () => {};

export const notNil = <T>(obj: T | undefined | null): obj is T => {
  return obj === undefined || obj === null ? false : true;
};

export const isNil = <T = undefined | null>(obj: T | unknown): obj is T => {
  return !notNil(obj);
};

export const predicateOrUndefined = <T>(predicate: boolean, value: T) => (predicate ? value : undefined);

// Random
// https://stackoverflow.com/a/47593316
export const xmur3 = (str: string) => {
  let h = 1779033703 ^ str.length;
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = (h << 13) | (h >>> 19);
  }

  return () => {
    h = Math.imul(h ^ (h >>> 16), 2246822507);
    h = Math.imul(h ^ (h >>> 13), 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
};
