import * as React from "react";

function SvgUploadFile() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.0003 36.667V21.667M20.0003 21.667L25.8337 27.5003M20.0003 21.667L14.167 27.5003" stroke="#D4D7E0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M33.3337 29.3452C35.8232 28.3698 38.3337 26.1478 38.3337 21.6663C38.3337 14.9997 32.7782 13.333 30.0003 13.333C30.0003 9.99967 30.0003 3.33301 20.0003 3.33301C10.0003 3.33301 10.0003 9.99967 10.0003 13.333C7.22254 13.333 1.66699 14.9997 1.66699 21.6663C1.66699 26.1478 4.17746 28.3698 6.66699 29.3452" stroke="#D4D7E0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
}

export default SvgUploadFile;
