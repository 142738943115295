import * as React from "react";

export const SvgCheckboxChecked = ({ size, color = "#47C281" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z"
        fill={color}
      />
      <path d="M5 14L9 17L18 6" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export const SvgCheckboxUnChecked = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#D4D7E0" />
    </svg>
  );
};

export const SvgCheckboxCheckedGray = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="5" width="14" height="14" rx="2" stroke="#5A5D65" strokeWidth="2" />
      <path d="M8 12L11 15L16 9" stroke="#5A5D65" strokeWidth="2" />
    </svg>
  );
};
