import { PayloadAction } from "@reduxjs/toolkit";
import { CreateZipResponse } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { downloadFileByLink, isFolderType } from "helpers/common";
import { first, size } from "lodash";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import {
  closeConfirmModalAction,
  openConfirmModalAction,
  PrepareFilesForDownloadPayload,
  setZipInfo,
} from "../downloadSlice";

function* getFilesInfo(payload: PrepareFilesForDownloadPayload) {
  const { bucketName, files, pathFolder } = payload;
  yield put(openConfirmModalAction());

  const res: CreateZipResponse = yield call([s3Client, s3Client.createZip], bucketName, files, pathFolder);

  yield put(
    setZipInfo({
      loading: false,
      filesCnt: Number(res.Stats.FilesCount),
      foldersCnt: Number(res.Stats.FoldersCount),
      filesSizeSumReadable: res.Stats.FilesSizeSumReadable,
      zipId: res.ZipId,
    })
  );
}

export function* watchDownloadFiles(action: PayloadAction<PrepareFilesForDownloadPayload>) {
  const { bucketName, files, pathFolder } = action.payload;
  const firstFile = first(files);

  try {
    if (size(files) === 1 && firstFile && !isFolderType(firstFile)) {
      const downloadLink: {
        link?: string;
        name: string;
      } = yield call([s3Client, s3Client.getDownloadLinkS3], {
        nameBucket: bucketName,
        nameFile: firstFile.name,
        pathFolder,
        versionId: firstFile.version,
      });

      yield call(downloadFileByLink, downloadLink.link, downloadLink.name);
      return;
    }

    yield call(getFilesInfo, action.payload);
  } catch (err) {
    yield put(closeConfirmModalAction());
    yield handleSagaError(err);
  }
}
