import { takeLatest } from "redux-saga/effects";
import { getNotifications, getNotificationsPanel, setMakeAllReadNotifications } from "./notification";
import { getNotificationProcess } from "./saga/getNotificationProcess";
import { getNotificationsPanelProcess } from "./saga/getNotificationsPanelProcess";
import { setAllReadNotificationsProcess } from "./saga/setAllReadNotificationsProcess";

export function* notificationWatcher() {
  yield takeLatest(getNotifications.type, getNotificationProcess);
  yield takeLatest(getNotificationsPanel.type, getNotificationsPanelProcess);
  yield takeLatest(setMakeAllReadNotifications.type, setAllReadNotificationsProcess);
}
