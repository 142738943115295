import { takeEvery, takeLatest } from "redux-saga/effects";
import { deletePaymentMethodSaga } from "Redux/user/Sagas/deletePaymentMethodSaga";
import { getBillingHistoryProcess } from "Redux/user/Sagas/getBillingHistoryProcess";
import { getPaymentMethodSaga } from "Redux/user/Sagas/getPaymentMethodSaga";
import { setDefaultPaymentMethodSaga } from "Redux/user/Sagas/setDefaultPaymentMethodSaga";
import {
  changePasswordAction,
  checkIsAuthAction,
  checkProofTonWallet,
  createAccessKey,
  deleteAccessKey,
  deletePaymentMethod,
  getBillingHistory,
  getEmailSettings,
  getPaymetMethod,
  getSettingsInfo,
  getTonConnectPayload,
  initS3Action,
  setDefaultPaymentMethod,
  setEmailSettings,
  setStatusAccessKey,
} from "./Actions/userActions";
import { setUserDataSettings } from "./reducer";
import { changePasswordProcess } from "./Sagas/changePasswordProcess";
import { changeStatusAccessKeyProcess } from "./Sagas/changeStatusAccessKeyProcess";
import { checkAuthNew } from "./Sagas/checkAuthProcess";
import { checkProofTonWalletProcess } from "./Sagas/checkProofTonWalletProcess";
import { createAccessKeyProcess } from "./Sagas/createAccessKeyProcess";
import { deleteAccessKeyProcess } from "./Sagas/deleteAccessKeyProcess";
import { getEmailSettingsProcess } from "./Sagas/getEmailSettingsProcess";
import { getSettingsInfoProcess } from "./Sagas/getSettingsInfoProcess";
import { getTonConnectPayloadProcess } from "./Sagas/getTonConnectPayloadProcess";
import { init } from "./Sagas/init";
import { setEmailSettingsProcess } from "./Sagas/setEmailSettingsProcess";
import { setUserDataSettingsProcess } from "./Sagas/setUserDataSettingsProcess";

export function* userSaga() {
  yield takeLatest(checkIsAuthAction.type, checkAuthNew);
  yield takeLatest(getSettingsInfo.type, getSettingsInfoProcess);
  yield takeLatest(createAccessKey.type, createAccessKeyProcess);
  yield takeLatest(deleteAccessKey.type, deleteAccessKeyProcess);
  yield takeLatest(setStatusAccessKey.type, changeStatusAccessKeyProcess);
  yield takeEvery(initS3Action.type, init);
  yield takeEvery(getPaymetMethod.type, getPaymentMethodSaga);
  yield takeEvery(setDefaultPaymentMethod.type, setDefaultPaymentMethodSaga);
  yield takeEvery(deletePaymentMethod.type, deletePaymentMethodSaga);
  yield takeEvery(getBillingHistory.type, getBillingHistoryProcess);
  yield takeLatest(getEmailSettings.type, getEmailSettingsProcess);
  yield takeLatest(setEmailSettings.type, setEmailSettingsProcess);
  yield takeLatest(setUserDataSettings.type, setUserDataSettingsProcess);
  yield takeLatest(changePasswordAction.type, changePasswordProcess);
  yield takeLatest(getTonConnectPayload.type, getTonConnectPayloadProcess);
  yield takeLatest(checkProofTonWallet.type, checkProofTonWalletProcess);
}
