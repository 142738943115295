import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { setToken } from "shared/src/helpers/token";
import { ApiClient } from "../../../helpers/ApiClient";
import { CheckProofPayload } from "../../../models/CheckProofTonWallet";
import { checkIsAuthAction } from "../Actions/userActions";

export function* checkProofTonWalletProcess({ payload }: PayloadAction<CheckProofPayload>) {
  try {
    const result: { accessToken: string } = yield call(ApiClient.checkProofWallet, payload);
    setToken(result.accessToken);
    yield put(checkIsAuthAction());
  } catch (err) {
    yield handleSagaError(err, "Settings");
  }
}
