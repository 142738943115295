import Button from "components/UI/Button/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDisabledBucketVersioningModal } from "Redux/ui/uiState";
import { CoreModal } from "../Modal";
import style from "./disabledBucketVersioning.module.scss";

type DisabledBucketVersioningProps = {
  visible: boolean;
};
export const DisabledBucketVersioning: React.FC<DisabledBucketVersioningProps> = ({ visible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onClose = () => {
    dispatch(setDisabledBucketVersioningModal(false));
  };
  return (
    <CoreModal
      title="You are suspending versioning, it cannot be disabled completely"
      className={style.wrap}
      closeOutClick={true}
      onClose={onClose}
      visible={visible}
      titleMargin="10px"
    >
      <p>Things to note:</p>
      <ul className={style.ul}>
        <li>Suspending versioning stops any new versions of the objects from being created.</li>
        <li>Suspending versioning has no impact on existing objects in the bucket.</li>
      </ul>
      <Button color="secondary" size="buttonlarge" onClick={onClose}>
        {t("Ok")}
      </Button>
    </CoreModal>
  );
};
