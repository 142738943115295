import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { AccessKey } from "models/AccessKey";
import { Notification, NotificationRep } from "models/Notification";
import { Pagination } from "models/Pagination";
import { BillingHistoryTO } from "shared/src/models/BillingHistory";
import { StripeCard } from "shared/src/models/StripeCard";
import { UserProfile, UserStatusEnum } from "shared/src/models/UserModel";
import { IEditUserData, IIsAuthorized } from "../../actions/interfaces";

export interface IUserState {
  isAuthorized: IIsAuthorized;
  userData: null | UserProfile;
  accessKeys: AccessKey[];
  authError: {
    open: boolean;
    errorText: string;
  };
  accessKeyCurrent: AccessKey;
  notifications: NotificationRep;
  notificationsPagination: Pagination;
  notificationsPanel: NotificationRep;
  billingHistory?: BillingHistoryTO;
  email: UserProfile;
  redirect?: string;
  payment: {
    cards?: StripeCard[];
  };
  tonWalletPayload?: { payload: string };
}
export const initPagination = { page: 1, perPage: 10, pagesCount: 1 };

const initialState: IUserState = {
  isAuthorized: { checked: false, result: false } as IIsAuthorized,
  authError: {
    errorText: "",
    open: false,
  },
  userData: null,
  accessKeys: [],
  accessKeyCurrent: {} as AccessKey,
  email: {} as UserProfile,
  notifications: {} as NotificationRep,
  notificationsPagination: initPagination,
  notificationsPanel: {
    items: [] as Notification[],
  } as NotificationRep,
  billingHistory: undefined,
  payment: {
    cards: undefined,
  },
};
const getUserDataStateOrFail = (state: IUserState) => {
  const userData = state.userData;
  if (!userData) throw new Error();
  return cloneDeep(userData);
};

type SetSettingsInfoPayload = any;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUnAuthorizedAction() {
      return { ...initialState, isAuthorized: { checked: true, result: false } };
    },
    setUserData(state, action: PayloadAction<{ user: UserProfile; authResult: IIsAuthorized }>) {
      return {
        ...state,
        userData: { ...state.userData, ...action.payload.user } as UserProfile,
        isAuthorized: action.payload.authResult,
      };
    },
    setPaymetMethod(state, action: PayloadAction<StripeCard[]>) {
      return { ...state, payment: { ...state.payment, cards: action.payload } };
    },
    setAccessKeys(state, action: PayloadAction<AccessKey[]>) {
      return { ...state, accessKeys: action.payload };
    },
    setCurrentAccessKey(state, action: PayloadAction<AccessKey>) {
      return { ...state, accessKeyCurrent: action.payload };
    },
    setSettingsInfo(state, action: PayloadAction<SetSettingsInfoPayload>) {
      return { ...state, userData: action.payload };
    },
    setBillingHistory(state, action: PayloadAction<BillingHistoryTO>) {
      return {
        ...state,
        billingHistory: action.payload,
      };
    },
    setNextTariffIdAction(state, action: PayloadAction<number | undefined>) {
      const userData = getUserDataStateOrFail(state);
      userData.nextTariffId = action.payload;
      return { ...state, userData };
    },
    updateUserStatusAction(state, action: PayloadAction<UserStatusEnum>) {
      const userData = getUserDataStateOrFail(state);
      userData.status = action.payload;
      return { ...state, userData };
    },
    setUserDataSettings(state, action: PayloadAction<IEditUserData>) {
      const userData = getUserDataStateOrFail(state);
      return { ...state, userData: { ...userData, ...action.payload } };
    },
    setTonWalletPayload(state, action: PayloadAction<{ payload: string }>) {
      return { ...state, tonWalletPayload: action.payload };
    },
  },
});

export const userReducer = userSlice.reducer;

export const {
  setUnAuthorizedAction,
  setUserData,
  setPaymetMethod,
  setAccessKeys,
  setCurrentAccessKey,
  setSettingsInfo,
  setBillingHistory,
  setNextTariffIdAction,
  updateUserStatusAction,
  setUserDataSettings,
  setTonWalletPayload,
} = userSlice.actions;
