import { useTonConnectUI } from "@tonconnect/ui-react";
import clsx from "clsx";
import { DropDownMenu } from "components/Header/DropDownMenu";
import { format } from "helpers/date-fns";
import { useOnClickOutside } from "helpers/hooks/useOnClickOutside";
import { Calendar, NavArrowDown, Timer, User, WarningTriangleOutline } from "iconoir-react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectTariffUserPlan } from "Redux/account/Selectors/selectTariffUserPlan";
import { setUnAuthorizedAction } from "Redux/user/reducer";
import { selectUserProfile } from "Redux/user/Selectors/selectUserState";
import { deleteToken } from "shared/src/helpers/token";
import { UserStatusEnum } from "shared/src/models/UserModel";
import style from "./UserMenu.module.scss";

type UserStatusProps = {
  status: UserStatusEnum;
};

const UserStatusIcon: React.FC<UserStatusProps> = ({ status }) => {
  switch (true) {
    case status === UserStatusEnum.trial:
      return <Timer className={style.success} width="24" height="24" />;

    case status === UserStatusEnum.active:
      return <Calendar className={style.success} width="24" height="24" color="#47C281" />;

    case [
      UserStatusEnum.payment_required,
      UserStatusEnum.suspended,
      UserStatusEnum.expired,
      UserStatusEnum.unsubscribed,
    ].includes(status):
      const willExpiredSoon = status === UserStatusEnum.expired;
      return (
        <WarningTriangleOutline
          className={clsx(willExpiredSoon ? style.warning : style.danger)}
          width="24"
          height="24"
        />
      );

    default:
      return <Timer className={style.success} width="24" height="24" color="#47C281" />;
  }
};

const UserMenuText: React.FC<UserStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const userPlan = useSelector(selectTariffUserPlan);
  const classes = clsx(style.nowrap);

  const defaultText = (
    <span data-test="activeUtil" className={classes}>
      {t("active until")} {format(userPlan?.end_at, "dd/MM/yy")}
    </span>
  );

  switch (true) {
    case status === UserStatusEnum.trial:
      return (
        <span data-test="trialUntil" className={classes}>
          {t("trial until")} {format(userPlan?.end_at, "dd/MM/yy")}
        </span>
      );

    case status === UserStatusEnum.expired: {
      return (
        <span data-test="expireOn" className={classes}>
          {t("expire on")} {format(userPlan?.end_at, "dd/MM/yy")}
        </span>
      );
    }

    case status === UserStatusEnum.unsubscribed: {
      return <span className={clsx(classes, style.danger, style.capitalize)}>{t("unsubscribed")}</span>;
    }

    case status === UserStatusEnum.suspended: {
      return <span className={clsx(classes, style.danger)}>{t("suspended")}</span>;
    }

    case status === UserStatusEnum.payment_required: {
      return <span className={clsx(classes, style.danger)}>{t("payment required")}</span>;
    }

    default:
      return defaultText;
  }
};

type UserMenuProps = {
  openSearch?: boolean;
};

export const UserMenu: React.FC<UserMenuProps> = ({ openSearch }) => {
  const [open, setOpen] = useState(false);
  const dropPanelRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [tonConnectUI] = useTonConnectUI();
  const dispatch = useDispatch();

  const userProfile = useSelector(selectUserProfile);
  const status = userProfile.status;

  const onLogout = async () => {
    await tonConnectUI.disconnect();
    deleteToken();
    dispatch(setUnAuthorizedAction());
  };

  useOnClickOutside(rootRef, () => setOpen(false));

  return (
    <div ref={rootRef} className={style.relative}>
      <div
        className={clsx(style.wrapPanel, openSearch && style.hidden)}
        ref={dropPanelRef}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className={style.panel}>
          <div className={style.flex}>
            <div className={style.walletIcon}>
              <UserStatusIcon status={status} />
            </div>
            <div className={style.statusTariff}>
              <UserMenuText status={status} />
            </div>
          </div>
          <div className={style.avatar}>
            <User width="24" height="24" color="#6E7E9D" />
          </div>
        </div>
        <div className={style.arrow}>
          <div style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.1s" }}>
            <NavArrowDown color={open ? "#1FCF90" : "#5A5D65"} />
          </div>
        </div>
      </div>

      {open && <DropDownMenu onLogout={onLogout} ref={dropRef} onClose={() => setOpen(false)} />}
    </div>
  );
};
