import { IGuideRegion, IStorageClass } from "actions/interfaces";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { apiUrl, fetchApi } from "helpers/common";
import { setRegions, setStorageClasses } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getBucketsGuidesProcess() {
  try {
    const classes: AxiosResponse<IStorageClass[]> = yield call(fetchApi, "get", "200", {
      url: `/guide/storage-class`,
      mainUrl: apiUrl,
    });
    const regions: AxiosResponse<IGuideRegion[]> = yield call(fetchApi, "get", "200", {
      url: `/guide/region`,
      mainUrl: apiUrl,
    });
    yield put(setStorageClasses(classes?.data));
    yield put(setRegions(regions?.data));
  } catch (err) {
    yield handleSagaError(err);
  }
}
