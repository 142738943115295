import { PayloadAction } from "@reduxjs/toolkit";
import { ApiClient } from "helpers/ApiClient";
import { AccessKey } from "models/AccessKey";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { fetchApi, urlAuthNode } from "../../../helpers/common";
import { CreateAccessKeyPayloadAction } from "../Actions/userActions";
import { setAccessKeys, setCurrentAccessKey } from "../reducer";

export function* createAccessKeyProcess({ payload }: PayloadAction<CreateAccessKeyPayloadAction>) {
  try {
    const res = yield call(fetchApi, "post", "201", {
      url: "/access-keys",
      body: { active: true },
      mainUrl: urlAuthNode,
    });
    yield put(setCurrentAccessKey(res));
    payload.onSuccess();
    const accessKeys: AccessKey[] = yield call(ApiClient.getAccessKeys);
    yield put(setAccessKeys(accessKeys));
  } catch (err) {
    yield handleSagaError(err, "Access keys");
  }
}
