import { nanoid } from "nanoid";
import React, { FC, useRef } from "react";
import style from "./inputFile.module.scss";

export interface IInputFileProps {
  id: string;
  onInputChangeHandler: (fileList: FileList) => void;
  acceptTypes?: string[];
  disabled?: boolean;
}

export const InputFile: FC<IInputFileProps> = ({ id, onInputChangeHandler, disabled = false, children }) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const _id = useRef(nanoid()).current;

  const onChangeFile = (fileList: FileList | null) => {
    if (fileList) {
      onInputChangeHandler(fileList);
    }
  };

  const open = () => {
    const $input = inputFileRef.current;
    $input?.click();
  };

  return (
    <div className={style.wrapperButton}>
      <label onClick={open} htmlFor={_id} className={style.container}>
        {children}
      </label>
      <input
        disabled={disabled}
        type="file"
        id={_id}
        ref={inputFileRef}
        multiple={true}
        hidden={true}
        onChange={(e) => onChangeFile(e?.target?.files)}
      />
    </div>
  );
};
