import clsx from "clsx";
import { Stack } from "components/Stack/Stack";
import { xmur3 } from "helpers/utils";
import React, { useState } from "react";
import styles from "./Avatar.module.scss";

export enum ColorsPaletteEnum {
  green = "#339963",
  red = "#E66C4E",
  yellow = "#F0B90B",
  blue = "#60A5FA",
  indigo = "#6875F5",
  purple = "#9061F9",
  pink = "#E74694",
}

export type AvatarProps = {
  seedStrings: string[];
  name?: string;
  src?: string;
  size?: number;
  id?: string;
  className?: string;
  cutName?: boolean;
};

export const Avatar: React.FC<AvatarProps> = ({
  seedStrings,
  name = "?",
  src,
  size = 24,
  className,
  cutName = true,
  id,
}) => {
  const seed = seedStrings.join("");
  const colors = Object.values(ColorsPaletteEnum);
  const random = xmur3(seed)() % colors.length;
  const firstLeter = name.charAt(0);

  return (
    <div
      id={id}
      className={clsx(styles.avatar, className)}
      style={{
        background: src ? `url(${src})` : `${colors[random]}`,
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${size}px`,
      }}
    >
      {!src && <span className={styles.letter}>{cutName ? firstLeter : name}</span>}
    </div>
  );
};

export const TestAvatar = () => {
  const [avatarSizes, setAvatarSizes] = useState(24);

  return (
    <>
      <select value={avatarSizes} onChange={({ target }) => setAvatarSizes(+target.value)}>
        <option value="24">24</option>
        <option value="60">60</option>
        <option value="120">120</option>
      </select>
      <div className={styles.test}>
        <Avatar size={avatarSizes} seedStrings={["Evgenii Bisk"]} name="K" />
        <Avatar
          size={avatarSizes}
          src="https://cdn-icons-png.flaticon.com/512/147/147144.png"
          seedStrings={["Evgenii adsd"]}
        />

        <Stack>
          <Avatar size={avatarSizes} seedStrings={["Evgenii Bisk"]} name="K" />
          <Avatar size={avatarSizes} seedStrings={["dasda Bisk"]} name="F" />
          <Avatar size={avatarSizes} seedStrings={["Evgenii dasda"]} name="G" />
          <Avatar size={avatarSizes} seedStrings={["1231231saf Bisk"]} name="T" />
        </Stack>
      </div>
    </>
  );
};
