import { ActionFromReducersMapObject, combineReducers, Reducer, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { account } from "Redux/account/reducer";
import { billingInfoUiReducer } from "Redux/BillingInfoUi/billingInfoUi";
import { buckets } from "Redux/buckets/reducer";
import { uiStateReducer } from "Redux/ui/uiState";
import { userReducer } from "Redux/user/reducer";
import { catalogReducer } from "./catalogs/catalogSlice";
import { downloadReducer } from "./download/downloadSlice";
import { errorReducer } from "./ErrorReducer/ErrorReducer";
import { notificationReducer } from "./notification/notification";

const reducers = {
  user: userReducer,
  buckets: buckets,
  account: account,
  ui: uiStateReducer,
  billingInfoUi: billingInfoUiReducer,
  error: errorReducer,
  notifications: notificationReducer,
  downloadReducer,
  catalog: catalogReducer,
};

type ReducersMapObject = typeof reducers;

export const rootReducer: Reducer<
  StateFromReducersMapObject<ReducersMapObject>,
  ActionFromReducersMapObject<ReducersMapObject>
> = combineReducers(reducers);

export type TStore = ReturnType<typeof rootReducer>;
