import { formatBytes } from "helpers/common";
import { createSelector } from "reselect";
import { selectBucketsState } from "./selectBucketsState";

export const selectBucketState = createSelector([selectBucketsState], (x) => x);

export const selectUploadState = createSelector([selectBucketsState], (state) => Object.values(state.uploadState));

export const selectUploadStateInfo = createSelector([selectBucketsState], (state) => state.uploadStateInfo);

export const selectPrettyTotalUploadSize = createSelector([selectUploadStateInfo], (state) => {
  const { filesTotalSize } = state;
  return filesTotalSize ? formatBytes(filesTotalSize) : "0 KB";
});

export const selectTotalUpload = createSelector([selectUploadState, selectUploadStateInfo], (uploads, info) => {
  const { filesTotalSize } = info;

  let totalLoaded = 0;

  for (const upload of uploads) {
    totalLoaded += upload.progressValues.loaded;
  }
  return Math.trunc((totalLoaded / filesTotalSize) * 100 || 0);
});
