import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { accountSaga } from "Redux/account/sagasWatcher";
import { bucketsSaga } from "Redux/buckets/sagasWatcher";
import { catalogsSaga } from "Redux/catalogs/sagasWatcher";
import { downloadFilesWathcer } from "Redux/download/sagaWatcher";
import { notificationWatcher } from "Redux/notification/notificationWatcher";
import { rootReducer } from "Redux/rootReducer";
import { uiSagaWatcher } from "Redux/ui/sagaWathcer";
import { userSaga } from "Redux/user/sagasWatcher";

const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });

  function* rootSaga() {
    yield all([
      fork(userSaga),
      fork(bucketsSaga),
      fork(accountSaga),
      fork(uiSagaWatcher),
      fork(notificationWatcher),
      fork(catalogsSaga),
      yield fork(downloadFilesWathcer),
    ]);
  }
  sagaMiddleware.run(rootSaga);

  return store;
};
