import { PayloadAction } from "@reduxjs/toolkit";
import { put, select } from "redux-saga/effects";
import { UserStatusEnum } from "shared/src/models/UserModel";
import { setSuspendedModal } from "../../ui/uiState";
import { selectUserProfile } from "../../user/Selectors/selectUserState";

export function* blockFunctionsSuspendedAccountProcess(payload: PayloadAction<() => void>) {
  const userProfile = yield select(selectUserProfile);
  if (userProfile.status === UserStatusEnum.suspended) {
    yield put(setSuspendedModal(true));
  } else {
    payload.payload();
  }
}
