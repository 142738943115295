import { call, put } from "redux-saga/effects";
import { apiUrl, fetchApi } from "helpers/common";
import { setPriceLanding } from "Redux/account/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getPriceLandingProcess() {
  try {
    const price = yield call(fetchApi, "get", "200", {
      url: `/billing/costs-estimator-params`,
      mainUrl: apiUrl,
      query: { format: "json" },
    });

    yield put(setPriceLanding(price));
  } catch (err) {
    console.error("catch getPriceLandingProcess", err);
    yield handleSagaError(err, "Get price landing data");
  }
}
