import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { setCommonError } from "Redux/ErrorReducer/ErrorReducer";
import { handleSagaError } from "Redux/helpers";
import { setActionButtonState } from "Redux/ui/uiState";
import { parseError } from "shared/src/helpers/errorHandler";
import { fetchApi, urlAuthNode } from "../../../helpers/common";
import { getEmailSettings, SetEmailSettingPayload } from "../Actions/userActions";

export function* setEmailSettingsProcess({ payload }: PayloadAction<SetEmailSettingPayload>) {
  try {
    yield call(fetchApi, "put", "200", {
      url: "/me",
      body: { email: payload },
      mainUrl: urlAuthNode,
    });
    yield put(getEmailSettings());
  } catch (err) {
    const { code } = parseError(err);
    if (Number(code) === 422) {
      yield put(
        setCommonError({
          message: "Sorry, but before you be able to change email address, you must change your password.",
          isBottomText: false,
        })
      );
    }
    yield handleSagaError(err, "Settings");
    yield put(setActionButtonState(false));
  }
}
