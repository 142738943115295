import { dashClient } from "helpers/HttpClient";
import { BaseRestClient } from "shared/src/http";
import { TariffDto } from "shared/src/models/TariffDto";

export class CatalogApi {
  dashClient: BaseRestClient;

  constructor() {
    this.dashClient = dashClient;
  }

  async getTarriffsData(): Promise<TariffDto[]> {
    const rep = await this.dashClient.apiGet<TariffDto[]>({ url: "/tariff/all" });
    return rep;
  }
  async getTariffById(id: number): Promise<TariffDto> {
    const rep = await this.dashClient.apiGet<TariffDto>({ url: `/tariff/${id}` });
    return rep;
  }
}

export const catalogApi = new CatalogApi();
