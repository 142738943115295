import { isEqual } from "lodash";
// TODO find way to use it from @reduxjs/toolkit
import { createSelectorCreator, defaultMemoize } from "reselect";
import { selectBucketsState } from "./selectBucketsState";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const selectStatisticsHome = createDeepEqualSelector(
  [selectBucketsState],
  (bucketsState) => bucketsState.statisticsHome
);
