import { NotUndefined } from "@redux-saga/types";
import S3 from "aws-sdk/clients/s3";
import { END, EventChannel, eventChannel } from "redux-saga";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { cancelAllUploadAction, getFilesList } from "../Actions/bucketsActions";
import { setUpload, updateUploadProgress, Upload } from "../reducer";
import { selectUpload } from "../Selectors/selectUpload";
import { selectUploadState } from "../Selectors/selectUploadInfo";
import { UploadManager } from "./prepareDropedFilesSaga";

function uploadAttach(upload: UploadManager) {
  return eventChannel((emitter) => {
    const onUploadProgress = (progress: S3.ManagedUpload.Progress) => {
      const { loaded, total } = progress;
      const loadedPercent = (loaded / total) * 100;
      emitter({ loadedPercent, loaded, total, filepath: upload.filemeta.filepath });
    };

    upload.instance.on("httpUploadProgress", (progress) => onUploadProgress(progress));

    upload.instance
      .promise()
      .then(() => emitter(END))
      .catch(() => emitter(END));

    return () => {
      // leave unsubscribe function empty, beacuse function below will invoke
      // .abort() UploadManger mathod while `httpUploadProgress` is in process
      // cancelUploadS3(upload);
    };
  });
}

export function* singleUploadSaga(upload: UploadManager) {
  try {
    const channel: EventChannel<NotUndefined> = yield call(uploadAttach, upload);
    yield takeEvery(channel, function* (progress) {
      yield put(updateUploadProgress(progress as any));
    });
  } catch (e) {
    console.log("channel error", e);
  }
}

export function* startUploadSaga() {
  const uploads: UploadManager[] = yield select(selectUploadState);
  const { nameBucket, pathFolder, sharing, callBack }: Upload = yield select(selectUpload);

  yield put(setUpload({ isLoadingFiles: true }));
  yield all(uploads.map(singleUploadSaga));
  const pagination = yield select((store) => store.buckets?.currentBucket?.pagination);

  if (!sharing) {
    yield put(
      getFilesList({
        nameBucket,
        pathFolder,
        afterLoad: () => {},
        pagination: {
          Page: pagination?.page || 1,
          PerPage: pagination?.perPage || 10,
        },
      })
    );
  }
  if (callBack) {
    callBack();
  }

  yield put(cancelAllUploadAction());
}
