import { StorageClass } from "aws-sdk/clients/s3";

export interface BucketSharingList {
  BucketSharingList: {
    Bucket: string;
    SharingList: SharingListItem[];
  };
}

export interface PrefixSharingListRoot {
  PrefixSharingList: {
    SharingList: SharingListItem[];
  };
}

export interface KeySharingListRoot {
  KeySharingList: {
    SharingList: SharingListItem[];
  };
}

export interface SharingListItem {
  UserEmailAddress: string;
  Role: SharingListItemRole;
}
export enum SharingListItemRole {
  Reader = "Reader",
  Writer = "Writer",
}

export enum SharingListType {
  Bucket = "Bucket",
  Folder = "Folder",
  File = "File",
}

export interface BreadcrumbBucket {
  Type: "Bucket";
  Breadcrumb: string;
  CanRead: boolean;
}

export interface BreadcrumbPrefix {
  Type: "Prefix";
  Breadcrumb: string;
  Prefix: string;
  CanRead: boolean;
}

export type Breadcrumbs = [BreadcrumbBucket, ...BreadcrumbPrefix[]];
export interface SharedBucket {
  Name: string;
  CreationDate: string;
  ObjectsCount: string;
  ObjectsWithoutFoldersCount: string;
  VersioningStatus: "Disabled" | "Enabled" | "Suspended";
  Region: { Code: string; Name: string };
  Owner: {
    DisplayName: string;
    ID: string;
    EmailAddress: string;
  };
  Size: string;
  SizeReadable: string;
}

export interface BucketSharedWithMe {
  Type: "Bucket";
  Bucket: SharedBucket;
  Role: SharingListItemRole;
}

export interface SharedPrefix {
  Bucket: string;
  Prefix: string;
  Size: string;
  SizeReadable: string;
  LastModified: string;
  Owner: {
    DisplayName: string;
    ID: string;
    EmailAddress: string;
  };
}

export interface PrefixSharedWithMe {
  Type: "Prefix";
  Prefix: SharedPrefix;
  Role: SharingListItemRole;
}

export interface SharedKey {
  Bucket: string;
  Key: string;
  LastModified: string;
  ETag: string;
  Size: string;
  SizeReadable: string;
  StorageClass: StorageClass;
  StorageClassReadable: string;
  ContentType?: string | undefined | null;
  FileType: string;
  Owner: {
    DisplayName: string;
    ID: string;
    EmailAddress: string;
  };
}

export interface KeySharedWithMe {
  Type: "Key";
  Key: SharedKey;
  Role: SharingListItemRole;
}

export type ResourceSharedWithMe = BucketSharedWithMe | PrefixSharedWithMe | KeySharedWithMe;

export type GetResourcesSharedWithMeQuery = {
  page?: number;
  perPage?: number;
  bucket?: string;
  prefix?: string;
  delimiter?: string;
  excludeRootFolder?: boolean;
};

export interface ResourcesSharedWithMe {
  ResourcesSharedWithMe: {
    Page: number;
    PagesCount: number;
    PerPage: number;
    Role?: SharingListItemRole;
    Bucket?: string;
    Prefix?: string;
    AvailableItemsCount: number;
    Resources: ResourceSharedWithMe[];
    Breadcrumbs?: Breadcrumbs;
  };
}

export type DeleteResourceFromSharedWithYouListParams = {
  bucket: string;
  prefix?: string;
  key?: string;
};

export class SharingEntityVm {
  public name: string;
  public id: string;
  public type: "Key" | "Prefix" | "Bucket";
  public owner: string;
  public role: SharingListItemRole;
  public size: string;
  public bucket?: string;
  public date: Date;

  constructor(entity: BucketSharedWithMe | PrefixSharedWithMe | KeySharedWithMe) {
    this.name = "";
    this.type = entity.Type;
    this.owner = "Owner";
    this.role = entity.Role;
    this.size = "";
    this.id = "";
    this.date = new Date();

    switch (entity.Type) {
      case "Bucket":
        this.name = entity.Bucket.Name;
        this.size = entity.Bucket.SizeReadable;
        this.date = new Date(entity.Bucket.CreationDate);
        this.owner = entity.Bucket.Owner.EmailAddress;
        this.id = entity.Bucket.Owner.ID;
        this.bucket = entity.Bucket.Name;

        break;

      case "Key":
        this.name = entity.Key.Key;
        this.size = entity.Key.SizeReadable;
        this.date = new Date(entity.Key.LastModified);
        this.owner = entity.Key.Owner.EmailAddress;
        this.id = entity.Key.Owner.ID;
        this.bucket = entity.Key.Bucket;

        break;

      case "Prefix":
        this.name = entity.Prefix.Prefix;
        this.size = entity.Prefix.SizeReadable;
        this.date = new Date(entity.Prefix.LastModified);
        this.owner = entity.Prefix.Owner.EmailAddress;
        this.id = entity.Prefix.Owner.ID;
        this.bucket = entity.Prefix.Bucket;

        break;
    }
  }
}
