import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectUserData } from "Redux/user/Selectors/selectUserData";

export const RequiredFinishedRegistrationRoute: React.FC = ({ children }) => {
  const userData = useSelector(selectUserData);
  const emailNotVerify = !userData?.emailVerified;

  if (emailNotVerify) return <Navigate to="/dashboard/email-verify" />;
  return <>{children}</>;
};
