import { PayloadAction } from "@reduxjs/toolkit";
import { ApiClient } from "helpers/ApiClient";
import { NotificationRep } from "models/Notification";
import { Pagination } from "models/Pagination";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { GetNotificationPayload } from "../notification";
import { setNotifications } from "../notification";

export function* getNotificationProcess({ payload }: PayloadAction<GetNotificationPayload>) {
  try {
    const { page, perPage, id } = payload?.pagination;
    const query = { id, page, perPage } as Pagination;
    const result: NotificationRep = yield call(ApiClient.getNotifications, query);
    yield put(setNotifications(result));
  } catch (err) {
    yield handleSagaError(err, "Get Notification Process");
  }
}
