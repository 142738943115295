import { defaultError } from "helpers/errorHandler";
import { put, select, takeEvery } from "redux-saga/effects";
import { setCommonError } from "./ErrorReducer/ErrorReducer";
import { selectAsyncButtonStore } from "./ui/selectAsyncButtonState";
import { AsyncButtonStateMap, clearAllLoadingAsyncButtonState, removeLoadingAsyncButton } from "./ui/uiState";

export function* handleSagaError(error: unknown, label?: string) {
  const err = error as { code?: string; message?: string };

  console.error(error);

  if (err?.code || err?.message) {
    const errMessage = label ? `${label}: ${err?.message}` : err?.message!;

    yield put(setCommonError({ message: err?.message ? errMessage : (err?.code as string), isBottomText: false }));
  } else {
    yield put(setCommonError({ message: defaultError, isBottomText: true }));
  }
}

export function* watchAsyncButton() {
  yield takeEvery("*", function* (action: { type: string }) {
    if (action.type === setCommonError.type) {
      yield put(clearAllLoadingAsyncButtonState());
      return;
    }

    const asyncButtonStore: AsyncButtonStateMap = yield select(selectAsyncButtonStore);

    if (asyncButtonStore[action.type]) {
      yield put(removeLoadingAsyncButton(action.type));
    }
  });
}
