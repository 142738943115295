import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { S3 } from "aws-sdk";
import { GetFileAclPayload } from "models/ACL";
import { call, put } from "redux-saga/effects";
import { setAclGrants, setAclOwner } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getFileAclProcess({ payload }: PayloadAction<GetFileAclPayload>) {
  try {
    const aclOutput: S3.GetObjectAclOutput = yield call([s3Client, s3Client.getFileAcl], payload);
    if (aclOutput.Grants) {
      yield put(setAclGrants(aclOutput.Grants));
    }
    if (aclOutput.Owner) {
      yield put(setAclOwner(aclOutput.Owner));
    }
  } catch (err) {
    yield handleSagaError(err);
  }
}
