import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { INewFolder } from "containers/BucketPage/BucketPage";
import { methods, processError } from "helpers/errorHandler";
import { call, put, select } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { getFilesList } from "../Actions/bucketsActions";

export function* createFolderProcess({ payload }: PayloadAction<INewFolder>) {
  const { nameBucket, nameFolder, pathFolder, onSuccess, setOuterError, sharing } = payload;
  try {
    yield call([s3Client, s3Client.createFolderS3], { nameBucket, nameFolder: `${nameFolder}/`, pathFolder });
    const pagination = yield select((store) => store.buckets?.currentBucket?.pagination);
    if (!sharing) {
      yield put(
        getFilesList({
          excludeRootFolder: "",
          nameBucket,
          pathFolder,
          afterLoad: () => {},
          pagination: { Page: pagination?.page || 1, PerPage: pagination?.perPage || 10 },
        })
      );
    }

    onSuccess?.();
  } catch (err) {
    if (err?.message && setOuterError) {
      setOuterError(err?.message);
    } else {
      const text = processError(err, methods.putObject);
      if (text?.length > 0 && setOuterError) {
        setOuterError(text);
      } else {
        yield handleSagaError(err);
      }
    }
  }
}
