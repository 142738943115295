import axios from "axios";

export const isError = (val: any): val is { code: string; message: string } => {
  return val && "code" in val && "message" in val;
};
export const parseError = (e: unknown): { code: string; message: string } => {
  if (axios.isAxiosError(e)) {
    const { message, statusCode } = e.response?.data as { message: string; statusCode: number };
    return {
      code: statusCode ? statusCode?.toString() : "500",
      // @ts-expect-error nice
      message: typeof message === "object" ? JSON.stringify(message?.message) : message ?? "Internal Error",
    };
  }

  const defaultError = { code: "InternalError", message: "Internal Error" };
  let { code, message } = isError(e) ? e : defaultError;
  code = code.toString();

  return { code, message };
};
