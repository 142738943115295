import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { apiUrl, fetchApi } from "../../../helpers/common";
import { getTickets } from "../Actions/accountActions";
import { handleSagaError } from "Redux/helpers";

export function* deleteTicketProcess({ payload }: PayloadAction<string>) {
  try {
    yield call(fetchApi, "delete", "200", { url: `/tickets/${payload}`, mainUrl: apiUrl });
    yield put(
      getTickets({
        status: "close",
        afterLoad: () => {},
      })
    );
  } catch (err) {
    yield handleSagaError(err, "Delete ticket");
  }
}
