import { PayloadAction } from "@reduxjs/toolkit";
import { IFilesListData } from "actions/interfaces";
import { IContentsFile, IGetListFiles } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { call, put, select } from "redux-saga/effects";
import { setFilesList } from "Redux/buckets/reducer";
import { selectShowFileVersionsInList } from "Redux/buckets/Selectors/selectShowFileVersionsInList";
import { handleSagaError } from "Redux/helpers";

export function* getFilesListProcess({ payload }: PayloadAction<IFilesListData>) {
  const { pagination, nameBucket, pathFolder, afterLoad, excludeRootFolder } = payload;
  const { Page, PerPage } = pagination;
  try {
    const showVersions = yield select(selectShowFileVersionsInList);
    const data: IGetListFiles = yield call([s3Client, s3Client.getListFiles], {
      nameBucket,
      pathFolder,
      Page,
      PerPage,
      excludeRootFolder,
      showVersions,
    });
    const list = data?.ListExtendedObjects;
    const filesListNotEmpty = list?.Contents.filter((el) => {
      return el?.Key !== list.Prefix;
    });
    const filesList: IContentsFile[] = filesListNotEmpty
      ? filesListNotEmpty.map((el) => {
          let key = el?.Key;
          const folder = `${pathFolder}/`;
          if (key.indexOf(folder) === 0) {
            key = key.replace(folder, "");
          }
          return { ...el, Key: key, originalKey: el?.Key };
        })
      : [];

    const foldersList = list?.CommonPrefixes
      ? list?.CommonPrefixes.map((el) => {
          let key = el?.Prefix;
          const folder = `${pathFolder}/`;
          if (key.indexOf(folder) === 0) {
            key = key.replace(folder, "");
          }
          return { ...el, Key: key };
        })
      : [];
    const pagination = {
      page: list?.Page > list?.PagesCount ? (list?.PagesCount === 0 ? 1 : list?.PagesCount) : list?.Page,
      perPage: list?.PerPage,
      pagesCount: list?.PagesCount,
    };
    yield put(
      setFilesList({
        filesList: filesList,
        foldersList: foldersList,
        nameBucket: nameBucket,
        pathFolder: pathFolder,
        pagination: pagination,
      })
    );

    afterLoad();
  } catch (err) {
    yield handleSagaError(err);
    afterLoad();
  }
}
