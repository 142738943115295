import * as React from "react";

function SvgHeadPhones() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12V17" stroke="#1B2A47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 21.0091L12.01 20.998" stroke="#1B2A47" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
      <path d="M4 13.5V13C4 8.02944 7.58172 4 12 4C16.4183 4 20 8.02944 20 13V13.5" stroke="#1B2A47" strokeWidth="1.5"
            strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M2 17.4383V15.5614C2 14.6437 2.62459 13.8437 3.51493 13.6211L4 13.4998L5.25448 13.1862C5.63317 13.0916 6 13.378 6 13.7683V19.2314C6 19.6217 5.63317 19.9081 5.25448 19.8135L3.51493 19.3786C2.62459 19.156 2 18.356 2 17.4383Z"
        stroke="#1B2A47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M22 17.4383V15.5614C22 14.6437 21.3754 13.8437 20.4851 13.6211L20 13.4998L18.7455 13.1862C18.3668 13.0916 18 13.378 18 13.7683V19.2314C18 19.6217 18.3668 19.9081 18.7455 19.8135L20.4851 19.3786C21.3754 19.156 22 18.356 22 17.4383Z"
        stroke="#1B2A47" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}

export default SvgHeadPhones;
