import { DEFAULT_REGION, s3Client } from "api/s3.client";
import { ApiClient } from "helpers/ApiClient";
import { AccessKey } from "models/AccessKey";
import { S3Regions } from "models/S3Regions";
import { call, put } from "redux-saga/effects";
import { setAllS3Regions } from "Redux/buckets/reducer";
import { getBillingUsage, getTariffUserPlan } from "../../account/Actions/accountActions";
import { setAccessKeys } from "../reducer";

export function* init() {
  try {
    const dashBoardAccessKey: AccessKey = yield call(ApiClient.getDashboardAccessKeys);
    const accessKeys: AccessKey[] = yield call(ApiClient.getAccessKeys);

    yield put(setAccessKeys(accessKeys));
    yield call([s3Client, s3Client.init], {
      accessKeyId: dashBoardAccessKey?.id,
      secretAccessKey: dashBoardAccessKey?.secretKey,
      region: DEFAULT_REGION,
    });

    const s3regions: S3Regions | undefined = yield call([s3Client, s3Client.getS3egions]);

    if (s3regions && s3regions?.ListRegions?.Regions?.length > 0) {
      yield put(setAllS3Regions(s3regions.ListRegions.Regions));
    }

    yield put(getTariffUserPlan());
    yield put(getBillingUsage());
  } catch (err) {}
}
