import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { SuccessModal } from "components/UI/Modal/SuccessModal/SuccessModal";
import { UploadModal } from "components/UI/Modal/UploadFileModal/UploadModal";
import { UploadMini } from "components/UploadMini/UploadMini";
import { Auth } from "containers/Auth";
import { RequiredFinishedRegistrationRoute } from "containers/RequiredFinishedRegistrationRoute";
import { RequiredTariffRoute } from "containers/RequiredTariffRoute/RequiredTariffRoute";
import { UIpage } from "containers/UIpage/UIpage";
import { VerifyEmailPage } from "containers/VerifyEmailPage/VerifyEmailPage";
import { debounce } from "lodash";
import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { selectUpload } from "Redux/buckets/Selectors/selectUpload";
import { setIsMob } from "Redux/ui/uiState";
import { selectIsAuthorized } from "Redux/user/Selectors/selectIsAuthoraized";
import Layout from "./components/Layout/Layout";
import { AppConfig } from "./config";
import HomePage from "./containers/HomePage/HomePage";
import { LoginPage } from "./containers/LoginPage/LoginPage";
import ErrorBoundary from "./helpers/ErrorBoundary";
// fallback if something going wrong with webpack override
// window.Buffer = window.Buffer || require("buffer").Buffer;
const BucketsPage = lazy(() => import("./containers/BucketsPage/BucketsPage"));
const SharedPage = lazy(() => import("./containers/SharedPage/SharedPage"));
const AccessKeysPage = lazy(() => import("./containers/AccessKeysPage/AccessKeysPage"));
const NotificationsPage = lazy(() => import("./containers/NotificationsPage/NotificationsPage"));
const BucketFilePage = lazy(() => import("./containers/BucketFilePage/BucketFilePage"));
const BucketPolicyPage = lazy(() => import("./containers/BucketPolicyPage/BucketPolicyPage"));
const SupportPage = lazy(() => import("./containers/SupportPage/SupportPage"));
const SettingsPage = lazy(() => import("./containers/SettingsPage/SettingsPage"));
const BillingPage = lazy(() => import("./containers/BillingPage/BillingPage"));
const BlockedPage = lazy(() => import("./containers/BlockedPage/BlockedPage"));
const SuspendPaymentResultPage = lazy(() =>
  import("./containers/SuspendPaymentResultPage/SuspendPaymentResultPage").then((module) => ({
    default: module.SuspendPaymentResultPage,
  }))
);

const from = new Date(new Date().setMonth(new Date().getMonth() - 1));
const to = new Date(new Date().setHours(23, 59));

export const App = () => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(selectIsAuthorized);
  const { isLoadingFiles, isModalUpload } = useSelector(selectUpload);

  useEffect(() => {
    const func = () => {
      return true;
    };
    if (isLoadingFiles) {
      window.onbeforeunload = func;
    } else {
      window.onbeforeunload = null;
    }
  }, [isLoadingFiles]);

  useEffect(() => {
    const onResize = debounce(() => {
      const width = window.innerWidth;
      dispatch(setIsMob(width <= 767.9));
    }, 200);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [dispatch]);

  return (
    <BrowserRouter>
      <TonConnectUIProvider manifestUrl={`https://petabox-dev.dccore.tech/tonconnect-manifest.json`}>
        <Auth>
          {isAuthorized.result ? (
            <Layout isAuthorized={isAuthorized?.result}>
              <ErrorBoundary>
                <Suspense fallback={<div />}>
                  <Routes>
                    {!AppConfig.isProd && <Route path="/dashboard/uipage" element={<UIpage />} />}
                    <Route path="/dashboard/email-verify" element={<VerifyEmailPage />} />
                    <Route path="/dashboard/suspend-payment-result" element={<SuspendPaymentResultPage />} />
                    <Route
                      path="/dashboard/"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <RequiredTariffRoute>
                            <HomePage from={from} to={to} />
                          </RequiredTariffRoute>
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/buckets/*"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <RequiredTariffRoute>
                            <BucketsPage />
                          </RequiredTariffRoute>
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/buckets/:bucketName/*"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <BucketFilePage />
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/policy-settings/:bucketName"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <BucketPolicyPage />
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/accesskeys"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <RequiredTariffRoute>
                            <AccessKeysPage />
                          </RequiredTariffRoute>
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/notifications"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <NotificationsPage />
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/settings"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <SettingsPage />
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/support"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <SupportPage />
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/billing/*"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <BillingPage />
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/blocked"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <BlockedPage />
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                    <Route
                      path="/dashboard/shared/*"
                      element={
                        <RequiredFinishedRegistrationRoute>
                          <RequiredTariffRoute>
                            <SharedPage />
                          </RequiredTariffRoute>
                        </RequiredFinishedRegistrationRoute>
                      }
                    />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
              <SuccessModal />
              <UploadModal />
              {isAuthorized?.result && isLoadingFiles && !isModalUpload && <UploadMini />}
            </Layout>
          ) : (
            <Routes>
              <Route path="/dashboard/login" element={<LoginPage />} />
            </Routes>
          )}
        </Auth>
      </TonConnectUIProvider>
    </BrowserRouter>
  );
};
