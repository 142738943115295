import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TariffDto } from "shared/src/models/TariffDto";

type CatalogState = {
  tariffCatalog: TariffDto[];
};

const initialState: CatalogState = {
  tariffCatalog: [],
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setTariffCatalog(state, action: PayloadAction<TariffDto[]>) {
      return {
        ...state,
        tariffCatalog: action.payload,
      };
    },
  },
});

export const catalogReducer = catalogSlice.reducer;
export const { setTariffCatalog } = catalogSlice.actions;
