import { PayloadAction } from "@reduxjs/toolkit";
import { ITicket } from "actions/interfaces";
import { dashClient } from "helpers/HttpClient";
import { call, put } from "redux-saga/effects";
import { handleSagaError } from "Redux/helpers";
import { getTickets } from "../Actions/accountActions";

export function* createTicketProcess({ payload }: PayloadAction<ITicket>) {
  try {
    const { fields, onSuccess } = payload;

    const formData = new FormData();

    const { file: files, ...restFields } = fields;

    for (const fieldName in restFields) {
      formData.append(fieldName, restFields[fieldName]);
    }

    for (const file of files) {
      formData.append("file", file, file.name);
    }

    yield call([dashClient, dashClient.apiMultiPart], { url: "/tickets", data: formData });
    onSuccess();
    yield put(
      getTickets({
        status: "open",
        afterLoad: () => {},
      })
    );
  } catch (err) {
    yield handleSagaError(err, "Create ticket");
  }
}
