import { PayloadAction } from "@reduxjs/toolkit";
import { S3File } from "api/s3-client.types";
import { s3Client } from "api/s3.client";
import { S3 } from "aws-sdk";
import { call, put } from "redux-saga/effects";
import { setObjectRetention } from "Redux/buckets/reducer";
import { handleSagaError } from "Redux/helpers";

export function* getObjectRetentionProccess({ payload }: PayloadAction<S3File>) {
  try {
    const objectRetention: S3.GetObjectRetentionOutput = yield call([s3Client, s3Client.getObjectRetention], payload);

    if (objectRetention.Retention) {
      yield put(setObjectRetention(objectRetention.Retention));
    }
  } catch (err) {
    // it's mean this bucket never set ObjectLockConfiguration;
    if (err.code === "NoSuchObjectLockConfiguration") {
      yield put(setObjectRetention(undefined));

      return;
    }

    yield handleSagaError(err);
  }
}
