import clsx from "clsx";
import { DocSearch, SortDown, SortUp } from "iconoir-react";
import { useState } from "react";
import Checkbox from "../Checkbox/Checkbox";
import { Spinner } from "../Spinner/Spinner";
import styles from "./CommonTable.module.scss";

export type QuerySort = {
  ord?: "ASC" | "DESC";
  ordBy?: string;
};

export type TableItem<T> = {
  label: React.ReactNode;
  render: (x: T) => React.ReactNode;
  sortKey?: string;
  width?: number;
  minWidth?: number;
};

type CommonTableProps<T> = {
  items: TableItem<T>[];
  idKey: keyof T;
  values: T[];
  loading?: boolean;
  className?: string;
  sortInfo?: QuerySort;
  onSortChange?: (sort: QuerySort) => void;
  emptyFallBack?: React.ReactNode;
  id?: string;
};

const SortIcon: React.FC<{ active: boolean; order?: QuerySort["ordBy"] }> = ({ active, order }) => {
  return order === "ASC" ? (
    <SortUp className={clsx(styles.sortIcon, active && styles.active)} />
  ) : (
    <SortDown className={clsx(styles.sortIcon, active && styles.active)} />
  );
};

export function CommonTable<T>(props: CommonTableProps<T>) {
  const { items, values, idKey, onSortChange, sortInfo, loading, emptyFallBack, id, className } = props;
  const makeWidth = (width?: number, minWidth?: number) => {
    if (width) {
      return { width: `${width}px`, minWidth: `${width}px`, maxWidth: `${width}px` };
    }

    if (minWidth) {
      return { minWidth: `${minWidth}px` };
    }
  };

  const changeSortOrd = (ordBy?: string) => {
    if (!ordBy) return;

    let ord: QuerySort["ord"] = "DESC"; // default order for new key
    if (sortInfo?.ordBy === ordBy) ord = sortInfo.ord === "ASC" ? "DESC" : "ASC";
    onSortChange && onSortChange({ ord, ordBy });
  };

  if (!loading && emptyFallBack && values.length === 0) {
    return <>{emptyFallBack}</>;
  }

  return (
    <div className={clsx(styles.scroll, styles.tableBuckets, className)}>
      <div className={styles.tableWrapper}>
        <table id={id} className={styles.common}>
          <thead>
            <tr>
              {items.map((x, idx) => (
                <th
                  onClick={() => changeSortOrd(x.sortKey)}
                  key={idx}
                  className={clsx(x.sortKey && styles.pointer)}
                  style={makeWidth(x.width, x.minWidth)}
                >
                  <div className={styles.thInner}>
                    <span>{x.label}</span>
                    {x.sortKey && (
                      <SortIcon
                        active={x.sortKey === sortInfo?.ordBy}
                        order={x.sortKey === sortInfo?.ordBy ? sortInfo.ord : "DESC"}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {values.length === 0 && (
              <tr>
                <td className={styles.noData} colSpan={items.length}>
                  <div className={styles.noDataInner}>
                    <DocSearch width={24} height={24} color="#6E7E9D" />
                    <span>No Items Yet</span>
                  </div>
                </td>
              </tr>
            )}
            {values.map((value) => (
              <tr key={value[idKey] as unknown as string} data-test={value[idKey]}>
                {items.map((x, idx) => (
                  <td key={idx}>{x.render(value)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {loading && (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}

export const TestTable = () => {
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quertSort, setQuerySort] = useState<QuerySort>();

  const data = empty
    ? []
    : [
        { uuid: 1, name: "Dmitry", lastName: "T", age: 28, role: "dev", active: false },
        { uuid: 2, name: "Vladislav", lastName: "T", age: 33, role: "dev", active: false },
        { uuid: 3, name: "Viktor", lastName: "D", age: 1000, role: "dev", active: false },
        { uuid: 4, name: "Anrew", lastName: "S", age: 181, role: "teamlead", active: false },
      ];

  const items: TableItem<typeof data[0]>[] = [
    {
      label: "First Name",
      render: (x) => x.name,
      sortKey: "name",
    },
    {
      label: "Last Name",
      render: (x) => x.lastName,
    },
    {
      label: "Age",
      render: (x) => x.age,
      sortKey: "age",
    },
    {
      label: "Role",
      render: (x) => x.role,
      sortKey: "role",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div style={{ display: "flex", columnGap: "20px" }}>
        <Checkbox onChange={() => setEmpty((x) => !x)} id={1} label="Empty Table" name="1" checked={empty} />
        <Checkbox onChange={() => setLoading((x) => !x)} id={1} label="Loading Table" name="1" checked={loading} />
      </div>
      <CommonTable
        loading={loading}
        sortInfo={quertSort}
        onSortChange={setQuerySort}
        idKey="uuid"
        items={items}
        values={data}
      />
    </div>
  );
};
