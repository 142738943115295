import { PayloadAction } from "@reduxjs/toolkit";
import { s3Client } from "api/s3.client";
import { PutFileAclPayload } from "models/ACL";
import { call, put, select } from "redux-saga/effects";
import { getFileAcl } from "Redux/buckets/Actions/bucketsActions";
import { handleSagaError } from "Redux/helpers";
import { selectIsCanShare } from "Redux/user/Selectors/selectIsCanShare";

export function* putFileAclProcess({ payload }: PayloadAction<PutFileAclPayload>) {
  try {
    const isCanShare = yield select(selectIsCanShare);
    const isEveryone = payload.grants.find(
      (grant) => grant.Grantee?.URI === "http://acs.amazonaws.com/groups/global/AllUsers"
    );
    if (!isCanShare && !!isEveryone) {
      throw Error(
        "We disabled this function for security reasons. However, if it is essential to you, please get in touch with support."
      );
    }
    yield call([s3Client, s3Client.putFileAcl], payload);
    yield put(getFileAcl({ nameFile: payload.fileName, nameBucket: payload.nameBucket }));
  } catch (err) {
    yield handleSagaError(err);
  }
}
